import _ from 'lodash';
import { Module, VuexModule, Mutation, Action, getModule, config } from 'vuex-module-decorators';

import campaignStore from '@/modules/campaigns/store/campaigns.store';
import store from '@/core/store/index.store';

import ModalCustomBudget from '@/modules/ads/components/ModalCustomBudget.vue';
import ChangeAdStatusDelete from '@/modules/ads/components/modals/ChangeAdStatusDelete.vue';
import ChangeAdStatusDeleteLastAd from '@/modules/ads/components/modals/ChangeAdStatusDeleteLastAd.vue';

import { AdConfig, adType, getAdConfig } from '@/modules/ads';
import { AdStatusAction, Ad, AdType } from '@/modules/ads/models/ads.model';
import { callToActionOptionsByChannel, excludedCallToActionByCampaignType } from '@/modules/ads/helpers';
import { Campaign } from '@/modules/campaigns/models/campaign.model';
import { Channel } from '@/modules/ads/models/ads.model';

config.rawError = true;

export interface AdModalParams {
  isAdActive: boolean;
  currentAdComponent: object | null;
  ad: Ad | null;
  adType: AdType | null;
}

@Module({
  name: 'ads',
  namespaced: true,
  dynamic: true,
  store,
})
class Ads extends VuexModule {
  get channelsWithAds(): Channel[] {
    const channelsWithAds: Channel[] = [];

    if (!!getAdConfig('FacebookPostAd').store.ads.length || !!getAdConfig('FacebookCarouselAd').store.ads.length || !!getAdConfig('FacebookStoryAd').store.ads.length) {
      channelsWithAds.push('facebook');
    }
    if (!!getAdConfig('InstagramPostAd').store.ads.length || !!getAdConfig('InstagramCarouselAd').store.ads.length || !!getAdConfig('InstagramStoryAd').store.ads.length) {
      channelsWithAds.push('instagram');
    }
    if (!!getAdConfig('GoogleDisplayCustomAd').store.ads.length || !!getAdConfig('GoogleDisplayGeneratedAd').store.ads.length) {
      channelsWithAds.push('google');
    }
    if (!!getAdConfig('LinkedinPostAd').store.ads.length) {
      channelsWithAds.push('linkedin');
    }
    if (!!getAdConfig('GoogleSearchAd').store.ads.length) {
      channelsWithAds.push('google-search');
    }
    return channelsWithAds;
  }

  get callToActionOptions() {
    return (adType: any) => {
      const excludedOptions = excludedCallToActionByCampaignType[_.get(campaignStore, 'selectedCampaign.type')] || [];
      return _.without(callToActionOptionsByChannel[adType], ...excludedOptions);
    };
  }

  public activeAdModal: AdModalParams = {
    isAdActive: false,
    currentAdComponent: null,
    ad: null,
    adType: null,
  };

  @Mutation
  public setAdModal(adAction: AdModalParams) {
    this.activeAdModal = adAction;
  }

  @Action
  public async refetchAllAds() {
    adType.forEach((type: AdType) => {
      const adConfig: AdConfig = getAdConfig(type);
      adConfig.store.fetchAds();
    });
  }

  @Action
  public async openModalCustomBudget(campaign: Campaign | null): Promise<void> {
    campaignStore.setModal({
      isActive: true,
      currentComponent: ModalCustomBudget,
      campaign,
      socialChannel: null,
    });
  }

  @Action({commit: 'setAdModal'})
  public async openAdConfirmModal({adAction, ad, adType, canDelete}: {adAction: AdStatusAction, ad: Ad, adType: AdType, canDelete: boolean}) {
    if (adAction === 'delete' && !canDelete) {
      return {
        isAdActive: true,
        currentAdComponent: selectAdModalByAction('deleteLastAd'),
        ad,
        adType,
      };
    }
    return {
      isAdActive: true,
      currentAdComponent: selectAdModalByAction(adAction),
      ad,
      adType,
    };
  }

  @Action({commit: 'setAdModal'})
  public closeAdConfirmationModal(): AdModalParams {
    return {isAdActive: false, currentAdComponent: null, ad: null, adType: null};
  }
}

function selectAdModalByAction(action: AdStatusAction) { 
  switch (action) {
      case 'delete': return ChangeAdStatusDelete;
      case 'deleteLastAd': return ChangeAdStatusDeleteLastAd;
    }
}

export default getModule(Ads, store);
