import {
  Balance,
  BalanceOverviewRecipient,
  CampaignSpend,
  Correction,
  Deposit,
  FinanceSettings,
} from '@/modules/settings/models/finance.model';
import httpService from '@/core/plugins/httpService';
import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import { getDateWithTimezone } from '@/core/plugins/date';
import {Money} from 'ts-money';

export default class FinanceApi {
  public getFinanceSettings(): Promise<FinanceSettings> {
    return httpService.get(this.baseUrl + '/finance-settings').then((response) => {
      const data = response.data as FinanceSettingsJson;
      return {
        billingOption: data.billing_option,
        taxGroup: data.tax_group,
        vatNumber: data.vat_number,
        invoiceEmail: data.invoice_email,
        currencyCode: data.currency_code,
        paymentPeriod: data.payment_period,
        costCenter: data.cost_center,
      };
    });
  }

  public getBalance(): Promise<Balance> {
    return httpService.get(this.baseUrl + '/balance').then((response) => {
      const data = response.data as BalanceJson;
      return {
        totalBudget: Money.fromInteger(data.total_budget, data.currency_code),
        reserved: Money.fromInteger(data.reserved, data.currency_code),
      };
    });
  }

  public getCorrections(): Promise<Correction[]> {
    return httpService.get(this.baseUrl + '/corrections').then((response) => {
      const data = response.data as CorrectionJson[];
      return data.map((correctionJson) => {
        return {
          date: getDateWithTimezone(correctionJson.date, 'YYYY-MM-DD'),
          amount: Money.fromInteger(correctionJson.amount, correctionJson.currency_code),
          description: correctionJson.description,
        };
      });
    });
  }

  public getDeposits(): Promise<Deposit[]> {
    return httpService.get(this.baseUrl + '/deposits').then((response) => {
      const data = response.data as DepositJson[];
      return data.map((depositJson) => {
        return {
          date: getDateWithTimezone(depositJson.date, 'YYYY-MM-DD'),
          amount: Money.fromInteger(depositJson.amount, depositJson.currency_code),
          description: depositJson.description,
        };
      });
    });
  }

  public getCampaignSpends(): Promise<CampaignSpend[]> {
    return httpService.get(this.baseUrl + '/campaign-spends').then((response) => {
      const data = response.data as CampaignSpendJson[];
      return data.map((campaignSpendJson) => {
        return {
          id: campaignSpendJson.id,
          campaignName: campaignSpendJson.campaign_name,
          product: campaignSpendJson.product,
          spend: Money.fromInteger(campaignSpendJson.spend, campaignSpendJson.currency_code),
          startDate: getDateWithTimezone(campaignSpendJson.start_date, 'YYYY-MM-DD'),
        };
      });
    });
  }

  public getBalanceOverviewRecipients(): Promise<BalanceOverviewRecipient[]> {
    return httpService.get(this.baseUrl + '/balance-overview-recipients').then((response) => {
      return response.data as BalanceOverviewRecipientJson[];
    });
  }

  public addBalanceOverviewRecipient(recipient: BalanceOverviewRecipient): Promise<void> {
    return httpService.post(this.baseUrl + '/balance-overview-recipients', recipient).then((response) => {
      return;
    });
  }

  protected get baseUrl(): string | undefined {
    const accountsStore = useAccountsStore();
    return process.env.VUE_APP_WONDERKIND_API_BASE_URL + `/accounts/${accountsStore.selectedAccount!.id}`;
  }
}

interface FinanceSettingsJson {
  billing_option: string;
  tax_group: string;
  vat_number: string;
  invoice_email: string;
  currency_code: string;
  payment_period: number;
  cost_center: string;
}

interface DepositJson {
  date: string | Date;
  amount: number;
  currency_code: string;
  description: string;
}

interface CorrectionJson {
  date: string | Date;
  amount: number;
  currency_code: string;
  description: string;
}

interface CampaignSpendJson {
  id: string;
  campaign_name: string;
  product: string;
  spend: number;
  currency_code: string;
  start_date: string | Date;
}

interface BalanceOverviewRecipientJson {
  name: string;
  email: string;
}

interface BalanceJson {
  total_budget: number;
  reserved: number;
  currency_code: string;
}
