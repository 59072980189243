import { defineStore } from 'pinia'
import JobLibraryResource from '@/modules/JobLibrary/api/jobLibrary.api';
import { JobList, NewJobList, UpdateJobListParams, ValidateXmlParams, XmlResponseValidationParams} from '@/modules/JobLibrary/models/jobLibrary.model';
import {Campaign, DynamicCampaignType, CampaignStatus} from '@/modules/campaigns/models/campaign.model';
import {JobProfile} from '@/core/models/jobProfile.model';
import toastNotificationStore from '@/core/store/toastNotification.store';
import router from '@/router';
import { DynamicCampaignGoalValue } from '@/modules/campaigns/models/campaign.goal.model';
import CampaignResource from '@/modules/campaigns/api/campaign.api';
import { handleResponseError } from '@/core/assets/global-utils';

interface State {
  selectedJobList: JobList | null; 
  selectedDynamicCampaign: Campaign | null; 
  newJobList: NewJobList | null; 
  profiles: JobProfile[][]; 
  jobLibrary: JobList[]; 
  dynamicCampaigns: Campaign[]; 
  validationResult: XmlResponseValidationParams | null; 
  feedResourceWithRequiredValidation: ValidateXmlParams | null; 
  animateXmlLoading: boolean; 
  authFailed: boolean; 
  clickedFeedIndex: number | null; 
  allowFeedUpload: boolean; 
  login: string; 
  password: string; 
  searchString: string; 
  pageCountForJobLibrary: number; 
  pageCountForCampaigns: number;
  isXmlModalActive: boolean,
}

export const useJobLibrarStore = defineStore('jobLibraryStore', {
  state: (): State => {
    return { 
      selectedJobList: null,
      selectedDynamicCampaign: null,
      newJobList: null,
      profiles: [[]],
      jobLibrary: [],
      dynamicCampaigns: [],
      validationResult: null,
      feedResourceWithRequiredValidation: null,
      animateXmlLoading: false,
      authFailed: false,
      clickedFeedIndex: null,
      allowFeedUpload: false,
      login: '',
      password: '',
      searchString: '',
      pageCountForJobLibrary: 1,
      pageCountForCampaigns: 1,
      isXmlModalActive: false,
    }
  },
  actions: {
    updateDynamicCampaignsList(data: { dynamicCampaign: Campaign, newStatus?: string }) {
      const { dynamicCampaign, newStatus } = data;
      
      if (data.newStatus === 'created') {
        this.dynamicCampaigns.unshift(dynamicCampaign);
        return;
      } 

      let updatedList = null;
      
      if (newStatus === 'deleted') {
        updatedList = this.dynamicCampaigns.filter((campaign) => campaign.id !== dynamicCampaign.id);
      } else {
        updatedList = this.dynamicCampaigns.map((currentCampaign) => {
          if (currentCampaign.id === dynamicCampaign.id ) {
            return  { ...dynamicCampaign, status: newStatus as CampaignStatus|| dynamicCampaign.status }
          }
          return currentCampaign;
        });
      }
      this.dynamicCampaigns = updatedList;
    },
    async resumeDynamicCampaign(campaign: Campaign) {
      try {
        await CampaignResource.prototype.resumeDynamicCampaign(campaign);
        await toastNotificationStore.showToastNotification({
          message: `Your campaign was successfully resumed!`,
          isSuccess: true,
        });
      } catch (error: any) {
        await handleResponseError(error);
      }
      this.updateDynamicCampaignsList({dynamicCampaign: campaign, newStatus: 'active'});
    },
    async deleteCampaign(campaign: Campaign) {
      try {
        await CampaignResource.prototype.delete(campaign);
        await toastNotificationStore.showToastNotification({
          message: `Your campaign was successfully deleted!`,
          isSuccess: true,
        });
      } catch (error: any) {
        await handleResponseError(error);
      }
      this.updateDynamicCampaignsList({dynamicCampaign: campaign, newStatus: 'deleted'});
    },
    async stopDynamicCampaign(campaign: Campaign) {
      try {
        await CampaignResource.prototype.stopDynamicCampaign(campaign);
        await toastNotificationStore.showToastNotification({
          message: `Your campaign was successfully stopped!`,
          isSuccess: true,
        });
      } catch (error: any) {
        await handleResponseError(error);
      }
      this.updateDynamicCampaignsList({dynamicCampaign: campaign, newStatus: 'stopped'});
    },
    async pauseDynamicCampaign(campaign: Campaign) {
      try {
        await CampaignResource.prototype.pauseDynamicCampaign(campaign);
        await toastNotificationStore.showToastNotification({
          message: `Your campaign was successfully paused!`,
          isSuccess: true,
        });
      } catch (error: any) {
        await handleResponseError(error);
      }
      this.updateDynamicCampaignsList({dynamicCampaign: campaign, newStatus: 'paused'});
    },
    async getSingleDynamicCampaign(campaignId: string): Promise<void | null> {
      try {
        this.selectedDynamicCampaign = await CampaignResource.prototype.findById(campaignId);
      } catch (error: any) {
        await handleResponseError(error);
        this.selectedDynamicCampaign = null;
        return Promise.resolve(null);
      }
    },
    async deployCampaignAndAds(campaign: Campaign) {
      try {
        const attempt = await JobLibraryResource.prototype.deployCampaignAndAds(campaign.id);
        if (attempt === 200) {
          await toastNotificationStore.showToastNotification({
            message: `Your campaign was successfully deployed!`,
            isSuccess: true,
          });
        } else {
          await toastNotificationStore.showToastNotification({
            message: `There was an error deploying your campaign. Please contact support.`,
            isError: true,
          });
        }
      } catch (error: any) {
        await handleResponseError(error);
      }
      this.updateDynamicCampaignsList({ dynamicCampaign: campaign, newStatus: 'active' });
    },
    async getAllJobListCampaigns(activeFilters?: string[]) {
      const campaigns = await JobLibraryResource.prototype.fetchAllJobListCampaigns(this.pageCountForCampaigns, activeFilters);
      if (!campaigns) {
        return;
      }
      const campaignsToStore = campaigns.filter((campaign) => {
        return !this.dynamicCampaigns.find((knownCampaign) => knownCampaign.id === campaign.id);
      });
      this.dynamicCampaigns = [...this.dynamicCampaigns, ...campaignsToStore]
      return campaignsToStore;
    },
    async updateSelectedDynamicCampaign(campaign: Campaign) {
      try {
        const response = await JobLibraryResource.prototype.updateSelectedCampaign(campaign);
        await toastNotificationStore.showToastNotification({ message: 'Campaign updated successfully', isSuccess: true });
        this.selectedDynamicCampaign = response
        return response;
      } catch (error: any) {
        await handleResponseError(error);
        this.selectedDynamicCampaign = null
        return Promise.resolve(null);
      }
    },
    async createCampaignFromJobList(requestParams: {facebookCatalogId: string, customDailyBudget: number, type: DynamicCampaignType, goal: DynamicCampaignGoalValue}) {
      try {
        const campaign = await JobLibraryResource.prototype.createCampaignFromJobList(requestParams);
        if (!!campaign) {
          await toastNotificationStore.showToastNotification({
            message: 'Your campaign was created!',
            isSuccess: true,
          });
          this.updateDynamicCampaignsList({ dynamicCampaign: campaign, newStatus: 'created' });
          this.selectedDynamicCampaign = campaign;
          return campaign;
        } else {
          await toastNotificationStore.showToastNotification({
            message: 'There was an error creating this campaign. Please contact support.',
            isError: true,
          });
          router.push({ name: 'dynamic-campaigns' });
        }
      } catch (error: any) {
        await handleResponseError(error);
        await router.push({name: 'dynamic-campaigns'});
      }
    },
    async deleteJobList(params: {jobListId: string, name: string}) {
      const attempt = await JobLibraryResource.prototype.deleteJobList(params.jobListId);
      if (attempt === 204) {
        await toastNotificationStore.showToastNotification({
          message: `${params.name} was successfully deleted!`,
          isSuccess: true,
        });
        this.jobLibrary = this.jobLibrary.filter((item) => item.id !== params.jobListId);
      } else {
        await toastNotificationStore.showToastNotification({
          message: `${params.name} could not be deleted, please contact support.`,
          isError: true,
        });
      }
    },
    async updateJobList(params: UpdateJobListParams) {
      return JobLibraryResource.prototype.updateJobList(params).then((attempt) => {
        if (attempt!.status === 200) {
          toastNotificationStore.showToastNotification({ message: `Success! ${params.name} has been updated!`, isSuccess: true });
        } else {
          toastNotificationStore.showToastNotification({ message: `'${params.name}' could not be updated. There is no template associated with this Job List.`, isError: true });
        }
      }).catch(() => {
        toastNotificationStore.showToastNotification({ message: `'${params.name}' could not be updated. There is no template associated with this Job List.`, isError: true });
      });
    },
    async getJobLibrary() {
      try {
        const jobLibrary = await JobLibraryResource.prototype.fetchJobLibrary(this.pageCountForJobLibrary);
        if (!jobLibrary || jobLibrary && !jobLibrary.length) {
          return;
        }
        const jobLibraryToStore = jobLibrary.filter((jobList) => {
          return !this.jobLibrary.find((knownJobList) => knownJobList.id === jobList.id);
        });
        this.jobLibrary = [...this.jobLibrary, ...jobLibraryToStore]
        return jobLibraryToStore;
      } catch (error: any) {
        await handleResponseError(error);
      }
    },
    resetJobLibrary() {
      this.jobLibrary = [];
    },
    setXmlModalStatus(status: boolean) {
      this.isXmlModalActive = status;
    },
    resetPageCountForCampaigns() {
      this.pageCountForCampaigns = 1;
    },
    incrementPageCountForCampaigns() {
      this.pageCountForCampaigns += 1;
    },
    resetPageCountForJobLibrary() {
      this.pageCountForJobLibrary = 1;
    },
    incrementPageCountForJobLibrary() {
      this.pageCountForJobLibrary += 1;
    },
    setSelectedJobList(selectedJobList: JobList | null) {
      this.selectedJobList = selectedJobList;
    },
    resetXmlValidationResult() {
      this.validationResult = null;
    },
    setfeedResourceWithRequiredValidation(feedSource: ValidateXmlParams | null) {
      this.feedResourceWithRequiredValidation = feedSource;
    },
    setAnimateXmlLoading(animateXmlLoading: boolean) {
      this.animateXmlLoading = animateXmlLoading;
    },
    setCreatedJobList(newJobList: NewJobList | null) {
      this.newJobList = newJobList;
    },
    appendJobProfile(data: {profile: JobProfile, profileContainerIndex: number}) {
      this.profiles[data.profileContainerIndex].push(data.profile);
    },  
    async createNewJobList(newJobList: NewJobList) {
      try {
        const attempt = await JobLibraryResource.prototype.createJobList(newJobList);
        if (attempt!.status === 201) {
          await toastNotificationStore.showToastNotification({
            message: `${newJobList.name} was successfully created!`,
            isSuccess: true,
          });
        } else {
          await toastNotificationStore.showToastNotification({
            message: `${newJobList.name} could not be created. Some data was invalid.`,
            isError: true,
          });
        }
        return attempt!.data;
      } catch (error: any) {
        await handleResponseError(error, 'response.data.errors.feedSources.0.jobProfileId.0');
        return false;
      }
    },
    resetCreateJobListData() {
      this.setSelectedJobList(null);
      this.profiles = [[]];
      this.setfeedResourceWithRequiredValidation(null);
      this.setCreatedJobList(null);
    },
    removeJobProfile( profileContainerIndex: number ) {
      this.profiles[profileContainerIndex].shift();
    },
    setLogin(login: string) {
      this.login = login;
    },
    setPassword(password: string) {
      this.password = password;
    },
    setClickedFeedIndex(clickedFeedIndex: number | null) {
      this.clickedFeedIndex = clickedFeedIndex;
    },
    setAllowFeedUpload(allowFeedUpload: boolean) {
      this.allowFeedUpload = allowFeedUpload;
    },
    removeProfileContainerForDeletedFeed(profileContainerIndex: number) {
      this.profiles = this.profiles.filter((_, index) => {
        return index !== profileContainerIndex;
      });
    },
    setSelectedDynamicCampaign(selectedDynamicCampaign: Campaign | null) {
      this.selectedDynamicCampaign = selectedDynamicCampaign;
    },
    async validateXml(feedSource: ValidateXmlParams) {
      debugger;
      this.setAnimateXmlLoading(true);
      try {
        this.authFailed = false;
        const result = await JobLibraryResource.prototype.validateXmlUrl(feedSource);
  
        if ((result && !result.passedAuth.passed) && this.isXmlModalActive) {
          await toastNotificationStore.showToastNotification({message: result.passedAuth.message!, isError: true});
          this.authFailed = false;
          this.setAnimateXmlLoading(false);
          this.validationResult = null;
          return null;
        }
  
        if (result && !result.requiredAuth.passed) {
          await toastNotificationStore.showToastNotification({message: result.requiredAuth.message!, isError: true});
          this.setAnimateXmlLoading(false);
          this.isXmlModalActive = true;
          this.setfeedResourceWithRequiredValidation(feedSource);
          this.validationResult = null
          return null;
        }
  
        if (result && !result.isXml.passed) {
          await toastNotificationStore.showToastNotification({message: result.isXml.message!, isError: true});
          this.setAnimateXmlLoading(false);
          this.validationResult = null
          return null;
        }
  
        if (result && !result.structure.passed) {
          await toastNotificationStore.showToastNotification({message: result.structure.message!, isError: true});
          this.setAnimateXmlLoading(false);
          this.validationResult = null;
          return null;
        }
  
        if (result && !result.amount.passed) {
          await toastNotificationStore.showToastNotification({message: result.amount.message, isError: true});
        }
  
        if (result && result.passedAuth.passed) {
          this.isXmlModalActive = false;
        }
        if (result) {
          this.validationResult = result;
        }
        return result ? result : null;
      } catch (error) {
        this.setAnimateXmlLoading(false);
        await handleResponseError(error);
        this.validationResult = null;
        return null;
      }
    },
    setSearch(searchString: string) {
      this.searchString = searchString;
    }
  },
});