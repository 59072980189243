import _ from 'lodash';
import store from '@/core/store/index.store';
import {Action, getModule, Module, Mutation, VuexModule, config} from 'vuex-module-decorators';
import customTemplateStore from '../../custom/store/customTemplates.store';
import {DefaultTemplate} from '../models/defaultTemplate.model';
import ChangeTemplateStatusDelete from '../modals/ChangeTemplateStatusDelete.vue';
import SocialPageSelectorModal from '../../../../core/components/SocialPageSelectorModal.vue';
import { SocialPage } from '@/modules/accounts/models/socialPage.model';
import TemplateResource from '../api/defaultTemplate.api';
import { Channel } from '@/modules/ads/models/ads.model';
import { CustomTemplate } from '../../custom/models/customTemplate.model';
import {TemplateModel} from '../../models/templates.model';
import { handleUnknownError } from '@/core/assets/global-utils';
import { FunctionGroup } from '@/modules/campaigns/models/function.group.model';

config.rawError = true;

interface SearchModalState {
  isActive: boolean;
  triggerDistanceTop: number;
}

export interface ModalParams {
  isActive: boolean;
  currentComponent: object | null;
  template: any;
  socialChannel?: Channel | null;
  templateType?: TemplateModel;
}

@Module({
  name: 'defaultTemplates',
  namespaced: true,
  dynamic: true,
  store,
})
class DefaultTemplates extends VuexModule {

  public activeModal: ModalParams = {
    isActive: false,
    currentComponent: null,
    template: null,
    socialChannel: null,
  };

  public searchModal: SearchModalState = {
    isActive: false,
    triggerDistanceTop: 0,
  };
  
  public editing: boolean = false;
  public defaultTemplates: DefaultTemplate[] = [];
  public selectedDefaultTemplate: DefaultTemplate | null = null;
  public loading: boolean = false;

  protected get baseUrl(): string | undefined {
    return process.env.VUE_APP_WONDERKIND_API_BASE_URL;
  }

  get templateId() {
    return _.get(this.selectedDefaultTemplate, 'id', '');
  }

  get usedFunctionGroups() {
    return this.defaultTemplates.map((template) => template.functionGroup);
  }

  get isEditing() {
    return this.editing;
  }

  get hasDefaultTemplates() {
    return this.defaultTemplates;
  }

  get completedDefaultTemplates() {
    return this.defaultTemplates.filter((template) => {
      if (
        template.functionGroup &&
        template.runtime &&
        template.customTotalGrossBudget &&
        template.enabledChannels.length
      ) {
        return template;
      }
      return;
    });
  }

  get isDuplicate() {
    const duplicate = this.defaultTemplates.find((template) => 
      this.selectedDefaultTemplate!.functionGroup === template.functionGroup
      && this.selectedDefaultTemplate?.functionGroup !== ''
      && this.selectedDefaultTemplate!.id !== template.id,
    );
    return !!duplicate;
  }

  @Mutation
  public setisEditing(value: boolean) {
    this.editing = value;
  }

  @Mutation
  public setLoading(newLoadingState: boolean) { this.loading = newLoadingState; }

  @Mutation
  public setDefaultTemplates(response: DefaultTemplate[]) {
    this.defaultTemplates = response;
  }

  @Mutation
  public setNewDefaults(newArray: DefaultTemplate[]) {
    this.defaultTemplates = newArray;
  }

  @Mutation
  public setModal(newModalParams: ModalParams) {
    this.activeModal = newModalParams;
    if (newModalParams.templateType === 'default') {
      this.selectedDefaultTemplate = newModalParams.template;
    } else if (newModalParams.templateType === 'custom') {
      customTemplateStore.selectCustomTemplate(newModalParams.template);
    }
  }

  @Mutation
  public addNewDefault(response: DefaultTemplate) {
    this.defaultTemplates.push(response);
    this.selectedDefaultTemplate = response;
  }

  @Mutation
  public appendDefaultTemplate(template: DefaultTemplate) {
    this.defaultTemplates.push(template);
  }

  @Mutation
  public setSelectedDefaultTemplate(defaultTemplate: DefaultTemplate | null) { 
    this.selectedDefaultTemplate = defaultTemplate;
  }

  @Mutation
  public setSocialPage(socialPage: SocialPage) {
    if (socialPage.channel === 'facebook') {
      this.selectedDefaultTemplate!.facebookPageId = socialPage.id;
    }
    if (socialPage.channel === 'instagram') {
      this.selectedDefaultTemplate!.instagramPageId = socialPage.id;
    }
    if (socialPage.channel === 'linkedin') {
      this.selectedDefaultTemplate!.linkedinPageId = socialPage.id;
    }
  }

  @Mutation
  public setTargetLanguage(targetLanguage: string) {
    this.selectedDefaultTemplate!.language = targetLanguage;
  }

  @Mutation
  public setBudget(budget: number) {
    this.selectedDefaultTemplate!.customTotalGrossBudget = budget;
  }

  @Mutation
  public setFunctionGroup(functionGroup: FunctionGroup | null) {
    this.selectedDefaultTemplate!.functionGroup = functionGroup;
  }

  @Action({commit: 'setisEditing'})
  public updateEdit(value: boolean) {
    return value;
  }

  @Action({ commit: 'setLoading' })
  public async updateLoadingState(newLoadingState: boolean): Promise<boolean> {
    return newLoadingState;
  }

  @Action({commit: 'setSocialPage'})
  public async selectSocialPage(params: {id: string, socialPage: SocialPage}) { 
    await TemplateResource.prototype.setSocialPage(params.socialPage, params.id);
    return params.socialPage;
  }

  @Action({commit: 'setModal'})
  public openChangeStatusConfirmModal({template, type}: {template: DefaultTemplate, type: string}) {
    return {
      isActive: true,
      currentComponent: ChangeTemplateStatusDelete,
      template,
      templateType: type,
    };
  }

  @Action({commit: 'setModal'})
  public openSocialPageModal(data: {socialChannel: Channel, template: DefaultTemplate | CustomTemplate}) {
    return {
      isActive: true,
      currentComponent: SocialPageSelectorModal,
      template: data.template,
      socialChannel: data.socialChannel,
    };
  }

  @Action({commit: 'setModal'})
  public closeActiveModal(): ModalParams {
    return {
      isActive: false,
      currentComponent: null,
      template: this.selectedDefaultTemplate,
      socialChannel: null,
    };
  }

  @Action({commit: 'setSelectedDefaultTemplate'})
  public selectDefaultTemplate(template: DefaultTemplate | null) {
    return template;
  }

  @Action({commit: 'setDefaultTemplates'})
  public async fetchDefaultTemplates(): Promise<DefaultTemplate[]> {
    const templates = await TemplateResource.prototype.findAll();
    this.updateLoadingState(false);
    return templates;
  }

  @Action({commit: 'setSelectedDefaultTemplate'})
  public async fetchSingleDefault(id: string): Promise<DefaultTemplate> {
    const template = await TemplateResource.prototype.findById(id);
    return template;
  }

  @Action({commit: 'appendDefaultTemplate'})
  public async createDefaultTemplate(newTemplate: DefaultTemplate): Promise<DefaultTemplate> {
    const created = await TemplateResource.prototype.createDefaultTemplate(newTemplate);
    return created;
  }

  @Action
  public async deleteDefaultTemplate(selected: DefaultTemplate) {
    const deleted = await TemplateResource.prototype.deleteDefaultTemplate(selected);
    this.fetchDefaultTemplates();
    return deleted;
  }

  @Action
  public async editDefaultTemplate(template: DefaultTemplate) {
    const edited = await TemplateResource.prototype.editDefaultTemplate(template);
    this.fetchDefaultTemplates();
    return edited;
  }

  @Action({commit: 'setTargetLanguage'})
  public async updateTargetLanguage(targetLanguage: string) {
    await TemplateResource.prototype.setTargetLanguage(this.templateId, targetLanguage);
    return targetLanguage;
  }

  @Action({commit: 'setBudget'})
  public async updateBudget(budget: number) {
    TemplateResource.prototype.setBudget(this.templateId, budget)
      .catch(err => handleUnknownError(err.response.data));
    return budget;
  }

  @Action({commit: 'setRuntime'})
  public async updateRuntime(runtime: string | number) {
    await TemplateResource.prototype.setRuntime(this.templateId, runtime);
    return runtime;
  }

  @Action({commit: 'setFunctionGroup'})
  public async updateFunctionGroup(functionGroup: FunctionGroup) {
    await TemplateResource.prototype.setFunctionGroup(this.templateId, functionGroup);
    return functionGroup;
  }

  @Action({commit: 'setFunctionGroup'})
  public async deleteFunctionGroup() {
    await TemplateResource.prototype.deleteFunctionGroup(this.templateId);
    return null;
  }
}

export default getModule(DefaultTemplates, store);
