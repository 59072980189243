import store from '@/core/store/index.store';
import {Action, config, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {GoogleDisplayGeneratedAd, GoogleDisplayGeneratedAdParams} from '../../models/googleAds.model';
import GoogleDisplayGeneratedAdResource from '../../api/googleDisplayGeneratedAd.api';
import googleDisplayGeneratedCustomStore from './googleDisplayGeneratedCustom.store';
import googleDisplayAdStore from './googleDisplayAd.store';
import googleDisplayCustomStore from './googleDisplayCustom.store';

config.rawError = true;

@Module({
  name: 'googleDisplayGeneratedAdsTemplates',
  namespaced: true,
  dynamic: true,
  store,
})
class GoogleDisplayGeneratedAdStore extends VuexModule {
  public ads: GoogleDisplayGeneratedAd[] = [];
  public newCreatedAds: GoogleDisplayGeneratedAd = {
    type: '',
    subtitle: '',
    title: '',
    callToAction: '',
    tags: [],
    primaryColor: '',
    logoUrl: '',
  };
  public isLoading: boolean = false;
  public minimiseGoogleAds: boolean = false;
  public placeholderAds = [
    { bannerName: 'half page', width: 300, height: 600 },
    { bannerName: 'wide skyscraper', width: 160, height: 600 },
    { bannerName: 'skyscraper', width: 120, height: 600 },
    { bannerName: 'medium rectangle', width: 300, height: 250 },
    { bannerName: 'large rectangle', width: 336, height: 280 },
    { bannerName: 'large leaderboard', width: 970, height: 90 },
    { bannerName: 'leaderboard', width: 728, height: 90 },
    { bannerName: 'banner', width: 468, height: 60 },
    { bannerName: 'small banner', width: 320, height: 50 },
  ];

  get sortedPlaceholders() {
    return this.placeholderAds.sort((a, b) => {
      const diff = a.height - b.height;
      return diff !== 0 ? diff : a.width - b.width;
    });
  }

  @Mutation
  public setAds(ads: GoogleDisplayGeneratedAd[]) { this.ads = ads; }

  @Mutation
  public setMinimiseGoogleAds(value: boolean) { this.minimiseGoogleAds = value; }

  @Mutation
  public appendAds(ad: GoogleDisplayGeneratedAd) { this.ads = [...this.ads, ad]; }

  @Mutation 
  public setIsLoading(value: boolean) { this.isLoading = value; }

  @Mutation
  public setNewCreatedAds(ads: GoogleDisplayGeneratedAd) { this.newCreatedAds = ads; }

  @Mutation
  public removeAd(removingAd: GoogleDisplayGeneratedAd) { 
    this.ads = this.ads.filter((ad) => {
      return removingAd.id !== ad.id;
    });
  }

  @Mutation
  public editAd(editedAd: GoogleDisplayGeneratedAd) { 
    this.ads = this.ads.map((ad) => {
      if (ad.id === editedAd.id) {
        return editedAd;
      } 
      return ad;
    });
  }

  @Mutation
  public resetNewAds() {
    this.newCreatedAds = {
      type: '',
      subtitle: '',
      title: '',
      callToAction: '',
      tags: [],
      primaryColor: '',
      logoUrl: '',
    };
  }

  @Action
  public async create(adParams: GoogleDisplayGeneratedAdParams) {
    this.setIsLoading(true);
    const ad = await GoogleDisplayGeneratedAdResource.prototype.create(adParams);

    if (!ad) { 
      return this.setIsLoading(false); 
    } else {
      this.appendAds(ad);
      this.setIsLoading(false);
    } 
  }

  @Action({ commit: 'editAd'})
  public async updateAd(ad: GoogleDisplayGeneratedAd) {
    const editedAd = await GoogleDisplayGeneratedAdResource.prototype.update(ad);
    return editedAd;
  }

  @Action({ commit: 'setAds' })
  public async fetchAds(): Promise<GoogleDisplayGeneratedAd[] | void> {
    const ads = await GoogleDisplayGeneratedAdResource.prototype.findAll();    
    return ads;
  }

  @Action({ commit: 'removeAd'})
  public async deleteAd(removingAd: GoogleDisplayGeneratedAd) { 
    await GoogleDisplayGeneratedAdResource.prototype.delete(removingAd);
    return removingAd;
  }
}

export default getModule(GoogleDisplayGeneratedAdStore, store);
