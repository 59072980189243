import { render, staticRenderFns } from "./Switcher.vue?vue&type=template&id=92e75970&scoped=true&"
import script from "./Switcher.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Switcher.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Switcher.vue?vue&type=style&index=0&id=92e75970&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92e75970",
  null
  
)

export default component.exports