import {AbstractResource} from '@/core/api/abstract.api';
import defaultTemplateStore from '../../templates/default/store/defaultTemplates.store';
import {InstagramCarouselAd} from '../models/instagramAds.model';
import {MediaType} from '@/modules/medialibrary/models/masterImage.model';
import { hydrateAdMedia, AdMediaJson } from '@/modules/ads/api/apiHelpers';

export default class InstagramCarouselAdResource extends AbstractResource<
  InstagramCarouselAd,
  InstagramCarouselAdResponseJson,
  InstagramCarouselAdRequestJson
  > {

    protected get resourceName() {
      return 'instagram-carousel-templates';
    }

    protected get resourceOwner() {
      if (!defaultTemplateStore.selectedDefaultTemplate) {
        throw new Error('Trying to perform ad related actions without a selected default template');
      }
      return `templates/${defaultTemplateStore.selectedDefaultTemplate.id}`;
    }

    protected extract(model: InstagramCarouselAd): InstagramCarouselAdRequestJson {
      return {
        media: model.media.map((mediaItem) => {
          return {
            id: mediaItem.id,
            type: mediaItem.type,
          };
        }),
        message: model.message,
        callToAction: model.callToAction,
      };
    }

    protected hydrate(json: InstagramCarouselAdResponseJson): InstagramCarouselAd {
      return {
        id: String(json.id),
        media: json.media.map((media) => hydrateAdMedia(media)),
        message: json.message,
        callToAction: json.callToAction,
      };
    }
  }

export interface InstagramCarouselAdResponseJson {
  id: string;
  media: AdMediaJson[];
  callToAction: string;
  message: string;
}

export interface InstagramCarouselAdRequestJson {
    media: Array<{id: string, type: MediaType}>;
    callToAction: string;
    message: string;
}
