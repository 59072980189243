import { render, staticRenderFns } from "./CampaignsListLocations.vue?vue&type=template&id=e6cc18d8&scoped=true&"
import script from "./CampaignsListLocations.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CampaignsListLocations.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CampaignsListLocations.vue?vue&type=style&index=0&id=e6cc18d8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6cc18d8",
  null
  
)

export default component.exports