import {AbstractResource} from '@/core/api/abstract.api';
import customTemplateStore from '../../templates/custom/store/customTemplates.store';
import {FacebookCarouselAd} from '../models/facebookAds.model';
import {MediaType} from '@/modules/medialibrary/models/masterImage.model';
import { hydrateAdMedia, AdMediaJson } from '@/modules/ads/api/apiHelpers';

export default class FacebookCarouselAdResource extends AbstractResource<
  FacebookCarouselAd,
  FacebookCarouselAdResponseJson,
  FacebookCarouselAdRequestJson
> {
  protected get resourceName(): string {
    return 'facebook-carousel-templates';
  }

  protected get resourceOwner(): string {
    if (!customTemplateStore.selectedCustomTemplate) {
      throw new Error ('Trying to perform ad related actions without a selected template');
    }
    return `customTemplates/${customTemplateStore.selectedCustomTemplate.id}`;
  }

  protected extract(ad: FacebookCarouselAd): FacebookCarouselAdRequestJson {
    return {
      media: ad.media.map((mediaItem) => {
        return {
            id: mediaItem.id,
            type: mediaItem.type,
        };
    }),
    message: ad.message,
    headline: ad.headline,
    linkDescription: ad.linkDescription,
    callToAction: ad.callToAction,
    };
  }

  protected hydrate(json: FacebookCarouselAdResponseJson): FacebookCarouselAd {
    return {
      id: String(json.id),
      media: json.media.map((media) => hydrateAdMedia(media)),
      message: json.message,
      headline: json.headline,
      linkDescription: json.linkDescription,
      callToAction: json.callToAction,
  };
  }
}

export interface FacebookCarouselAdResponseJson {
  id: string;
  media: AdMediaJson[];
  message: string;
  headline: string;
  linkDescription: string;
  callToAction: string;
  }

export interface FacebookCarouselAdRequestJson {
  media: Array<{id: string, type: MediaType}>;
  message: string;
  headline: string;
  linkDescription: string;
  callToAction: string;
}
