import store from '@/core/store/index.store';
import toastNotificationStore from '../../../../core/store/toastNotification.store';
import {Action, config, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import { UploadState } from '../../../medialibrary/models/masterImage.model';
import GoogleDisplayAdApi from '../../api/googleDisplayAd.api';
import { GoogleDisplayCustomAd } from '../../models/googleAds.model';

config.rawError = true;

@Module({
  name: 'googleDisplayAdsTemplate',
  namespaced: true,
  dynamic: true,
  store,
})
class GoogleDisplayAdStore extends VuexModule {
  public uploadState: UploadState = 'idle';
  public unsortedAds: GoogleDisplayCustomAd[] = [];
  public newCreatedAds: GoogleDisplayCustomAd[] = [];
  public minimiseGoogleAds: boolean = false;

  get ads(): GoogleDisplayCustomAd[] {
    return this.unsortedAds.sort((a: GoogleDisplayCustomAd, b: GoogleDisplayCustomAd) => {
      const diff = a.height - b.height;
      return diff !== 0 ? diff : a.width - b.width;
    });
  }

  get createdAds(): GoogleDisplayCustomAd[] {
    return this.newCreatedAds.sort((a: GoogleDisplayCustomAd, b: GoogleDisplayCustomAd) => {
      const diff = a.height - b.height;
      return diff !== 0 ? diff : a.width - b.width;
    });
  }

  @Mutation
  public setUploadState(state: UploadState) { this.uploadState = state; }

  @Mutation
  public setCreatedAds(ads: GoogleDisplayCustomAd[]) { this.newCreatedAds = ads; }

  @Mutation
  public setAds(ads: GoogleDisplayCustomAd[]) { this.unsortedAds = ads; }

  @Mutation
  public appendAds(ads: GoogleDisplayCustomAd[]) { this.unsortedAds = [...this.unsortedAds, ...ads]; }

  @Mutation
  public resetNewAds() { this.newCreatedAds = []; }

  @Mutation
  public setMinimiseGoogleAds(value: boolean) { this.minimiseGoogleAds = value; }

  @Mutation
  public removeAd(removingAd: GoogleDisplayCustomAd) {
    this.unsortedAds = this.unsortedAds.filter((ad) => { 
      return removingAd.id !== ad.id;
    });
  }

  @Action({ commit: 'removeAd'})
  public async deleteAd(removingAd: GoogleDisplayCustomAd) {
    await GoogleDisplayAdApi.prototype.delete(removingAd);
    return removingAd;
  }

  @Action({ commit: 'setAds'})
  public async fetchAds(): Promise<GoogleDisplayCustomAd[]> {
    return await GoogleDisplayAdApi.prototype.findAll();
  }

  @Action({ commit: 'appendAds'})
  public async create(zipArchive: File): Promise<GoogleDisplayCustomAd[] | void> {
    this.setUploadState('loading');
    const ads = await GoogleDisplayAdApi.prototype.create(zipArchive);
    if (!ads) {
      toastNotificationStore.showToastNotification({
        message: 'Failed to upload zip file',
        isError: true,
        isSuccess: false,
        isWarning: false,
      });
      return this.setUploadState('failed');
    }
    this.setUploadState('succeeded');
    this.setCreatedAds(ads);
    return ads;
  }

  @Action({ commit: 'setUploadState' })
  public updateUploadState(state: UploadState): UploadState { return state; }
}

export default getModule(GoogleDisplayAdStore, store);
