import store from '@/core/store/index.store';
import {Action, config, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {GoogleDisplayGeneratedAd, GoogleDisplayGeneratedAdParams} from '../../models/googleAds.model';
import GoogleDisplayGeneratedAdResource from '../../api/googleDisplayGeneratedCustom.api';

config.rawError = true;

@Module({
  name: 'googleDisplayGeneratedAdsCustom',
  namespaced: true,
  dynamic: true,
  store,
})
class GoogleDisplayGeneratedAdStore extends VuexModule {
  public ads: GoogleDisplayGeneratedAd[] = [];
  public newCreatedAds: GoogleDisplayGeneratedAd = {
    type: '',
    subtitle: '',
    title: '',
    callToAction: '',
    tags: [],
    primaryColor: '',
    logoUrl: '',
  };
  public isLoading: boolean = false;

  @Mutation
  public resetNewAds() { 
    this.newCreatedAds = {
      type: '',
      subtitle: '',
      title: '',
      callToAction: '',
      tags: [],
      primaryColor: '',
      logoUrl: '',
    };
  }

  @Mutation
  public setAds(ads: GoogleDisplayGeneratedAd[]) { this.ads = ads; }

  @Mutation
  public appendAds(ad: GoogleDisplayGeneratedAd) { this.ads = [...this.ads, ad]; }

  @Mutation 
  public setIsLoading(value: boolean) { this.isLoading = value; }

  @Mutation
  public setNewCreatedAds(ads: GoogleDisplayGeneratedAd) { this.newCreatedAds = ads; }

  @Mutation
  public removeAd(removingAd: GoogleDisplayGeneratedAd) { 
    this.ads = this.ads.filter((ad) => {
      return removingAd.id !== ad.id;
    });
  }

  @Action
  public async create(adParams: GoogleDisplayGeneratedAdParams) {
    this.setIsLoading(true);
    const ad = await GoogleDisplayGeneratedAdResource.prototype.create(adParams);

    if (!ad) { 
      return this.setIsLoading(false);
    } else {
      this.setIsLoading(false);
      this.appendAds(ad);
    }
  }

  @Action({ commit: 'editAd'})
  public async updateAd(ad: GoogleDisplayGeneratedAd) {
    const editedAd = await GoogleDisplayGeneratedAdResource.prototype.update(ad);
    return editedAd;
  }

  @Action({ commit: 'setAds' })
  public async fetchAds(): Promise<GoogleDisplayGeneratedAd[] | void> {
    const ads = await GoogleDisplayGeneratedAdResource.prototype.findAll();    
    return ads;
  }

  @Action({ commit: 'removeAd'})
  public async deleteAd(removingAd: GoogleDisplayGeneratedAd) { 
    await GoogleDisplayGeneratedAdResource.prototype.delete(removingAd);
    return removingAd;
  }
}

export default getModule(GoogleDisplayGeneratedAdStore, store);
