import httpService from '@/core/plugins/httpService';
import { AbstractResource } from '@/core/api/abstract.api';
import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import { Package, PackageStatus } from '../models/package.model';


export default class PackageResource extends AbstractResource<Package, PackageResponseJson, PackageRequestJson> {
  public getAllPackages(): Promise<Package[]> {
    const accountsStore = useAccountsStore();
    return httpService.get(`/accounts/${accountsStore.selectedAccount!.id}/packages`)
        .then(response => response.data.map((p: PackageResponseJson) => this.hydrate(p)));
  }

  public getSinglePackage(packageId: string): Promise<Package> {
    const accountsStore = useAccountsStore();
    return httpService.get(`/accounts/${accountsStore.selectedAccount!.id}/packages/${packageId}`).then((response) => response.data);
  }

  public createPackage(): Promise<Package> {
    const accountsStore = useAccountsStore();
    return httpService.post(`/accounts/${accountsStore.selectedAccount!.id}/packages`).then((response) => response.data);
  }

  public duplicatePackage(packageId: string): Promise<Package> {
    const accountsStore = useAccountsStore();
    return httpService.post(`/accounts/${accountsStore.selectedAccount!.id}/packages/${packageId}/duplicate`)
        .then((response) => this.hydrate(response.data as PackageResponseJson));
  }

  public updatePackage(packageItem: Package): Promise<void> {
    const accountsStore = useAccountsStore();
    return httpService.put(`/accounts/${accountsStore.selectedAccount!.id}/packages/${packageItem.id}`, packageItem).then((response) => response.data);
  }

  public archivePackage(packageId: string): Promise<void> {
    const accountsStore = useAccountsStore();
    return httpService.post(`/accounts/${accountsStore.selectedAccount!.id}/packages/${packageId}/archive`).then((response) => response.data);
  }

  public deletePackage(packageId: string): Promise<void> {
    const accountsStore = useAccountsStore();
    return httpService.delete(`/accounts/${accountsStore.selectedAccount!.id}/packages/${packageId}`).then((response) => response.data);
  }

  protected get resourceName() {
    return 'packages';
  }

  protected get resourceOwner() {
    const accountsStore = useAccountsStore();
    return 'accounts/' + accountsStore.selectedAccount!.id + '/packages/';
  }

  protected extract(model: Package): PackageRequestJson {
      return {
        id: model.id,
        hash: model.hash,
        accountId: model.accountId,
        name: model.name,
        customTotalGrossBudget: model.customTotalGrossBudget,
        runtime: model.runtime,
        status: model.status,
        channelsActivation: {
          facebook: model.channelsActivation.facebook,
          facebookFeed: model.channelsActivation.facebookFeed,
          instagramFeed: model.channelsActivation.instagramFeed,
          instagramStories: model.channelsActivation.instagramStories,
          googleDisplay: model.channelsActivation.googleDisplay,
          googleSearch: model.channelsActivation.googleSearch,
          linkedIn: model.channelsActivation.linkedIn,
        },
        channelsBudget: {
          facebook: model.channelsBudget.facebook,
          googleDisplay: model.channelsBudget.googleDisplay,
          googleSearch: model.channelsBudget.googleSearch,
          linkedIn: model.channelsBudget.linkedIn,
        },
      };
  }

  protected hydrate(json: PackageResponseJson): Package {
    const packageItem: Package = {
      id: json.id,
      hash: json.hash,
      accountId: json.accountId,
      name: json.name,
      customTotalGrossBudget: json.customTotalGrossBudget / 100,
      runtime: json.runtime,
      status: json.status,
      channelsActivation: {
        facebook: json.channelsActivation.facebook,
        facebookFeed: json.channelsActivation.facebookFeed,
        instagramFeed: json.channelsActivation.instagramFeed,
        instagramStories: json.channelsActivation.instagramStories,
        googleDisplay: json.channelsActivation.googleDisplay,
        googleSearch: json.channelsActivation.googleSearch,
        linkedIn: json.channelsActivation.linkedIn,
      },
      channelsBudget: {
        facebook: `${Number(json.channelsBudget.facebook) / 100}`,
        googleDisplay: `${Number(json.channelsBudget.googleDisplay) / 100}`,
        googleSearch: `${Number(json.channelsBudget.googleSearch) / 100}`,
        linkedIn: `${Number(json.channelsBudget.linkedIn) / 100}`,
      },
    };
    return packageItem;
  } 
 }

export interface PackageResponseJson {
  id: string;
  hash: string;
  accountId: string;
  name: string;
  customTotalGrossBudget: number;
  runtime: number;
  status: PackageStatus;
  channelsActivation: {
    facebook: boolean;
    facebookFeed: boolean;
    instagramFeed: boolean;
    instagramStories: boolean;
    googleDisplay: boolean;
    googleSearch: boolean;
    linkedIn: boolean;
  };
  channelsBudget: {
    facebook: string;
    googleDisplay: string;
    googleSearch: string;
    linkedIn: string;
  };
 }

export type PackageRequestJson = PackageResponseJson;

