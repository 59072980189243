import {VuexModule, Mutation, Action, Module, getModule} from 'vuex-module-decorators';
import store from '@/core/store/index.store';
import {ShowToastParams, ToastNotificationState, DeleteMultipleMediaNotificationState} from '@/core/models/toastNotification.model';

@Module({
    name: 'toastNotifications',
    namespaced: true,
    dynamic: true,
    store,
})
class ToastNotificationStore extends VuexModule {
  public toastNotification: ToastNotificationState = {
    isActive: false,
    isError: false,
    isSuccess: false,
    isWarning: false,
    message: '',
  };

  public deleteMultipleMediaNotification: DeleteMultipleMediaNotificationState = {
    message: `Your media was successfully deleted`, 
    isError: false, 
    isSuccess: true,
    isWarning: false,
  };

  public mediaSelectionLength: number | null = null;

  @Mutation
  public setToastNotification(notificationState: ToastNotificationState) {
    this.toastNotification = notificationState;
  }

  @Mutation 
  public setMediaSelectionLength(length: number) {
    this.mediaSelectionLength = length;
  }

  @Action({ commit: 'setToastNotification' })
  public async showToastNotification({ message, isError = false, timeout, isSuccess = false, isWarning = false }: ShowToastParams): Promise<ToastNotificationState> {
    const timer = timeout || (isError ? 10000 : 4000);
    await this.removeToastNotificationTimer(timer);
    return { isActive: !!message, message, isError, isSuccess, isWarning };
  }

  @Action({ commit: 'setToastNotification' })
  public async closeToastNotification(): Promise<ToastNotificationState> {
    return { isActive: false, message: '', isError: false, isSuccess: false, isWarning: false };
  }

  @Action
  public async removeToastNotificationTimer(timout: number): Promise<ToastNotificationState | void> {
    setTimeout(() => {
      this.closeToastNotification();
    }, timout);
  }
}

export default getModule(ToastNotificationStore, store);
