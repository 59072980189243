import { AbstractResource } from '@/core/api/abstract.api';
import { JobProfileSynonyms } from '../../../core/models/jobProfile.model';

export default class ProfileSynonymsResource extends AbstractResource<JobProfileSynonyms, JobProfileSynonymsJson, JobProfileSynonymsJson> {
  protected get resourceName() { return 'profiles'; }
  protected get resourceOwner() { return ''; }

  protected extract(model: JobProfileSynonyms): JobProfileSynonymsJson {
    const { name, functionGroup: function_group, id, synonyms } = model;
    return { id, function_group, name, synonyms };
  }

  protected hydrate(json: JobProfileSynonymsJson): JobProfileSynonyms {
    const { name, function_group: functionGroup, synonyms, id } = json;
    return { id, name, functionGroup, synonyms };
  }
}

export interface JobProfileSynonymsJson {
  id: string;
  function_group: string;
  name: string;
  synonyms: string[];
}
