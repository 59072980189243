import httpService from '@/core/plugins/httpService';
import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import {AbstractResource} from '@/core/api/abstract.api';
import {DefaultTemplate} from '../models/defaultTemplate.model';
import {FunctionGroup} from '@/modules/campaigns/models/function.group.model';
import {SocialPage} from '@/modules/accounts/models/socialPage.model';

export default class DefaultTemplateResource extends AbstractResource<DefaultTemplate, DefaultTemplateResponseJson, DefaultTemplateRequestJson> {
  
  public async findById(id: string): Promise<DefaultTemplate> {
    return await httpService.get('/' + this.resourceOwner + '/' + this.resourceName + '/' + id).then(
      (response) => this.hydrate(response.data as DefaultTemplateResponseJson));
  }

  public createDefaultTemplate(newTemplate: DefaultTemplate): Promise<DefaultTemplate> {
    return httpService.post(this.collectionEndpoint, newTemplate).then(
      (response) => {
        return this.hydrate(response.data as DefaultTemplateResponseJson);
    });
  }

  public deleteDefaultTemplate(selected: DefaultTemplate) {
    return httpService.delete(this.collectionEndpoint + '/' + selected.id);
  }

  public editDefaultTemplate(selected: DefaultTemplate) {
    return httpService.put(this.resourceOwner + 'templates/' + selected.id, selected);
  }

  public async setFunctionGroup(templateId: string, functionGroup: FunctionGroup | null) {
    const url = '/templates/' + templateId + '/function-group';
    await httpService.put(url, {functionGroup});
  }

  public async setTargetLanguage(templateId: string, targetLanguage: string) {
    const url = '/templates/' + templateId + '/language';
    await httpService.put(url, {language: targetLanguage});
  }

  public async deleteFunctionGroup(templateId: string) {
    await httpService.delete(this.entityEndpoint + '/' + templateId + '/function-group');
  }
  
  public async setRuntime(templateId: string, runtime: number | string) {
    const url = '/templates/' + templateId + '/runtime';
    await httpService.put(url, {runtime});
  }

  public async setBudget(templateId: string, budget: number | null) {
    const url = '/templates/' + templateId + '/budget';
    return await httpService.put(url, {budget});
  }

  public async setSocialPage(socialPage: SocialPage, templateId: string) {
    await httpService.put(this.entityEndpoint + '/' + templateId + '/social-page', {pageId: socialPage.id, channel: socialPage.channel});
  }

  protected extract(): DefaultTemplateRequestJson {
    return {
      dummyData: '',
    };
  }

  protected hydrate(json: DefaultTemplateResponseJson): DefaultTemplate {
    const template = {
    id: json.id,
    accountId: json.accountId,
    customTotalGrossBudget: json.customTotalGrossBudget,
    functionGroup: json.functionGroup ? 
      json.functionGroup as FunctionGroup
      : '',
    runtime: json.runtime,
    facebookPageId: json.facebookPageId,
    instagramPageId: json.instagramPageId,
    linkedinPageId: json.linkedinPageId,
    enabledChannels: json.enabledChannels,
    language: json.language,
    };
    return template;
  }

  protected get resourceName() {
    return 'templates';
  }

  protected get resourceOwner() {
    const accountsStore = useAccountsStore();
    return 'accounts/' + accountsStore.selectedAccount!.id;
  }
}

export interface DefaultTemplateResponseJson {
  id: string;
  accountId: string;
  customTotalGrossBudget: number;
  functionGroup: FunctionGroup;
  runtime: number;
  facebookPageId: string | null;
  instagramPageId: string | null;
  linkedinPageId: string | null;
  enabledChannels: string[];
  language: string | null;
}

export interface DefaultTemplateRequestJson {
  dummyData: string;
}
