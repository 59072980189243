import httpService from '@/core/plugins/httpService';

export default class ReportsResource {
    public fetch(accountId: string): Promise<string> {
        return httpService.get('/reporting/account/' + accountId)
          .then((response) => {
              return response.data;
          }).catch(() => {
              return;
          });
    }

    public fetchReportPerCampaign(accountId: string) {
      return httpService.get(`/reporting/account/${accountId}/aggregated`)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.log(error);
      });
    }
}


