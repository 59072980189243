export type CampaignGoalLabel = '' | 'traffic' | 'brand awareness' | 'conversions';
export type CampaignGoalValue = '' | 'MAXIMISE_CLICKS' | 'MAXIMISE_IMPRESSIONS' | 'MAXIMISE_CONVERSIONS';
export type DynamicCampaignGoalValue = '' | 'MAXIMISE_CLICKS' | 'MAXIMISE_CONVERSIONS' | 'MAXIMISE_SALES';

export interface CampaignGoal {
  value: CampaignGoalValue;
  label: CampaignGoalLabel;
}

export const allCampaignGoals: CampaignGoal[] = [
  { value: 'MAXIMISE_CLICKS', label: 'traffic' },
  { value: 'MAXIMISE_IMPRESSIONS', label: 'brand awareness' },
  { value: 'MAXIMISE_CONVERSIONS', label: 'conversions' },
];
