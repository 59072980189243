import {AbstractResource} from '@/core/api/abstract.api';
import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import toastNotificationStore from '@/core/store/toastNotification.store';

import {Workspace} from '../models/workspace.model';
import httpService from '@/core/plugins/httpService';

export default class WorkspaceResource extends AbstractResource<Workspace, WorkspaceJson, WorkspaceJson> {
   

    public async findUserWorkspaces(userId: string): Promise<Workspace[] | void> {

        return httpService.get('/' + this.resourceOwner + `/members/${userId}/workspaces`)
            .then((response) => this.hydrateCollection(response.data as WorkspaceJson[])).catch((error) => {
                toastNotificationStore.showToastNotification({
                    message: error.response.data.message,
                    isError: true,
                  });
            });
    }

    public async rename(workspace: Workspace): Promise<Workspace> {
        const response = await httpService.put(this.collectionEndpoint + '/' + this.identifier(workspace), this.extract(workspace));
        return response.data;
    }

    public delete(workspace: Workspace): Promise<void> {
        return httpService.delete(this.collectionEndpoint + '/' + this.identifier(workspace))
          .then((response) => Promise.resolve());
    }

    protected identifier(workspace: Workspace): string | undefined {
        return workspace.slug;
    }

    protected get resourceName() {
        return 'workspaces';
    }

    protected get resourceOwner(): string {
        const accountsStore = useAccountsStore();
        if (!accountsStore.selectedAccount) {
            const message = 'No selected account found';
            toastNotificationStore.showToastNotification({
                message,
                isError: true,
            });
            throw Error(message);
        }
        return `accounts/${accountsStore.selectedAccount!.id}`;
    }

    protected extract(model: Workspace): WorkspaceJson {
        return {
            id: model.id,
            name: model.name,
            slug: model.slug,
        };
    }

    protected hydrate(json: WorkspaceJson): Workspace {
        return {
            id: json.id,
            name: json.name,
            slug: json.slug,
            campaignIds: json.campaign_ids ? json.campaign_ids : [],
        };
    }
}

interface WorkspaceJson {
    id: string;
    name: string;
    slug: string;
    campaign_ids?: string[];
}
