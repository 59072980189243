import { CroppedImage } from '@/modules/medialibrary/models/masterImage.model';
import httpService from '@/core/plugins/httpService';

export default class CroppedImageApi {
    public create(masterImageId: string): Promise<any> {
        return httpService.post('/master-images/' + masterImageId + '/cropped-images')
          .then((response) => this.hydrate(response.data as CroppedImageJson))
          .catch(console.error);
    }

    public replaceImage(id: string, base64Output: string, copy: string): Promise<any> {
        return httpService.put('/cropped-images/' + id, { image: base64Output, copy })
          .then((response) => this.hydrate(response.data as CroppedImageJson))
          .catch(console.error);
    }

    public hydrate(json: CroppedImageJson): CroppedImage {
        return {
            id: String(json.cropped_image_id),
            url: json.cropped_image_url,
            masterUrl: json.master_image_url,
            thumbNailUrl: json.cropped_image_url,
            type: 'image',
        };
    }
}

export interface CroppedImageJson {
    cropped_image_id: string;
    cropped_image_url: string;
    master_image_url: string;
}
