import Campaigns from './pages/Campaigns.vue';
import CampaignActivity from '@/modules/campaigns/pages/CampaignActivity.vue';
import CampaignPresets from '@/modules/campaigns/pages/CampaignPresets.vue';
import CombinedPage from '@/modules/campaigns/pages/CombinedPage.vue';
import ErroredCampaign from '@/modules/campaigns/pages/ErroredCampaigns.vue';
import ManualSettingsPresets from '@/modules/campaigns/components/ManualSettingsPresets.vue';
import TemplateSettingsPresets from '@/modules/campaigns/components/TemplateSettingsPresets.vue';

export default [
  {
    path: '/',
    redirect: '/campaigns-overview',
  },
  {
    path: '/campaigns-overview',
    name: 'campaigns-overview',
    component: Campaigns,
  },
  {
    path: '*',
    redirect: '/campaigns-overview',
  },
  {
    path: '/campaigns-overview/set-campaign',
    name: 'create-campaign',
    component: CampaignPresets,
    children: [
      {
        path: 'manual-settings',
        name: 'manual-settings',
        component: ManualSettingsPresets,
      },
      {
        path: 'template-settings',
        name: 'template-settings',
        component: TemplateSettingsPresets,
      },
    ],
  },
  {
    path: '/campaigns-overview/edit',
    name: 'edit-campaign-type',
    component: CampaignPresets,
    children: [
      {
        path: 'manual-settings/:campaignId',
        name: 'edit-manual-settings',
        component: ManualSettingsPresets,
      },
      {
        path: 'template-settings/:campaignId',
        name: 'edit-template-settings',
        component: TemplateSettingsPresets,
      },
    ],
  },
  {
    path: '/campaigns-overview/set-campaign/:campaignId/:page',
    name: 'edit-campaign',
    props: true,
    component: CombinedPage,
  },
  {
    path: '/campaigns-overview/activity/:campaignId',
    name: 'campaign-activity',
    component: CampaignActivity,
    props: true,
  },
  {
    path: '/campaigns-overview/errored',
    name: 'errored-campaigns',
    component: ErroredCampaign,
    props: true,
  },
];
