import { AdType } from '@/modules/ads/models/ads.model';

const facebookPostValidation: Record<string, any> = {
  message: { warning: 105, error: 350, lineBreaks: 3 },
  headline: { warning: 40, error: 80 },
  linkDescription: { warning: 30, error: 125 },
  imageMinWidth: 500,
  videoMaxDuration: 900, // 15min
};
const facebookCarouselValidation: Record<string, any> = {
  message: { warning: 105, error: 350, lineBreaks: 3 },
  headline: { warning: 30, error: 80 },
  linkDescription: { warning: 20, error: 40 },
  imageMinWidth: 500,
  videoMaxDuration: 900, // 15min
  videoRatio: [{ x: 1, y: 1 }],
};
const facebookStoryValidation: Record<string, any> = {
  videoMaxDuration: 900, // 15min
  videoRatio: [{ x: 9, y: 16 }],
  videoMinWidth: 500,
  imageMinWidth: 500,
};
const instagramPostValidation: Record<string, any> = {
  message: { warning: 74, error: 2200, lineBreaks: 2 },
  imageMinWidth: 500,
  videoMaxSize: 30, // mb
  videoRatio: [{ x: 4, y: 5 }, { x: 16, y: 9 }, { x: 1, y: 1}],
  videoMinWidth: 500,
};
const instagramCarouselValidation: Record<string, any> = {
  message: { warning: 74, error: 2200, lineBreaks: 2 },
  imageMinWidth: 500,
  videoMaxDuration: 60, // 1min
  videoMaxSize: 30, // mb
  videoRatio: [{ x: 1, y: 1 }],
};
const instagramStoryValidation: Record<string, any> = {
  videoMaxSize: 30, // mb
  videoMaxDuration: 120, // 2min
  videoRatio: [{ x: 9, y: 16 }],
  videoMinWidth: 500,
  imageMinWidth: 500,
};
const linkedinPostValidation: Record<string, any> = {
  message: { warning: 130, error: 600, lineBreaks: 3 },
  headline: { warning: 55, error: 200 },
  linkDescription: { warning: 100, error: 300 },
  imageMinWidth: 500,
  videoMaxSize: 200, // mb
};
const googleSearchValidation: Record<string, any> = {
  headlineCharacterLimit: 30,
  descriptionCharacterLimit: 90,
  linkPathCharacterLimit: 15,
};
const googleBannerGenerationValidation: Record<string, any> = {
  type: { warning: 17, error: 17 },
  subtitle: { warning: 75, error: 75 },
  title: { warning: 55, error: 55 },
  callToAction: { warning: 15, error: 15 },
};
const googleBannerGenerationReduce: Record<string, any> = {
  smallBanner: {
    title: 20,
    subtitle: 20,
    callToAction: 20,
  },
  banner: {
    title: 20,
    subtitle: 20,
    callToAction: 20,
    tags: 17,
  },
  leaderboard: {
    title: 55,
    subtitle: 20,
    callToAction: 15,
    tags: 17,
  },
  largeLeaderboard: {
    title: 20,
    subtitle: 20,
    callToAction: 15,
    tags: 17,
  },
  mediumRectangle: {
    type: 16,
    title: 20,
    subtitle: 20,
    callToAction: 15,
    tags: 17,
  },
  largeRectangle: {
    type: 20,
    title: 20,
    subtitle: 20,
    callToAction: 15,
    tags: 17,
  },
  skyscraper: {
    title: 20,
    subtitle: 20,
    callToAction: 20,
    tags: 17,
  },
  wideSkyscraper: {
    title: 20,
    subtitle: 20,
    callToAction: 20,
    tags: 17,
  },
  halfPage: {
    type: 11,
    title: 20,
    subtitle: 25,
    callToAction: 20,
    tags: 17,
  },
};

const typeToValidationMap: Record<AdType, any> = {
  FacebookPostAd: facebookPostValidation,
  FacebookCarouselAd: facebookCarouselValidation,
  FacebookStoryAd: facebookStoryValidation,
  InstagramPostAd: instagramPostValidation,
  InstagramCarouselAd: instagramCarouselValidation,
  InstagramStoryAd: instagramStoryValidation,
  LinkedinPostAd: linkedinPostValidation,
  GoogleDisplayCustomAd: {},
  GoogleDisplayGeneratedAd: {},
  GoogleSearchAd: {},
};

function getValidationByAdType(adType: AdType | null): any {
  return adType ? typeToValidationMap[adType] : {};
}

export {
  getValidationByAdType,
  facebookPostValidation,
  facebookCarouselValidation,
  facebookStoryValidation,
  instagramPostValidation,
  instagramCarouselValidation,
  instagramStoryValidation,
  linkedinPostValidation,
  googleSearchValidation,
  googleBannerGenerationValidation,
  googleBannerGenerationReduce,
};
