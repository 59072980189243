import { watchEffect } from 'vue'
import { NavigationGuard } from 'vue-router';
import { getInstance } from '@/modules/auth/auth';
import localUserStore from '@/modules/auth/store/localUser.store';

export const auth0Guard: NavigationGuard = (to, from, next) => {
    const authService = getInstance();

    const fn = async () => {
        // Unwatch loading
        // unwatch && unwatch(); ----> Not sure what this does? Commented it out for now because
        // it was creating a TS build error, it doesn't look like it was calling anything actually
        // and it didnt' cause any errors when commented out.
        if (to.matched.some((record) => record.meta.noAuthRequired)) {
            return next();
        }
        // If the user is authenticated, continue with the route
        if (authService.authPlugin.isAuthenticated.value) {
            // Make sure the user profile is loaded
            if (!localUserStore.user) {
                await getInstance().fetchToken();
                await localUserStore.profile();
                await localUserStore.userMemberships();
                await localUserStore.intercomHashKey();
                
                if (process.env.NODE_ENV === 'production') {
                    if (process.env.VUE_APP_INTERCOM_APP_ID) {
                        if (!!window.Intercom) {
                            window.Intercom('boot', {
                              app_id: process.env.VUE_APP_INTERCOM_APP_ID,
                              name: localUserStore.user!.name,
                              email: localUserStore.user!.email,
                              created_at: localUserStore?.intercomKey!.created_at || 0,
                              user_id: localUserStore?.user!.id || '',
                              user_hash: localUserStore.intercomKey!.hash,
                            });
                        }
                    }
                }
            }

            return next();
        }

        // Otherwise, log in
        authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.auth0Client) {
        return fn();
    }

    // Watch for the loading property to change before we check isAuthenticated

    watchEffect(() => {
        if (!authService.authPlugin.loading.value) {
            return fn();
        }
    })
};
