import httpService from '@/core/plugins/httpService';
import {GoogleDisplayGeneratedAd, GoogleDisplayGeneratedAdParams} from '../models/googleAds.model';
import defaultTemplateStore from '../../templates/default/store/defaultTemplates.store';
import toastNotificationStore from '@/core/store/toastNotification.store';
import { handleResponseError } from '@/core/assets/global-utils';

export default class GoogleDisplayGeneratedAdResource {
  protected get resourceName() {
    if (!defaultTemplateStore.selectedDefaultTemplate) {
      throw new Error('Trying to perform ad related actions without a selected template.');
    }
    return `templates/${defaultTemplateStore.selectedDefaultTemplate.id}/google-banner-templates`;
  }

  protected get endpoint(): string {
    return '/' + this.resourceName;
  }

  public findAll(): Promise<GoogleDisplayGeneratedAd[] | void> {
    return httpService.get(this.endpoint)
      .then((response) => this.hydrateCollection(response.data as GoogleBannerGeneratedAdResponseJson[]))
      .catch((error) => {
        handleResponseError(error);
      });
  }
  
  public create(adParams: GoogleDisplayGeneratedAdParams): Promise<GoogleDisplayGeneratedAd | void> {
    return httpService.post(this.endpoint, this.extract(adParams))
      .then((response) => { 
        return this.hydrate(response.data as GoogleBannerGeneratedAdResponseJson);
      })
      .catch((error) => {
        toastNotificationStore.showToastNotification({ 
          message: `Failed to generate ads.`, 
          isError: true,
        });
      });
  }

  public update(ad: GoogleDisplayGeneratedAd): Promise<GoogleDisplayGeneratedAd | void> {
    return httpService.put('/google-banner-templates/' + ad.id, this.extract(ad))
      .then((response) => this.hydrate(response.data as GoogleBannerGeneratedAdResponseJson))
      .catch((error) => {
        handleResponseError(error);
      });
  }

  public delete(ad: GoogleDisplayGeneratedAd) {
    return httpService.delete('/google-banner-templates/' + ad.id);
  }

  protected hydrate(json: GoogleBannerGeneratedAdResponseJson): GoogleDisplayGeneratedAd {
    return {
      id: json.id,
      type: json.type,
      subtitle: json.intro,
      title: json.title,
      callToAction: json.call_to_action,
      tags: json.tags,
      primaryColor: '#2E395D',
      logoUrl: json.logo_url,
    };
  }

  protected hydrateCollection(responseCollection: GoogleBannerGeneratedAdResponseJson[]): GoogleDisplayGeneratedAd[] {
    return responseCollection.map((response) => this.hydrate(response));
  }

  protected extract(model: GoogleDisplayGeneratedAdParams): GoogleBannerGeneratedAdRequestJson {
    const { type, subtitle, title, callToAction, tags, primaryColor, logoUrl } = model;
    return {
      type,
      intro: subtitle,
      title,
      call_to_action: callToAction,
      tags,
      background_color: primaryColor,
      logo_url: logoUrl,
    };
  }
}

interface GoogleBannerGeneratedAdResponseJson {
  background_color: string;
  call_to_action: string;
  id: number;
  intro: string;
  logo_url: string;
  tags: string[];
  title: string;
  type: string;
}

interface GoogleBannerGeneratedAdRequestJson {
  background_color: string;
  call_to_action: string;
  intro: string;
  logo_url: string;
  tags: string[];
  title: string;
  type: string;
}
