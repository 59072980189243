import { defineStore, Store, StoreDefinition } from 'pinia';
import { state, actions, getters, BaseState, BaseGetters, BaseActions } from '@/core/store/common/ads.store'
import { PiniaStore } from '@/core/store/piniaTypes';

import { AbstractResource } from '@/core/api/abstract.api';

import adsEditorStore from '@/modules/ads/store/adsEditor.store';
import { useSocialPagesStore } from '@/modules/accounts/store/socialPages.store';

import { Ad as AbstractAd } from '@/modules/ads/models/ads.model';
import { AdMedia } from '@/modules/medialibrary/models/video.model';
import FacebookCarouselAdResource, { FacebookCarouselAdRequestJson, FacebookCarouselAdResponseJson } from '@/modules/ads/api/facebookCarouselAd.api';
import FacebookPostAdResource, { FacebookPostAdRequestJson, FacebookPostAdResponseJson } from '@/modules/ads/api/facebookPostAd.api';
import FacebookStoryAdResource, { FacebookStoryAdRequestJson, FacebookStoryAdResponseJson } from '@/modules/ads/api/facebookStoryAd.api';
import { FacebookCarouselAd, FacebookPostAd, FacebookStoryAd } from '@/modules/ads/models/facebookAds.model';
import { SocialPage } from '@/modules/accounts/models/socialPage.model';

interface FacebookCarouselState extends BaseState<AbstractAd> {
  resource: AbstractResource<FacebookCarouselAd, FacebookCarouselAdRequestJson, FacebookCarouselAdResponseJson>;
}

interface FacebookPostState extends BaseState<AbstractAd> {
  resource: AbstractResource<FacebookPostAd, FacebookPostAdRequestJson, FacebookPostAdResponseJson>;
}

interface FacebookStoryState extends BaseState<AbstractAd> {
  resource: AbstractResource<FacebookStoryAd, FacebookStoryAdRequestJson, FacebookStoryAdResponseJson>;
}

interface FacebookCarouselActions extends BaseActions<FacebookCarouselAd> {
  createAds(ad:FacebookCarouselAd): Promise<void>;
}

interface FacebookPostActions extends BaseActions<FacebookPostAd> {
  createAds(ad: FacebookPostAd): Promise<void>;
  updateFacebookPage(id: string | null | undefined): SocialPage | undefined;
}

interface FacebookStoryActions extends BaseActions<FacebookStoryAd> {
  createAds(ad: FacebookStoryAd): Promise<void>;
}

type FacebookCaourselStore = Store<'facebookCarouselAdStore', FacebookCarouselState, BaseGetters<AdMedia>, FacebookCarouselActions>;
type FacebookPostStore = Store<'facebookPostAdStore', FacebookPostState, BaseGetters<AdMedia>, FacebookPostActions>;
type FacebookStoryStore = Store<'facebookStoreAdStore', FacebookStoryState, BaseGetters<AdMedia>, FacebookStoryActions>;

type FacebookCarouselStoreDefinition = StoreDefinition<'facebookCarouselStore', FacebookCarouselState, BaseGetters<AdMedia>, FacebookCarouselActions>
type FacebookPostStoreDefinition = StoreDefinition<'facebookPostStore', FacebookPostState, BaseGetters<AdMedia>, FacebookPostActions>;
type FacebookStoryStoreDefinition = StoreDefinition<'facebookStoryStore', FacebookStoryState, BaseGetters<AdMedia>, FacebookStoryActions>;

const extendedCaourselStore: PiniaStore<FacebookCaourselStore> = {
  state: () => {
    return {
      ...state(),
      resource: FacebookCarouselAdResource.prototype,
    }
  },
  getters: {
    ...getters,
    media(): AdMedia[] {
      return adsEditorStore.media.length > 0 ?
      adsEditorStore.media :
      [
        { id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'},
        { id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'},
      ];
    }
  },
  actions: {
    ...actions,
    async createAds(ad: FacebookCarouselAd): Promise<void> {
      await this.createAd(ad);
    }
  },
}

const extendedPostStore: PiniaStore<FacebookPostStore> = {
  state: () => {
    return {
      ...state(),
      facebookPage: {},
      resource: FacebookPostAdResource.prototype,
    }
  },
  getters: {
    ...getters,
    media(): AdMedia[] {
      return adsEditorStore.media.length > 0 ?
        adsEditorStore.media :
        [{ id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'}];
    },
  },
  actions: {
    ...actions,
    updateFacebookPage(id: string | null | undefined): SocialPage | undefined {
      const socialPagesStore = useSocialPagesStore();
      const socialPage = socialPagesStore.returnSocialPage({id, channel: 'facebook'});
      this.facebookPage = socialPage;
      return socialPage;
    },
    async createAds(): Promise<void> {
      for (const media of this.media) {
        const facebookPostAd: FacebookPostAd = {
          media,
          ...adsEditorStore.copy,
          status: 'active',
        };
        await this.createAd(facebookPostAd);
      }
    },
  }
}

const extendedStoryStore: PiniaStore<FacebookStoryStore> = {
  state: () => {
    return {
      ...state(),
      resource: FacebookStoryAdResource.prototype,
    }
  },
  getters: {
    ...getters,
    media(): AdMedia[] {
      return adsEditorStore.media.length > 0 ?
        adsEditorStore.media :
        [{ id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'}];
    },
  },
  actions: {
    ...actions,
    async createAds(): Promise<void> {
      for (const media of this.media) {
        const facebookStoryAd: FacebookStoryAd = {
          media,
          ...adsEditorStore.copy,
          status: 'active',
        };
        await this.createAd(facebookStoryAd);
      }
    },
  },
}

export const useFacebookCarouselStore: FacebookCarouselStoreDefinition = defineStore('facebookCarouselStore', extendedCaourselStore);
export const useFacebookPostStore: FacebookPostStoreDefinition = defineStore('facebookPostStore', extendedPostStore);
export const useFacebookStoryStore: FacebookStoryStoreDefinition = defineStore('facebookStoryStore', extendedStoryStore);