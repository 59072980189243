import Vue from 'vue';
import Router from 'vue-router';
import Campaigns from './modules/campaigns';
import JobLibrary from './modules/JobLibrary';
import Ads from './modules/ads';
import MediaLibrary from './modules/medialibrary';
import Settings from './modules/settings';
import httpService from '@/core/plugins/httpService';
import {auth0Guard} from '@/modules/auth/auth0Guard';
import localAuthStore from '@/modules/auth/store/localAuth.store';
import localUserStore from '@/modules/auth/store/localUser.store';
import Templates from './modules/templates';
import TemplateAds from './modules/template-ads';
import Packages from './modules/packages';
import UiGuidelines from './ui-guideline.vue';

const isProdMode = process.env.NODE_ENV === 'production';
const isProdDomain = window.location.href.includes('launch');
const authDriver = process.env.VUE_APP_AUTH_DRIVER;

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...Ads,
    ...Campaigns,
    ...JobLibrary,
    ...MediaLibrary,
    ...Settings,
    ...Templates,
    ...TemplateAds,
    ...Packages,
    {
      path: '/ui',
      component: UiGuidelines,
      beforeEnter: (to, from, next) => {
        if (isProdMode && isProdDomain) {
          window.location.href = '/';
        } else {
          next();
        }
      },
    },
    {
      path: '/invitations/:invitationId/accept',
      beforeEnter: async (to) => {
        // Call the backend and get the follow up action
        const response = await httpService.get(to.fullPath);
        if (response.data.redirect_to === 'LOGIN') {
          Vue.prototype.$auth.loginWithRedirect();
        }
        window.location.href = response.data.redirect_to;
        return;
      },
      meta: {
        noAuthRequired: true,
      },
    },
  ],
  scrollBehavior(to) {
    if (to.hash) {
      const hashElement: HTMLElement | null = document.querySelector(to.hash);
      if (hashElement) { hashElement.scrollIntoView({ behavior: 'smooth' }); }
    }
  },
});

const originalRouterPush: any = Router.prototype.push;
Router.prototype.push = function push(location: object) {
  return originalRouterPush.call(this, location).catch(() => { /* suppresses navigation warnings */ });
};

switch (authDriver) {
  case 'auth0':
    router.beforeEach(auth0Guard);
    break;
  case 'local':
    if (isProdMode) {
      throw Error('Local auth not allowed in production');
    }
    router.beforeEach(async (to, _, next) => {
      const isLoggedIn = await localAuthStore.authenticate();
      const noAuthRequired = to.matched.some((record) => record.meta.noAuthRequired);
      const notLoggedInAuthRequired = !noAuthRequired && !isLoggedIn;

      if (notLoggedInAuthRequired) {
        next('sign-in');
      } else {
        // fetch profile if it is not yet done
        if (isLoggedIn && !localUserStore.user) {
          await localUserStore.profile();
          await localUserStore.userMemberships();
          await localUserStore.intercomHashKey();
        }
        next();
      }
    });
    break;
  default:
    router.beforeEach(auth0Guard);
}

export default router;
