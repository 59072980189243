import { defineStore } from 'pinia'
import NotificationResource from '@/modules/notifications/api/notification.api';
import { Notification } from '@/modules/notifications/models/notification.model';

interface State {
  notifications: Notification[]
}

export const useNotificationStore = defineStore('notificationStore', {
  state: (): State => {
    return { 
      notifications: []
    }
  },
  getters: {
    allNotifications(state): Notification[] {
      return state.notifications;
    },
    pinnedNotifications(state) {
      return state.notifications.filter((item) => {
        return item.pinned === true;
      });
    }
  },
  actions: {
    async fetchNotifications(): Promise<Notification[]> {
      const notifications = await NotificationResource.prototype.findAll();
      this.notifications = notifications
      return notifications;
    },
    deleteNotification(notification: Notification) {
      NotificationResource.prototype.deleteNotification(notification);
      this.notifications = this.notifications.filter((item) => {
        return notification.id !== item.id;
      });
    },
    updatePinStatus(notification: Notification) {
      if (!notification.pinned) {
        NotificationResource.prototype.pinNotification(notification);
      } else {
        NotificationResource.prototype.unpinNotification(notification);
      }
      notification.pinned = !notification.pinned
    },
    updateMarkReadStatus(notification: Notification) {
      if (!notification.read) {
        NotificationResource.prototype.markRead(notification);
      } else {
        NotificationResource.prototype.markUndread(notification);
      }
      notification.read = !notification.read
    },
    markAllRead() {
      NotificationResource.prototype.markAllRead();
      this.notifications = this.notifications.map((item) => ({ ...item, read: true }));
    }
  },
});
