import store from '@/core/store/index.store';
import adsEditorStore from '../templateAdsEditor.store';
import {Action, config, getModule, Module} from 'vuex-module-decorators';
import { AbstractResource } from '@/core/api/abstract.api';
import { AdMedia } from '@/modules/medialibrary/models/video.model';
import InstagramStoryAdResource, {InstagramStoryAdRequestJson, InstagramStoryAdResponseJson} from '../../api/instagramStoryAd.api';
import {AbstractAds} from '../abstractTemplateAds.store';
import {InstagramStoryAd} from '../../models/instagramAds.model';

config.rawError = true;

@Module({
  name: 'instagramStoryTemplates',
  namespaced: true,
  dynamic: true,
  store,
})
class InstagramStoryAds extends AbstractAds<InstagramStoryAd, InstagramStoryAdResponseJson, InstagramStoryAdRequestJson> {
  protected get resource(): AbstractResource<InstagramStoryAd, InstagramStoryAdResponseJson, InstagramStoryAdRequestJson> {
    return InstagramStoryAdResource.prototype;
  }

  get media(): AdMedia[] {
    return adsEditorStore.media.length > 0 ?
      adsEditorStore.media :
      [{ id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'}];
  }

  @Action
  public async createAds(): Promise<void> {
    for (const media of this.media) {
      const instagramStoryAd: InstagramStoryAd = {
        media,
        ...adsEditorStore.copy,
      };
      await this.createAd(instagramStoryAd);
    }
  }
}

export default getModule(InstagramStoryAds, store);
