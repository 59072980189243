import { Store } from 'pinia'
import { PiniaActions, PiniaActionTree, PiniaGetterTree, PiniaGetters, PiniaStateTree, PiniaState } from '@/core/store/piniaTypes';
import { AdMedia } from '@/modules/medialibrary/models/video.model';
import { Ad as AbstractAd } from '@/modules/ads/models/ads.model';
import adsEditorStore from '@/modules/ads/store/adsEditor.store';

type BaseStore<Ad extends AbstractAd, AdMedia> = Store<'adBaseStore', BaseState<Ad> ,BaseGetters<AdMedia>, BaseActions<Ad>>

export interface BaseActions<Ad extends AbstractAd> extends PiniaActionTree {
  setAds(ads: Ad[]): void;
  appendAd(ad: Ad): void;
  emptyNewCreatedAds(): void;
  updateAd(ad: Ad): Promise<Ad[]>;
  createAd(ad: Ad): Promise<Ad | null>;
  deleteAd(removingAd: Ad): Promise<Ad[]>;
  fetchAds(): Promise<Ad[]>;
}
export interface BaseGetters<AdMedia> extends PiniaGetterTree {
  media(): AdMedia[]
}
export interface BaseState<Ad extends AbstractAd> extends PiniaStateTree {
  loading: boolean;
  ads: Ad[];
  newCreatedAds: Ad[];
  resource: Record<any, any>
}

export const state: PiniaState<BaseStore<AbstractAd, AdMedia>> = () => ({
  loading: true,
  ads: [],
  newCreatedAds: [],
  resource: {},
});

export const actions: PiniaActions<BaseStore<AbstractAd, AdMedia>> = {
  setAds(ads: AbstractAd[]) { 
    this.ads = ads; 
  },
  appendAd(ad: AbstractAd) {
    this.ads.push(ad);
    this.newCreatedAds.push(ad);
  },
  emptyNewCreatedAds() { 
    this.newCreatedAds = []; 
  },
  async updateAd(ad: AbstractAd): Promise<AbstractAd[]> {
    await this.resource.update(ad);
    const ads =  await this.resource.findAll();
    this.setAds(ads);
    return ads;
  },
  async createAd(ad: AbstractAd): Promise<AbstractAd | null> {
    try {
      const newAd = await this.resource.create(ad);
      this.appendAd(newAd);
      return newAd;
    } catch (err) {
      return null;
    }
  },
  async deleteAd(removingAd: AbstractAd): Promise<AbstractAd[]> {
    await this.resource.delete(removingAd);
    const ads = this.ads.filter((ad) => {
      return removingAd.id !== ad.id;
    });
    this.setAds(ads);
    return ads;
  },
  async fetchAds(): Promise<AbstractAd[]> {
    const ads = await this.resource.findAll();
    this.loading = false;
    this.setAds(ads);
    return ads;
  },
}; 

export const getters: PiniaGetters<BaseStore<AbstractAd, AdMedia>> = {
  media(): AdMedia[] {
    return adsEditorStore.media.length > 0 ?
      adsEditorStore.media :
      [{ id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'}];
  }
};
