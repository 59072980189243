import { defineStore } from 'pinia'
import {
  AccountSettings,
  SocialPageConnectionRequest,
  ConnectedSocialPage,
  ChannelAccounts,
  EnabledFeatures,
} from '@/modules/settings/models/accountSettings.model';
import AccountSettingsApi from '@/modules/settings/api/accountSettings.api';
import _ from 'lodash';

interface State {
  accountSettings: AccountSettings | null;
  channelAccounts: ChannelAccounts | null ;
  currentDefaultLandingPageUrl: string
  conversionId: string
  dynamicAdsPixelId: string
  socialPageConnectionRequests: SocialPageConnectionRequest[]
  connectedSocialPages: ConnectedSocialPage[]
  targetLanguage: string
  enabledFeatures: EnabledFeatures | null;
}
export const useAccountSettingsStore = defineStore('accountSettings', {
  state: (): State => {
    return { 
      accountSettings: null,
      channelAccounts: null,
      currentDefaultLandingPageUrl: '',
      conversionId: '',
      dynamicAdsPixelId: '',
      socialPageConnectionRequests: [],
      connectedSocialPages: [],
      targetLanguage: '',
      enabledFeatures: null
    }
  },
  getters: {
    currency(state) {
      return _.get(state.accountSettings, 'currency');
    },
    currencySymbol(state){
      return _.get(state.accountSettings, 'currency.symbol', '€');
    },
    currencyCode() {
      const currencyMap: Record<string, string> = {
        EUR: 'euro',
        GBP: 'pounds',
        USD: 'dollars',
        ZWD: 'Zimbabwean dollars',
      };
  
      return currencyMap[_.get(this.currency, 'code', '')];
    },
    accountConversionId(state) {
      return state.conversionId;
    },
    facebookPages(state) {
      return state.connectedSocialPages.filter((page) => page.channel === 'facebook');
    },
    instagramPages(state) {
      return state.connectedSocialPages.filter((page) => page.channel === 'instagram');
    },
    linkedInPages(state) {
      return state.connectedSocialPages.filter((page) => page.channel === 'linkedin');
    },
    facebookConnectionRequests(state) {
      return state.socialPageConnectionRequests.filter((request) => request.channel === 'facebook' && request.status === 'pending');
    },
    instagramConnectionRequests(state) {
      return state.socialPageConnectionRequests.filter((request) => request.channel === 'instagram' && request.status === 'pending');
    },
    linkedInConnectionRequests(state) {
      return state.socialPageConnectionRequests.filter((request) => request.channel === 'linkedin' && request.status === 'pending');
    }
  },
  actions: {
    updateTargetLanguage(targetLanguage: string) {
      this.targetLanguage = targetLanguage;
    },
    setDynamicAdsPixelId(dynamicAdsPixelId: string) {
      this.dynamicAdsPixelId = dynamicAdsPixelId;
    },
    async getAccountSettings(): Promise<void> {
      AccountSettingsApi.prototype.getAccountSettings().then((settings) => {
        this.currentDefaultLandingPageUrl = settings.defaultLandingPageUrl;
        this.connectedSocialPages = settings.connectedSocialPages;
        this.targetLanguage = settings.targetLanguage;
        this.channelAccounts = settings?.channelAccounts ?? null;

        this.getEnabledFeatures();
        this.getDynamicAdsPixelId();
        this.getConversionId();
        this.setAccountSettings(settings);
       });
    },
    setAccountSettings(settings: AccountSettings) {
      this.accountSettings = settings;
    },
    async getDynamicAdsPixelId() {
      const { pixelId } = await AccountSettingsApi.prototype.fetchDynamicAdsPixelId();
      this.dynamicAdsPixelId = pixelId;
    },
    async getEnabledFeatures() {
      const enabledFeatures = await AccountSettingsApi.prototype.fetchAllEnabledFeatures();
      if (enabledFeatures) {
        this.enabledFeatures = enabledFeatures
      }
    },
    async getConversionId() {
      const { conversionId } = await AccountSettingsApi.prototype.fetchConversionId();
      this.setConversionId(conversionId);
    },
    setConversionId(conversionId: string) {
      this.conversionId = conversionId;
    },
    async updateDynamicAdsPixelId(pixelId: string): Promise<void> {
      await AccountSettingsApi.prototype.updateDynamicAdsPixelId(pixelId);
      this.dynamicAdsPixelId = pixelId;
    },
    async completeConnectionRequest(request: SocialPageConnectionRequest): Promise<void> {
      await AccountSettingsApi.prototype.completeSocialAccountConnectionRequest(request);
    },
    async deleteSocialChannelPage(page: ConnectedSocialPage): Promise<void | ConnectedSocialPage> {
      await AccountSettingsApi.prototype.deleteSocialChannelPage(page);
      this.connectedSocialPages = this.connectedSocialPages.filter((socialPage) => socialPage !== page)
    },
    async updateDefaultValues({url, defaultUtms, targetLanguage}: {url: string, defaultUtms: any, targetLanguage: string}): Promise<void> {  
      await AccountSettingsApi.prototype.updateDefaultValues(url, defaultUtms, targetLanguage)
      this.currentDefaultLandingPageUrl = url
    },
    async updateConversionId(conversionId: string): Promise<void> {
      await AccountSettingsApi.prototype.updateConversionId(conversionId);
      this.conversionId = conversionId;
    },
    async fetchSocialPageConnectionRequests(): Promise<void> {
      const requests = await AccountSettingsApi.prototype.getSocialPageConnectionRequests()
      this.socialPageConnectionRequests = requests;
    },
    async createSocialAccountConnectionRequest(request: {channel: string, url: string, page_id: string}): Promise<void> {
      const createdRequest = await AccountSettingsApi.prototype.createSocialAccountConnectionRequest(request);
      if (createdRequest) {
        this.socialPageConnectionRequests.push(createdRequest)
      }
    },
    async requestLeadformsCreation(pageId: string) {
      const leadformRequestStatus = await AccountSettingsApi.prototype.requestLeadformsCreation(pageId);
      const updatedSocialPages = this.connectedSocialPages.map((socialPage) => {
        return socialPage.id !== pageId ? socialPage : { ...socialPage, leadformRequestStatus };
      });
      this.connectedSocialPages = updatedSocialPages
    }
  },
});
