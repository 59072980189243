import store from '@/core/store/index.store';
import adsEditorStore from '../templateAdsEditor.store';
import { Action, getModule, Module, Mutation, config } from 'vuex-module-decorators';
import { AbstractAds } from '../abstractTemplateAds.store';
import { AbstractResource } from '@/core/api/abstract.api';
import { AdMedia } from '@/modules/medialibrary/models/video.model';
import FacebookPostAdResource, {FacebookPostAdRequestJson, FacebookPostAdResponseJson} from '../../api/facebookPostAd.api';
import {FacebookPostAd} from '../../models/facebookAds.model';
import { SocialPage } from '@/modules/accounts/models/socialPage.model';
import { useSocialPagesStore } from '@/modules/accounts/store/socialPages.store';

config.rawError = true;

@Module({
  name: 'facebookPostTemplates',
  namespaced: true,
  dynamic: true,
  store,
})
class FacebookPostAds extends AbstractAds<FacebookPostAd, FacebookPostAdResponseJson, FacebookPostAdRequestJson> {
  protected get resource(): AbstractResource<FacebookPostAd, FacebookPostAdResponseJson, FacebookPostAdRequestJson> {
    return FacebookPostAdResource.prototype;
  }

  public facebookPage: SocialPage | undefined | object = {};

  get media(): AdMedia[] {
    return adsEditorStore.media.length > 0 ?
      adsEditorStore.media :
      [{ id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'}];
  }

  @Mutation
  public async setFacebookPage(page: SocialPage | {} | undefined) { 
    this.facebookPage = page;
  }

  @Action({commit: 'setFacebookPage'})
  public updateFacebookPage(id: string | null | undefined) {
    const socialPagesStore = useSocialPagesStore();
    return socialPagesStore.returnSocialPage({id, channel: 'facebook'});
  }

  @Action
  public async createAds(): Promise<void> {
    for (const media of this.media) {
      const facebookPostAd: FacebookPostAd = {
        media,
        ...adsEditorStore.copy,
      };
      await this.createAd(facebookPostAd);
    }
  }
}

export default getModule(FacebookPostAds, store);
