import { AdMedia, Video, Media } from '../medialibrary/models/video.model';
import {
  CUSTOM,
  EMPLOYER_BRAND_AWARENESS,
  FAST_APPLY_FORM,
  JOB_APPLICATIONS,
  VACANCY_VISITS,
} from '@/core/store/productConfig';

interface AspectRatio {
  x: number;
  y: number;
}

function cropImageUrlOnAspectRatio(media: AdMedia, aspectRatio: AspectRatio): Promise<AdMedia> {
  // return images.map((image: Media) => {
  return new Promise((resolve, reject) => {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const img: HTMLImageElement = document.createElement('img');
    const ctx = canvas.getContext('2d');

    img.setAttribute('crossorigin', '');
    img.setAttribute('src', media.masterUrl);
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const currentRatio = width / height;
      const newRatio = aspectRatio.x / aspectRatio.y;
      const imageIsToWide: boolean = currentRatio > newRatio;

      if (imageIsToWide) {
        const newWidth = height * newRatio;
        const distanceLeft = (width - newWidth) / 2;
        canvas.setAttribute('width', `${newWidth}px`);
        canvas.setAttribute('height', `${height}px`);
        ctx!.drawImage(img, distanceLeft, 0, newWidth, height, 0, 0, newWidth, height);
      } else {
        const newHeight = width / newRatio;
        const distanceTop = (height - newHeight) / 2;
        canvas.setAttribute('width', `${width}px`);
        canvas.setAttribute('height', `${newHeight}px`);
        ctx!.drawImage(img, 0, distanceTop, width, newHeight, 0, 0, width, newHeight);
      }

      img.remove();
      resolve({ ...media, url: canvas.toDataURL() });
    };
  });
}

function isValidVideoRatio(video: Video | Media, ratios: AspectRatio[] = []): boolean {
  return !ratios.length ? true : ratios.some(({x, y}) => {
    return video.width / video.height === x / y;
  });
}

function isValidVideoMaxDuration(video: Video, videoMaxDuration?: number): boolean {
  return !videoMaxDuration ? true : video.duration <= videoMaxDuration;
}

function isValidVideoMinWidth(video: Video | Media, videoMinWidth?: number): boolean {
  return !videoMinWidth ? true : video.width >= videoMinWidth;
}

function getBudgetNum(str: string): number | null {
  const matchedBudgetStrings = str.match(/\d+$/);
  if (matchedBudgetStrings != null && matchedBudgetStrings.length) {
      return parseInt(matchedBudgetStrings[0], 10);
  } else {
      return null;
  }
}

function generateCreateAdRoute(producType: string) {
  return producType === 'campaign' ? 'create ad' : 'create-template-ad';
}

function generateEditProductRoute(producType: string) {
  if (producType === 'campaign') {
    return 'edit-campaign';
  }
  if (producType === 'customTemplate') {
    return 'edit-custom';
  }
  return 'edit-default';
}

function generateAdsPageRoute(producType: string) {
  return producType === 'campaign' ? 'campaign-details/ads' : 'template-details/ads';
}

const callToActionOptionsByChannel: any = {
  facebookCarousel: ['Contact us', 'Learn more', 'Sign up'],
  facebookPost: ['Apply now', 'Contact us', 'Learn more', 'Sign up', 'Watch more'],
  facebookStory: ['Contact us', 'Learn more', 'Sign up'],
  instagramCarousel: ['Contact us', 'Learn more', 'Sign up'],
  instagramPost: ['Apply now', 'Contact us', 'Learn more', 'Sign up'],
  instagramStory: ['Learn more', 'Sign up'],
  linkedin: ['Apply', 'Attend', 'Download', 'Join', 'Learn more', 'Register', 'Request demo', 'View quote', 'Sign up', 'Subscribe'],
};

const excludedCallToActionByCampaignType: any = {
  [VACANCY_VISITS]: [],
  [EMPLOYER_BRAND_AWARENESS]: [],
  [JOB_APPLICATIONS]: [],
  [FAST_APPLY_FORM]: ['Contact us'],
  [CUSTOM]: [],
};

export {
  AspectRatio,
  callToActionOptionsByChannel,
  cropImageUrlOnAspectRatio,
  excludedCallToActionByCampaignType,
  getBudgetNum,
  generateAdsPageRoute,
  generateCreateAdRoute,
  generateEditProductRoute,
  isValidVideoMaxDuration,
  isValidVideoMinWidth,
  isValidVideoRatio,
};
