import httpService from '@/core/plugins/httpService';
import {User} from '@/modules/auth/models/user.model';
import {Membership} from '@/modules/auth/models/membership.model';
import appStore from '@/core/store/app.store';

export default class UserResource {
    public static profile(): Promise<User> {
        return httpService.get('/me').then((response) => {
            return User.fromLocalUser(response.data);
        });
    }

    public static memberships(): Promise<Membership[]> {
        appStore.updateLoadingState(true);
        return httpService.get('/me/memberships').then((response) => {
            appStore.updateLoadingState(false);

            if (!response.data.length) {
                return [] as Membership[];
            }

            return response.data.map((item: { [key: string]: string }) => {
                return new Membership(item.account_id, item.name, item.role, item.member_since);
            });
        });
    }

    public static intercomHashKey(): Promise<string | null> {
        return httpService.get('/me/intercom').then((response) => {
            return response.data;
        });
    }
}

