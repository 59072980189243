import { defineStore } from 'pinia';

import { GoogleSearchAd } from '@/modules/ads/models/googleSearch.model';
import { GoogleSearchCopy } from '@/modules/ads/store/adsEditor.store';
import GoogleSearchApi from '@/modules/ads/api/googleSearchAd.api';

interface State {
  ads: GoogleSearchAd[];
  newCreatedAds: GoogleSearchAd[];
}

export const useGoogleSearchStore = defineStore('GoogleSearch', {
  state: (): State => {
    return {
      ads: [],
      newCreatedAds: [],
    }
  },
  actions: {
    async create(googleSearchAd: GoogleSearchCopy) {
      const createdAd = await GoogleSearchApi.prototype.create(googleSearchAd);
      this.ads = [...this.ads, createdAd as GoogleSearchAd];
      return createdAd;
    },
    async updateAd(ad: GoogleSearchAd) {
      const editedAd = await GoogleSearchApi.prototype.update(ad);
      this.ads = this.ads.map((ad) => {
        if (ad.id === editedAd.id) {
          return editedAd;
        }
        return ad;
      });
      return editedAd;
    },
    async fetchAds(): Promise<GoogleSearchAd[]> {
      const ads = await GoogleSearchApi.prototype.findAll();
      this.setAds(ads); 
      return ads;
    },
    async deleteAd(removingAd: GoogleSearchAd) {
      await GoogleSearchApi.prototype.delete(removingAd);
      this.ads = this.ads.filter((ad) => removingAd.id !== ad.id);
      return removingAd;
    },
    setAds(ads: GoogleSearchAd[]) {
      this.ads = ads;
    }
  }
})




