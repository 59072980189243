import _ from 'lodash';
import ToastNotificationStore from '@/core/store/toastNotification.store';
import { useAccountSettingsStore } from '@/modules/settings/store/accountSettings.store';
import toastNotificationStore from '@/core/store/toastNotification.store';
import { handleUnknownError } from '@/core/assets/global-utils';

export const handleBudgetError = (err: any) => {
  const status = _.get(err, 'response.status');
  const data = _.get(err, 'response.data');

  if (status === 422 && data) {
    handleMinBudgetError(data);
  } else {
    handleUnknownError(data);
  }
};

export const handleDeployError = (err: any) => {
  const status = _.get(err, 'response.status');
  const data = _.get(err, 'response.data');

  if (status === 422 && data) {
    handleMinBudgetError(data);
  } else {
    toastNotificationStore.showToastNotification({
      message: `Campaign deployment has failed`,
      isError: true,
    });
  }
};

const handleMinBudgetError = (data: any) => {
  const minBudgetPerChannel = _.get(data, 'context', {});
  const channelKey = Object.keys(minBudgetPerChannel)[0];

  if (channelKey) {
    const channelNameMap: Record<string, string> = {
      facebook: 'Facebook | Instagram',
      googleSearch: 'Google Search',
      googleDisplay: 'Google Display',
      linkedIn: 'LinkedIn',
    };
    const channelName = channelNameMap[channelKey];
    const channelMinBudget = minBudgetPerChannel[channelKey] / 100;
    const accountSettingsStore = useAccountSettingsStore();
    ToastNotificationStore.showToastNotification({
      message: `The minimum ${channelName} budget for campaign duration is ${channelMinBudget} ${accountSettingsStore.currencyCode}`,
      isError: true,
    });
  } else {
    handleUnknownError(data);
  }
};
