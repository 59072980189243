import {AbstractResource} from '../../../core/api/abstract.api';
import {MediaType} from '../../medialibrary/models/masterImage.model';
import {LinkedinPostAd} from '../models/linkedinAds.model';
import customTemplateStore from '../../templates/custom/store/customTemplates.store';
import { hydrateAdMedia, AdMediaJson } from '@/modules/ads/api/apiHelpers';

export default class LinkedinPostAdResource extends AbstractResource<
  LinkedinPostAd,
  LinkedinPostAdResponseJson,
  LinkedinPostAdRequestJson
  > {
    
    protected get resourceName() {
      return 'linkedin-post-templates';
    }

    protected get resourceOwner(): string {
      if (!customTemplateStore.selectedCustomTemplate) {
        throw new Error('Trying to perform ad related actions without a selected template');
      }
      return `customTemplates/${customTemplateStore.selectedCustomTemplate.id}`;
    }

    protected extract(model: LinkedinPostAd): LinkedinPostAdRequestJson {
      return {
          media: {
              id: model.media.id,
              type: model.media.type,
          },
          message: model.message,
          headline: model.headline,
          link_description: model.linkDescription,
          call_to_action: model.callToAction,
      };
    }

    protected hydrate(json: LinkedinPostAdResponseJson): LinkedinPostAd {
      return {
          id: String(json.id),
          status: 'active',
          media: hydrateAdMedia(json.media),
          headline: json.headline,
          linkDescription: json.link_description,
          message: json.message,
          callToAction: json.call_to_action,
      };
    }
  }

export interface LinkedinPostAdResponseJson {
  id: string;
  media: AdMediaJson;
  message: string;
  headline: string;
  link_description: string;
  call_to_action: string;
}

export interface LinkedinPostAdRequestJson {
  media: {
      id: string;
      type: MediaType;
  };
  message: string;
  headline: string;
  link_description: string;
  call_to_action: string;
}
