import httpService from '@/core/plugins/httpService';
import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import {
  AccountSettings,
  SocialPageConnectionRequest,
  ConnectedSocialPage,
  UtmChannels,
  ConversionId,
  ChannelAccounts,
  EnabledFeatures,
  RequestStatus,
  PixelId,
} from '@/modules/settings/models/accountSettings.model';
import toastNotificationStore from '@/core/store/toastNotification.store';
import { showNotification } from '@/core/assets/global-utils';

export default class FinanceApi {
  public getAccountSettings(): Promise<AccountSettings> {
    return httpService.get(this.baseUrl!).then((response) => {
      const data = response.data as AccountSettingsJson;
      return {
        name: data.name,
        alias: data.alias,
        address: data.address.street,
        postalCode: data.address.postal_code,
        city: data.address.city,
        country: data.address.country,
        currency: data.currency,
        channelAccounts: {
          facebook: data.channel_accounts.facebook ? {accountId: data.channel_accounts.facebook.account_id} : null,
          linkedin: data.channel_accounts.linkedin ? {accountId: data.channel_accounts.linkedin.account_id} : null,
          googleAds: data.channel_accounts.google_ads ? {accountId: data.channel_accounts.google_ads.account_id} : null,
        } as ChannelAccounts,
        defaultLandingPageUrl: data.default_landing_page_url,
        connectedSocialPages: data.social_pages.map((page) => {
          return {
            iconUrl: page.icon,
            name: page.name,
            channel: page.channel,
            id: page.id,
            type: 'connected',
            leadformRequestStatus: page.leadform_tos,
          };
        }),
        defaultUtm: data.default_utm,
        utmChannels: data.utm_channels.filter((channelName: string) => {
          return !!data.default_utm[channelName];
        }),
        conversionId: data.conversion_id,
        targetLanguage: data.primary_language,
      };
    });
  }

  public getSocialPageConnectionRequests(): Promise<SocialPageConnectionRequest[]> {
    return httpService.get(this.baseUrl + '/social-account-connection-requests').then((response) => {
      return response.data.map((request: SocialAccountConnectionRequestJson) => {
        return {
          ...request,
          type: 'request',
        };
      }) as SocialAccountConnectionRequestJson[];
    });
  }

  public fetchAllEnabledFeatures(): Promise<EnabledFeatures> {
    return httpService.get(`${this.baseUrl}/enabled-features`)
      .then((response) => response.data)
      .catch((error) => {
          showNotification({
             message: error.response.data.message,
             isError: true,
         });
       });
  }

  public async fetchConversionId(): Promise<ConversionId> {
    return httpService.get(`${this.baseUrl}/conversion-id`).then((response) => {
      return { conversionId: response.data };
    });
  }

  public async fetchDynamicAdsPixelId(): Promise<PixelId> {
    return httpService.get(`${this.baseUrl}/pixel-id`).then((response) => {
      return { pixelId: response.data }
    });
  }

  public updateDefaultValues(url: string, defaultUtms: object, language: string): Promise<void> {
    return httpService.put(this.baseUrl!, {
      default_landing_page_url: url,
      utm: defaultUtms,
      primary_language: language,
    }).then(() => {return; }).catch((error) => {
      const errored = error.response.data;
      if ( errored.default_landing_page_url) {
        toastNotificationStore.showToastNotification({
          message: `Please paste full URL including https://`,
          isError: true,
          timeout: 10000,
          isSuccess: false,
          isWarning: false,
        });
      } else {
        toastNotificationStore.showToastNotification({
          message: `UTM input fields can't be empty`,
          isError: true,
          timeout: 10000,
          isSuccess: false,
          isWarning: false,
        });
      }
    });
  }

  public updateConversionId(conversionId: string): Promise<void> {
    return httpService.put(`${this.baseUrl}/conversion-id`, {conversion_id: conversionId}).then(() => { return; });
  }

  public updateDynamicAdsPixelId(pixelId: string): Promise<void> {
    return httpService.put(`${this.baseUrl}/pixel-id`, { pixelId })
      .then(() => { return; });
  }

  public createSocialAccountConnectionRequest(request: {channel: string, url: string, page_id: string}): Promise<SocialPageConnectionRequest | void> {
    return httpService.post(this.baseUrl + '/social-account-connection-requests', request).then((response) => {
      return response.data.socialAccountConnectionRequest as SocialAccountConnectionRequestJson;
    }).catch((error) => {
      toastNotificationStore.showToastNotification({
        message: error.response.data.message,
        isError: true,
        timeout: 10000,
      });
    });
  }

  public completeSocialAccountConnectionRequest(request: SocialPageConnectionRequest): Promise<void> {
    return httpService.put('/social-account-connection-requests/' + request.id, {
      status: 'completed',
    }).then(() => {
      return;
    });
  }

  public deleteSocialChannelPage(page: ConnectedSocialPage): Promise<void> {
    return httpService.delete(this.baseUrl + '/social-pages/' + page.id).then(() => {
      return;
    });
  }

  public requestLeadformsCreation(pageId: string): Promise<RequestStatus> {
    return httpService.put(`${this.baseUrl}/social-pages/${pageId}/update`).then((response) => { 
      return response.data.leadform_tos;
    });
  }

  protected get baseUrl(): string | undefined {
    const accountsStore = useAccountsStore();
    return process.env.VUE_APP_WONDERKIND_API_BASE_URL + `/accounts/${accountsStore.selectedAccount!.id}`;
  }
}

interface AccountSettingsJson {
  name: string;
  alias: string;
  address: {
    street: string;
    postal_code: string;
    city: string;
    country: string;
  };
  currency: {
    code: string;
    symbol: string;
  };
  default_landing_page_url: string;
  social_pages: Array<{name: string, channel: string, icon: string, id: string, type: string, leadform_tos: RequestStatus}>;
  default_utm: {[key: string]: object};
  utm_channels: UtmChannels[];
  conversion_id: string;
  primary_language: string;
  channel_accounts: {
    facebook: {account_id: string},
    linkedin: {account_id: string},
    google_ads: {account_id: string},
  };
}

interface SocialAccountConnectionRequestJson {
  id: string;
  channel: string;
  url: string;
  status: 'pending' | 'completed';
  type: 'request';
}
