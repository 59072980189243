import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

type TDate = string | Date;

type unitOfTime = (
  'year' | 'years' | 'y' |
  'month' | 'months' | 'M' |
  'week' | 'weeks' | 'w' |
  'day' | 'days' | 'd' |
  'hour' | 'hours' | 'h' |
  'minute' | 'minutes' | 'm' 
);

interface IDateRange {
  startDate: TDate;
  endDate: TDate;
}

/**
 * @returns 2022-02-14T21:00:00Z
 */
const getDateWithTimezone = (date?: TDate, format?: string): string => {
    return dayjs(date, format).utc().utcOffset(0, false).format();
};

/**
 * @returns 2022-02-14 'YYYY-MM-DD'
 */
const formatDate = (date: TDate = new Date(), format: string = 'YYYY-MM-DD' ): string => {
    return dayjs(date).format(format);
};

const subtractDay = (date: TDate, amount: number, type: unitOfTime = 'days') =>  {
    return dayjs(date).subtract(amount, type).toDate();
};

const unixDate = (date: TDate = new Date()): number =>  {
    return dayjs(date).unix();
};

const addDay = (date: TDate = new Date(), amount: number): Date =>  {
    return dayjs(date).add(amount, 'days').toDate();
};

const dateDiff = ( endDate: TDate, startDate: TDate, type: unitOfTime = 'days'): number => {
  const mStartDate = dayjs(new Date(startDate).setHours(0, 0, 0, 0));
  const mEndDate = dayjs(new Date(endDate).setHours(0, 0, 0, 0));
  return mEndDate.diff(mStartDate, type);
};

const startOfDay = (date: TDate = new Date(), type: unitOfTime = 'day'): Date =>  {
    return dayjs(date).startOf(type).toDate();
};

const isBeforeDate = (startDate: TDate, endDate: TDate): boolean =>  {
    return dayjs(startDate).isBefore(endDate);
};

const isValidDate = (date: TDate): boolean =>  {
    return dayjs(date).isValid();
};

const isSameDate = (date: TDate, secondDate: TDate, type: unitOfTime = 'day' ): boolean =>  {
    return dayjs(date).isSame(secondDate, type);
};

const isSameOrAfterDate = (date: TDate, secondDate: TDate, type?: unitOfTime ): boolean =>  {
    return dayjs(date).isSameOrAfter(secondDate, type);
};

export {
    getDateWithTimezone,
    subtractDay,
    formatDate,
    dateDiff,
    startOfDay,
    isBeforeDate,
    isValidDate,
    unixDate,
    addDay,
    isSameDate,
    isSameOrAfterDate,
    IDateRange,
    TDate,
};

