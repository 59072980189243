import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

const integrations = [new Integrations.Vue({ Vue, attachProps: true })];
const isTestEnv = location.hostname.startsWith('qa') || location.hostname.startsWith('eb');

if (process.env.NODE_ENV === 'production' && !isTestEnv) {
  Sentry.init({ dsn: process.env.VUE_APP_SENTRY_DSN, integrations, environment: 'production' });
}
