import httpService from '@/core/plugins/httpService';
import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import {AbstractResource} from '@/core/api/abstract.api';
import {Notification, Date} from '../models/notification.model';

export default class NotificationResource extends AbstractResource<Notification, NotificationResponseJson, NotificationRequestJson> {
  public async deleteNotification(notification: Notification) {
    return await httpService.delete(this.collectionEndpoint + '/' + this.identifier(notification));
  }

  public async pinNotification(notification: Notification): Promise<void> {
    return await httpService.put(this.collectionEndpoint + '/' + notification.id + '/pin');
  }

  public async unpinNotification(notification: Notification): Promise<void> {
    return await httpService.put(this.collectionEndpoint + '/' + notification.id + '/unpin');
  }

  public async markRead(notification: Notification): Promise<void> {
    return await httpService.put(this.collectionEndpoint + '/' + notification.id + '/read');
  }

  public async markUndread(notification: Notification): Promise<void> {
    return await httpService.put(this.collectionEndpoint + '/' + notification.id + '/unread');
  }

  public async markAllRead() {
    return await httpService.put(this.collectionEndpoint + '/read-all');
  }

  protected get resourceName(): string {
    return 'notifications';
  }

  protected get resourceOwner(): string {
    const accountsStore = useAccountsStore();
    return 'accounts/' + accountsStore.selectedAccount!.id;
  }

  protected extract(): NotificationRequestJson {
    return {
      id: '',
    };
  }

  protected hydrate(json: NotificationResponseJson): Notification {
    const notification: Notification = {
      id: json.id,
      campaignId:  json.campaign_id,
      campaignName: json.campaignName,
      body: json.message,
      read: json.read,
      pinned: json.pinned,
      date: formatDate(json.created_at),
      time: formatTime(json.created_at),
    };
    return notification;
  }
}

export interface NotificationResponseJson {
  id: string;
  campaign_id: string; 
  campaignName: string;
  message: string;
  read: boolean; 
  pinned: boolean;
  created_at: string;
}

// tslint:disable-next-line
export interface NotificationRequestJson {
  id: string;
}

// Date Formating logic 
function formatDate(info: string): Date {
  const year = info.slice(0 , 4);
  const month = info.slice(5, 7);
  const day = info.slice(8, 10);
  return {
    fullDate: day + '/' + month + '/' + year,
    day,
    month: convertMonth(month),
    year,
  };
}

function convertMonth(month: string) {
  const converted = {
    number: month,
    name: getMonthName(month)!,
  };
  return converted;
}

function getMonthName(date: string) {
  switch (date) {
    case '01': return 'January';
    case '02': return 'February';
    case '03': return 'March';
    case '04': return 'April';
    case '05': return 'May';
    case '06': return 'June';
    case '07': return 'July';
    case '08': return 'August';
    case '09': return 'September';
    case '10': return 'October';
    case '11': return 'November';
    case '12': return 'December';
  }
}

// Time Formatting Logic
function formatTime(info: string) {
  const time = info.slice(11, 13) + info.slice(14, 16) + info.slice(17, 19);
  return parseInt(time, 10);
}
