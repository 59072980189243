import {AbstractResource} from '@/core/api/abstract.api';
import httpService from '@/core/plugins/httpService';

import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import { useWorkspaceStore } from '../store/workspaces.store';

import { WorkspaceMember } from '@/modules/accounts/models/workspaceMember.model';

export default class WorkspaceMemberResource extends AbstractResource<WorkspaceMember, WorkspaceMemberJson, WorkspaceMemberJson> {
    public addUser(userId: string): Promise<void> {
        return httpService.post(this.collectionEndpoint, {user_id: userId});
    }
    
    get workspaceStore() {
      return useWorkspaceStore();
    }

    protected identifier(workspaceMember: WorkspaceMember): string | undefined {
        return workspaceMember.id;
    }

    protected get resourceName() {
        return 'members';
    }

    protected get resourceOwner(): string {
        const accountsStore = useAccountsStore();
        return `accounts/${accountsStore.selectedAccount!.id}/workspaces/${this.workspaceStore.selectedWorkspace!.slug}`;
    }

    protected extract(model: WorkspaceMember): WorkspaceMemberJson {
        return {
            user_id: model.id!,
            first_name: model.first_name,
            last_name: model.last_name,
            name: model.name,
            email: model.email,
            member_since: model.member_since,
        };
    }

    protected hydrate(json: WorkspaceMemberJson): WorkspaceMember {
        return {
            id: json.user_id,
            first_name: json.first_name,
            last_name: json.last_name,
            name: json.name,
            email: json.email,
            member_since: json.member_since,
        };
    }

}

interface WorkspaceMemberJson {
    user_id: string;
    first_name: string;
    last_name: string;
    name: string;
    email: string;
    member_since: string;
}
