import { Module, VuexModule, getModule, Action, Mutation, config } from 'vuex-module-decorators';
import store from '@/core/store/index.store';
import localAuthApi from '../api/localAuth.api';
import localUserStore from '../store/localUser.store';
import toastNotificationStore from '@/core/store/toastNotification.store';

config.rawError = true;

@Module({
    name: 'authenticate',
    namespaced: true,
    dynamic: true,
    store,
})
class LocalAuthStore extends VuexModule {
    public isAuthenticated: boolean = false;

    @Mutation
    public setIsAuthenticated(isAuthenticated: boolean) {
        this.isAuthenticated = isAuthenticated;
    }

    @Action({ commit: 'setIsAuthenticated'})
    public authenticate(): Promise<boolean> {
        return localAuthApi.isAuthenticated();
    }

    @Action({ commit: 'setIsAuthenticated' })
    public async logout(): Promise<void> {
        await localAuthApi.logout();
        this.context.commit('setIsAuthenticated', false);
        localUserStore.empty();
    }

    @Action
    public async login(email: string): Promise<void> {
        try {
            await localAuthApi.login(email);
            this.context.commit('setIsAuthenticated', true);
        } catch (error) {
            toastNotificationStore.showToastNotification({
                message: 'Was unable to sign in, please try again',
                isError: false,
                isSuccess: false,
                isWarning: false,
            });
            throw error;
        }
    }
}

export default getModule(LocalAuthStore, store);
