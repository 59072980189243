import { render, staticRenderFns } from "./MediaInputItem.vue?vue&type=template&id=4ef2d280&scoped=true&"
import script from "./MediaInputItem.vue?vue&type=script&setup=true&lang=ts&"
export * from "./MediaInputItem.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./MediaInputItem.vue?vue&type=style&index=0&id=4ef2d280&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ef2d280",
  null
  
)

export default component.exports