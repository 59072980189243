import { FacebookCarouselAd, FacebookPostAd, FacebookStoryAd } from './facebookAds.model';
import { InstagramPostAd, InstagramCarouselAd, InstagramStoryAd } from './instagramAds.model';
import { GoogleDisplayCustomAd } from './googleAds.model';
import { AspectRatio } from '../helpers';
import { LinkedinPostAd } from './linkedinAds.model';

export type Ad = FacebookPostAd | FacebookCarouselAd | FacebookStoryAd | InstagramPostAd | InstagramCarouselAd | InstagramStoryAd | GoogleDisplayCustomAd | LinkedinPostAd;

export type Channel = 'facebook' | 'instagram' | 'linkedin' | 'google' | 'google-search';
export type MissingChannelName = 'facebook_feed' | 'instagram_feed' | 'instagram_story' | 'google_display' | 'google_search' | 'linked_in';
export type ChannelId = 'facebookPageId' | 'instagramPageId' | 'linkedinPageId';
export type ChannelBudgetKey = 'facebookInstagram' | 'googleDisplay' | 'googleSearch' | 'linkedin';

export type AdType =
'FacebookPostAd' | 'FacebookCarouselAd' | 'FacebookStoryAd' |
'InstagramPostAd' | 'InstagramCarouselAd' | 'InstagramStoryAd' |
'GoogleDisplayCustomAd' | 'GoogleDisplayGeneratedAd' | 'LinkedinPostAd' | 'GoogleSearchAd';

export type ChannelWithSocialPage = Exclude<Channel, 'google' | 'google-search'>;

export type AdStatus = 'active' | 'paused';
export type AdStatusAction = 'delete' | 'pause' | 'resume' | 'deleteLastAd';

export interface AdStatusActions {
  active: Array<Extract<AdStatusAction, 'delete' | 'pause'>>;
  pause: Array<Extract<AdStatusAction, 'delete' | 'resume'>>;
}

export interface AdValidation {
  message?: WarningAndErrorOption;
  headline?: WarningAndErrorOption;
  linkDescription?: WarningAndErrorOption;
  imageMinWidth?: number;
  videoMaxDuration?: number;
  videoMaxSize?: number;
  videoMinWidth?: number;
  videoRatio?: AspectRatio[];
}

interface WarningAndErrorOption {
  warning: number;
  error: number;
  lineBreaks?: number;
}

export interface SegmentControlOption {
  label: string;
  imgUrl: string;
  value: AdType;
}

export interface ErrorItemJson {
  code: number;
  created_at: string;
  request_id: string;
  error_message: string;
  user_message: string;
}

export enum AdTypeList {
  FacebookCarouselAd = 'FacebookCarouselAd',
  FacebookPostAd = 'FacebookPostAd',
  FacebookStoryAd = 'FacebookStoryAd',
  InstagramCarouselAd = 'InstagramCarouselAd',
  InstagramPostAd = 'InstagramPostAd',
  InstagramStoryAd = 'InstagramStoryAd',
  GoogleDisplayCustomAd = 'GoogleDisplayCustomAd',
  GoogleDisplayGeneratedAd = 'GoogleDisplayGeneratedAd',
  LinkedInPostAd = 'LinkedinPostAd',
}

export enum CallActionTypes {
  InstagramCarouselAd = 'instagramCarousel',
  InstagramPostAd = 'instagramPost',
  InstagramStoryAd = 'instagramStory',
}

export const adInputPlaceholders = {
  callToAction: 'Select a call to action for your ad.',
  headline: 'What\'s your ad headline?',
  linkDescription: 'Write down your link description.',
  message: 'Type your message. You can start by telling about the advantages of working for your company.',
};

export const adViewPlaceholders = {
  callToAction: 'CTA',
  headline: 'Your headline',
  linkDescription: 'Your link description',
  targetUrl: 'Your target url',
  message: 'Your message will be shown here. Please start typing in the panel on your left.',
};
