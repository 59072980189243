export function lineBreakCount(text: string): number {
    return text.split('\n').length;
}

export function truncateByLength(text: string, length: number): string {
    return `${text.substring(0, text.substring(0, length).lastIndexOf(' '))} …`;
}

export function truncateByLineBreak(text: string, breaks: number): string {
    return `${text.split('\n').slice(0, breaks).join('\n')} …`;
}

export function isTruncated(text: string, maxLength?: number, maxLineBreaks?: number): boolean {
    const tooLong = maxLength && text.length > maxLength;
    let tooManyLineBreaks = false;

    if (maxLineBreaks) {
        tooManyLineBreaks = lineBreakCount(text) > maxLineBreaks;
    }

    return tooLong || tooManyLineBreaks;
}

export function truncateText(text: string, maxLength?: number, maxLineBreaks?: number): string {
    const lineBreakCountItem = lineBreakCount(text);

    if (maxLineBreaks && lineBreakCountItem > maxLineBreaks) {
        return truncateByLineBreak(text, maxLineBreaks);
    }
    if (maxLength && text.length > maxLength) {
        return truncateByLength(text, maxLength);
    }

    return text;
}

export function lastLineBreakCharIndex(text: string, maxLineBreaks: number): number {
    return text.split('\n')
    .filter((_, index) => index < maxLineBreaks)
    .join('\n')
    .length - 1;
}