import {AbstractResource} from '@/core/api/abstract.api';
import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import {AccountMember} from '@/modules/accounts/models/accountMember.model';
import httpService from '@/core/plugins/httpService';

export default class AccountMemberResource extends AbstractResource<AccountMember, AccountMemberJson, AccountMemberJson> {
    public demoteAdminToEditor(admin: AccountMember): Promise<void> {
        return httpService.delete('/' +  this.resourceOwner + '/admins/' + admin.id);
    }

    public async promoteEditorToAdmin(editor: AccountMember) {
        return httpService.post('/' +  this.resourceOwner + '/admins/', {user_id: editor.id});
    }

    protected identifier(accountMember: AccountMember): string | undefined {
        return accountMember.id;
    }

    protected get resourceName() {
        return 'members';
    }

    protected get resourceOwner(): string {
        const accountsStore = useAccountsStore();
        return `accounts/${accountsStore.selectedAccount!.id}`;
    }

    protected extract(model: AccountMember): AccountMemberJson {
        return {
            id: model.id,
            name: model.name,
            email: model.email,
            role: model.role,
            workspaces: model.workspaces ? model.workspaces : [],
        };
    }

    protected hydrate(json: AccountMemberJson): AccountMember {
        return {
            id: json.id,
            name: json.name,
            email: json.email,
            role: json.role,
            workspaces: json.workspaces ? json.workspaces : [],
        };
    }
}

interface AccountMemberJson {
    id: string;
    name: string;
    email: string;
    role: string;
    workspaces: Array<{ name: string, slug: string }>;
}
