import {Module, getModule, Action, config} from 'vuex-module-decorators';
import {AbstractAds} from '../abstractTemplateCustom.store';
import {AbstractResource} from '@/core/api/abstract.api';
import store from '@/core/store/index.store';
import adsEditorStore from '../templateAdsEditor.store';
import {AdMedia} from '@/modules/medialibrary/models/video.model';
import {InstagramCarouselAd} from '../../models/instagramAds.model';
import InstagramCarouselAdResource, {InstagramCarouselAdResponseJson, InstagramCarouselAdRequestJson} from '../../api/instagramCarouselCustom.api';

config.rawError = true;

@Module({
  name: 'instagramCarouselCustomTemplates',
  namespaced: true,
  dynamic: true,
  store,
})
class InstagramCarouselAds extends AbstractAds<
  InstagramCarouselAd,
  InstagramCarouselAdResponseJson,
  InstagramCarouselAdRequestJson
  > {
    protected get resource(): AbstractResource<
      InstagramCarouselAd,
      InstagramCarouselAdResponseJson,
      InstagramCarouselAdRequestJson> {
        return InstagramCarouselAdResource.prototype;
      } 
    
    get media(): AdMedia[] {
      return adsEditorStore.media.length > 0 ?
      adsEditorStore.media :
      [
        { id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'},
        { id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'},
      ];
    }
  
  @Action
  public async createAds() {
    const media = this.media;
    const instagramCarouselAd = {
      media,
      ...adsEditorStore.copy,
    };
    await this.createAd(instagramCarouselAd);
  }
}

export default getModule(InstagramCarouselAds, store);
