import {AbstractResource} from '@/core/api/abstract.api';
import {Account} from '../models/account.model';

export default class AccountResource extends AbstractResource<Account, AccountJson, AccountJson> {
    protected get resourceName() {
        return 'me/memberships';
    }

    protected get resourceOwner() {
        return '';
    }

    protected extract(model: Account): AccountJson {
        return {
            account_id: model.id!,
            name: model.name,
        };
    }

    protected hydrate(json: AccountJson): Account {
        const model: Account = {
            name: json.name,
        };

        if (json.account_id) {
            model.id = json.account_id;
        }

        return model;
    }
}

interface AccountJson {
    account_id: string;
    name: string;
}
