export type TemplateModel = 'default' | 'custom';
export type TemplateGoal = '';
export type Page = 'template-details/ads' | 'template-details';

export enum ETemplateTypes {
    DEFAULT = 'default-templates',
    CUSTOM = 'custom-templates',
}

export const tabItems: Array<Record<string, string>> = [
    { id: ETemplateTypes.DEFAULT, title: 'Default templates', templateModel: 'default' },
    { id: ETemplateTypes.CUSTOM, title: 'Custom templates', templateModel: 'custom' },
];
