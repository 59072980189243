import { defineStore, Store, StoreDefinition } from 'pinia'
import { PiniaStore } from '@/core/store/piniaTypes';
import { state, actions, getters, BaseState, BaseGetters, BaseActions } from '@/core/store/common/ads.store'
import { InstagramPostAd, InstagramCarouselAd, InstagramStoryAd } from '@/modules/ads/models/instagramAds.model';
import { SocialPage } from '@/modules/accounts/models/socialPage.model';
import { AdMedia } from '@/modules/medialibrary/models/video.model';
import adsEditorStore from '@/modules/ads/store/adsEditor.store';
import InstagramPostAdResource, { InstagramPostAdResponseJson, InstagramPostAdRequestJson } from '@/modules/ads/api/instagramPostAd.api';
import InstagramCarouselAdResource, { InstagramCarouselAdResponseJson, InstagramCarouselAdRequestJson } from '@/modules/ads/api/instagramCarouselAd.api';
import InstagramStoryAdResource, { InstagramStoryAdRequestJson, InstagramStoryAdResponseJson } from '@/modules/ads/api/instagramStoryAd.api';
import { useSocialPagesStore } from '@/modules/accounts/store/socialPages.store';
import { AbstractResource } from '@/core/api/abstract.api';
import { Ad as AbstractAd } from '@/modules/ads/models/ads.model';         

interface InstagramPostState extends BaseState<AbstractAd> {
  resource: AbstractResource<InstagramPostAd, InstagramPostAdResponseJson, InstagramPostAdRequestJson>;
}

interface InstagramCarouselState extends BaseState<AbstractAd> {
  resource: AbstractResource<InstagramCarouselAd, InstagramCarouselAdResponseJson, InstagramCarouselAdRequestJson>;
  instagramPage: SocialPage | undefined | object;
}

interface InstagramStoryState extends BaseState<AbstractAd> {
  resource: AbstractResource<InstagramStoryAd, InstagramStoryAdResponseJson, InstagramStoryAdRequestJson>;
}

interface InstagramPostActions extends BaseActions<InstagramPostAd> {
  createAds(ad: InstagramPostAd): Promise<void>;
}

interface InstagramCarouselActions extends BaseActions<InstagramCarouselAd> {
  createAds(ad: InstagramCarouselAd): Promise<void>;
  updateInstagramPage(id: string | null | undefined): SocialPage | undefined;
}

interface InstagramStoryActions extends BaseActions<InstagramStoryAd> {
  createAds(ad: InstagramStoryAd): Promise<void>;
}

type InstagramPostStore = Store<'instagramPostAdStore', InstagramPostState, BaseGetters<AdMedia>, InstagramPostActions>;
type InstagramCarouselStore = Store<'instagramCarouselStore', InstagramCarouselState, BaseGetters<AdMedia>, InstagramCarouselActions>;
type InstagramStoryStore = Store<'instagramStoryStore', InstagramStoryState, BaseGetters<AdMedia>, InstagramStoryActions>;

type InstagramPostStoreDefinition = StoreDefinition<'instagramPostAdStore', InstagramPostState, BaseGetters<AdMedia>, InstagramPostActions>;
type InstagramCarouselDefinition = StoreDefinition<'instagramCarouselStore', InstagramCarouselState, BaseGetters<AdMedia>, InstagramCarouselActions>;
type InstagramStoryDefinition = StoreDefinition<'instagramStoryStore', InstagramStoryState, BaseGetters<AdMedia>, InstagramStoryActions>;

const extendedPostStore: PiniaStore<InstagramPostStore> = {
  state: () => {
    return {
      ...state(),
      resource: InstagramPostAdResource.prototype
    }
  },
  getters: {
    ...getters,
  },
  actions: {
    ...actions,
    async createAds(): Promise<void> {
      for (const media of this.media) {
        const instagramPostAd: InstagramPostAd = {
          media,
          ...adsEditorStore.copy,
          status: 'active',
        };
        await this.createAd(instagramPostAd);
      }
    },
    updateInstagramPage(id: string | null | undefined) {
      const socialPagesStore = useSocialPagesStore();
      const page = socialPagesStore.returnSocialPage({id, channel: 'instagram'});
      this.instagramPage = page;
      return page;
    }
  },
};
const extendedCarouselStore: PiniaStore<InstagramCarouselStore> = {
  state: () => {
    return {
      ...state(),
      resource: InstagramCarouselAdResource.prototype,
      instagramPage: {}
    }
  },
  getters: {
    ...getters,
    media(): AdMedia[] {
      return adsEditorStore.media.length > 0 ?
      adsEditorStore.media :
      [
        { id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'},
        { id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'},
      ];
    }
  },
  actions: {
    ...actions,
    async createAds(ads: InstagramCarouselAd): Promise<void> {
      await this.createAd(ads);
    },
    updateInstagramPage(id: string | null | undefined) {
      const socialPagesStore = useSocialPagesStore();
      const page = socialPagesStore.returnSocialPage({id, channel: 'instagram'});
      this.instagramPage = page;
      return page;
    }
  },
};
const extendedStoryStore: PiniaStore<InstagramStoryStore> = {
  state: () => {
    return {
      ...state(),
      resource: InstagramStoryAdResource.prototype,
      instagramPage: {}
    }
  },
  getters: {
    ...getters,
  },
  actions: {
    ...actions,
    async createAds(): Promise<void> {
      for (const media of this.media) {
        const instagramPostAd: InstagramPostAd = {
          media,
          ...adsEditorStore.copy,
          status: 'active',
        };
        await this.createAd(instagramPostAd);
      }
    },
  },
};

export const useInstagramPostAd: InstagramPostStoreDefinition = defineStore('instagramPostAdStore', extendedPostStore);
export const useInstagramCarouselAd: InstagramCarouselDefinition = defineStore('instagramCarouselStore', extendedCarouselStore);
export const useInstagramStoryAd: InstagramStoryDefinition = defineStore('instagramStoryStore', extendedStoryStore);
