import JobLibrary from './pages/JobLibrary.vue';
import CreateJobList from './pages/CreateJobList.vue';
import CreateDynamicCampaign from './pages/CreateDynamicCampaign.vue';
import DynamicCampaigns from './pages/DynamicCampaigns.vue';
import DynamicCampaignPreview from './pages/DynamicCampaignPreview.vue';
import JobLibraryList from './pages/JobLibraryList.vue';

export default [
  {
    path: '/job-library',
    name: 'job-library',
    component: JobLibrary,
    redirect: '/job-library/job-list',
    children: [
      {
        path: 'job-list',
        name: 'job-list',
        component: JobLibraryList,
      },
      {
        path: 'update-job-list/:jobListId',
        name: 'update-job-list',
        props: true,
        component: CreateJobList,
      },
      {
        path: 'dynamic-campaigns',
        name: 'dynamic-campaigns',
        component: DynamicCampaigns,
      },
      {
        path: 'create-job-list',
        name: 'create-job-list',
        component: CreateJobList,
      },
      {
        path: 'dynamic-campaigns/create-dynamic-campaign',
        name: 'create-dynamic-campaign',
        component: CreateDynamicCampaign,
      },
      {
        path: 'dynamic-campaigns/edit-dynamic-campaign/:campaignId',
        name: 'edit-dynamic-campaign',
        component: CreateDynamicCampaign,
      },
      {
        path: 'dynamic-campaigns/campaign-preview/:campaignId',
        name: 'campaign-preview',
        component: DynamicCampaignPreview,
      },
    ],
  },
];
