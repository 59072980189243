import { reactive,  computed } from 'vue';
import createAuth0Client, {
    Auth0Client,
    GetTokenSilentlyOptions,
    GetTokenWithPopupOptions,
    LogoutOptions,
    RedirectLoginOptions,
} from '@auth0/auth0-spa-js';
import {User} from '@/modules/auth/models/user.model';
import httpService from '@/core/plugins/httpService';

export interface Auth0Options {
    domain: string;
    clientId: string;
    audience?: string;
    [key: string]: string | undefined;
}
interface Auth0PluginState {
    loading: boolean,
    isAuthenticated: boolean;
    user: User | undefined;
    popupOpen: boolean;
    error: Error | null;
    accessToken: string;

}

export type RedirectCallback = (appState: any) => void;

let auth0Client = {} as Auth0Client;

const authPlugin = {
    isAuthenticated: computed(() => state.isAuthenticated),
    loading: computed(() => state.loading),
    user: computed(() => state.user)
}

const state = reactive<Auth0PluginState>({
    loading: true,
    isAuthenticated: false,
    user: undefined,
    popupOpen: false,
    error: null,
    accessToken: ''
});

async function getUser(): Promise<User | undefined> {
    const userData = await auth0Client?.getUser();
    if (userData) {
        return User.fromAuth0User(userData);
    }
}

/** Authenticates the user using the redirect method */
function loginWithRedirect(o: RedirectLoginOptions) {
    return auth0Client?.loginWithRedirect(o);
}

/** Returns the access token. If the token is invalid or missing, a new one is retrieved */
function getTokenSilently(o: GetTokenSilentlyOptions) {
    return auth0Client?.getTokenSilently(o);
}

/** Gets the access token using a popup window */
function getTokenWithPopup(o: GetTokenWithPopupOptions) {
    return auth0Client?.getTokenWithPopup(o);
}

/** Logs the user out and removes their session on the authorization server */
function logout(o: LogoutOptions) {
    return auth0Client?.logout(o);
}

async function fetchToken(): Promise<string | undefined> {

    state.accessToken = await auth0Client.getTokenSilently({});
    if (state.accessToken) {
        httpService.defaults.headers.common.Authorization = 'Bearer ' + state.accessToken;
        localStorage.setItem('token', state.accessToken);
    }
    return state.accessToken;
}

/** Use this lifecycle method to instantiate the SDK client */
async function init(onRedirectCallback: RedirectCallback, redirectUri: string, auth0Options: Auth0Options) {
    // Create a new instance of the SDK client using members of the given options object
    auth0Client = await createAuth0Client({
        domain: auth0Options.domain,
        client_id: auth0Options.clientId, // eslint-disable-line @typescript-eslint/camelcase
        audience: auth0Options.audience,
        redirect_uri: redirectUri, // eslint-disable-line @typescript-eslint/camelcase
    });

    try {
        // If the user is returning to the app after authentication..
        if (
            window.location.search.includes('error=') ||
            (window.location.search.includes('code=') && window.location.search.includes('state='))
        ) {
            // handle the redirect and retrieve tokens
            const { appState } = await auth0Client?.handleRedirectCallback() ?? { appState: undefined };

            // Notify subscribers that the redirect callback has happened, passing the appState
            // (useful for retrieving any pre-authentication state)
            onRedirectCallback(appState);
        }
    } catch (e: any) {
        state.error = e;
    } finally {
        // Initialize our internal authentication state when the page is reloaded
        state.isAuthenticated = await auth0Client?.isAuthenticated();
        state.user = await getUser();
        if (state.isAuthenticated) {
            await fetchToken();
            const result = await httpService.get(
                '/me',
                { headers: { Authorization: `Bearer ${state.accessToken}` }},
            ).catch(() => {});
        }
        state.loading = false;
    }
}

export const VueAuth = {
    init,
    auth0Client,
    authPlugin,
    loginWithRedirect,
    getTokenSilently,
    fetchToken,
    logout,
    getTokenWithPopup
}
