import { render, staticRenderFns } from "./MultiselectDropdown.vue?vue&type=template&id=0ff84b8c&scoped=true&"
import script from "./MultiselectDropdown.vue?vue&type=script&setup=true&lang=ts&"
export * from "./MultiselectDropdown.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./MultiselectDropdown.vue?vue&type=style&index=0&id=0ff84b8c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ff84b8c",
  null
  
)

export default component.exports