import {AbstractResource} from '@/core/api/abstract.api';
import defaultTemplateStore from '../../templates/default/store/defaultTemplates.store';
import {FacebookPostAd} from '../models/facebookAds.model';
import {MediaType} from '@/modules/medialibrary/models/masterImage.model';
import { hydrateAdMedia, AdMediaJson } from '@/modules/ads/api/apiHelpers';

export default class FacebookPostAdResource extends AbstractResource<
  FacebookPostAd,
  FacebookPostAdResponseJson,
  FacebookPostAdRequestJson
  > {

    protected get resourceName() {
      return 'facebook-post-templates';
    }

    protected get resourceOwner(): string {
      if (!defaultTemplateStore.selectedDefaultTemplate) {
        throw new Error('Trying to perform ad related actions without a selected template');
      }
      return `templates/${defaultTemplateStore.selectedDefaultTemplate.id}`;
    }

    protected extract(model: FacebookPostAd): FacebookPostAdRequestJson {
      return {
        media: {
          id: model.media.id,
          type: model.media.type,
          masterUrl: model.media.masterUrl,
          previewUrl: model.media.previewUrl,
          url: model.media.url,
        },
        message: model.message,
        headline: model.headline,
        linkDescription: model.linkDescription,
        callToAction: model.callToAction,
      };
    }

    protected hydrate(json: FacebookPostAdResponseJson): FacebookPostAd {
      return {
        id: String(json.id),
        media: hydrateAdMedia(json.media),
        message: json.message,
        headline: json.headline,
        linkDescription: json.linkDescription,
        callToAction: json.callToAction,
      };
    }
  }

export interface FacebookPostAdResponseJson {
  id: string;
  media: AdMediaJson;
  message: string;
  headline: string;
  linkDescription: string;
  callToAction: string;
}

export interface FacebookPostAdRequestJson {
  media: {
    id: string;
    type: MediaType;
    masterUrl: string;
    previewUrl: string;
    url: string;
  };
  message: string;
  headline: string;
  linkDescription: string;
  callToAction: string;
}
