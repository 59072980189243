import { render, staticRenderFns } from "./AdsRow.vue?vue&type=template&id=5b9551b0&scoped=true&"
import script from "./AdsRow.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AdsRow.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./AdsRow.vue?vue&type=style&index=0&id=5b9551b0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b9551b0",
  null
  
)

export default component.exports