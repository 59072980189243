import {Action, getModule, Module, Mutation, VuexModule, config} from 'vuex-module-decorators';
import store from '../../../core/store/index.store';
import {AccountMember} from '../models/accountMember.model';
import AccountMemberResource from '@/modules/accounts/api/accountMember.api';

config.rawError = true;

@Module({
    name: 'accountMembers',
    namespaced: true,
    dynamic: true,
    store,
})
class AccountMembersStore extends VuexModule {
    public accountMembers: AccountMember[] = [];
    public loading: boolean = true;

    @Mutation
    public setLoading(state: boolean) {
        this.loading = state;
    }

    @Mutation
    public setAccountMembers(accountMembers: AccountMember[]) {
        this.accountMembers = accountMembers;
    }

    @Action({ commit: 'setLoading' })
    public async updateLoadingState(newLoadingState: boolean): Promise < boolean > {
        return newLoadingState;
    }

    @Action({ commit: 'setAccountMembers' })
    public async fetchAccountMembers(): Promise<AccountMember[]> {
        const accountMembers = await AccountMemberResource.prototype.findAll();
        this.updateLoadingState(false);
        return accountMembers;
    }

    @Action({ commit: 'setAccountMembers' })
    public async demoteAdminToEditor(admin: AccountMember) {
        if (admin.role !== 'admin') {
            throw new Error('User is not an admin');
        }
        await AccountMemberResource.prototype.demoteAdminToEditor(admin);
        return this.context.dispatch('fetchAccountMembers');
    }

    @Action({ commit: 'setAccountMembers' })
    public async promoterEditorToAdmin(editor: AccountMember) {
        if (editor.role !== 'editor') {
            throw new Error('User is not an editor');
        }
        await AccountMemberResource.prototype.promoteEditorToAdmin(editor);
        return this.context.dispatch('fetchAccountMembers');
    }
}

export default getModule(AccountMembersStore, store);
