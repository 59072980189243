import { defineStore } from 'pinia'
import locationsApi from '@/modules/campaigns/api/locations.api';
import { Location } from '@/core/models/location.model';
interface State {
  locationOptions: Location[];
}

export const useCampaignEditorStore = defineStore('campaignEditor', {
  state: (): State => {
    return { 
      locationOptions: []
    }
  },
  actions: {
    async fetchLocationOptions(searchInput: string = ''): Promise<void> {
      const locations = await locationsApi.prototype.locationsByString(searchInput);
      this.locationOptions = locations;
    },
  },
});
