
const introValidation: Record<string, any> = {
  headline: { maxLength: 60 },
}

const completionValidation: Record<string, any> = {
  headline: { maxLength: 60 },
  description: { maxLength: 120 },
}

const leadformInputValidation: Record<string, any> = {
  intro: introValidation,
  completion: completionValidation,
}

export {
  completionValidation,
  introValidation,
  leadformInputValidation,
}