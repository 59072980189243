import { defineStore } from 'pinia'
import { cropImageUrlOnAspectRatio } from '@/modules/ads/helpers';
import { CroppedImage, MasterImage } from '@/modules/medialibrary/models/masterImage.model';
import croppedImageApi from '../api/croppedImage.api';
import adsEditorStore from '@/modules/ads/store/adsEditor.store';
import templateAdsEditorStore from '@/modules/template-ads/store/templateAdsEditor.store';
import { AdType } from '@/modules/ads/models/ads.model';
import { CropperOption } from '@/core/models/cropper.model';
import {
  facebookPostValidation, facebookCarouselValidation,
  instagramPostValidation, instagramCarouselValidation,
  linkedinPostValidation,
} from '@/core/validations/ads/channels';

type AdTypeWithCropper = Extract<AdType, 'FacebookPostAd' | 'FacebookCarouselAd' | 'InstagramCarouselAd' | 'InstagramPostAd' | 'LinkedinPostAd'>;

const minWidthFacebookPost = facebookPostValidation.imageMinWidth;
const minWidthFacebookCarousel = facebookCarouselValidation.imageMinWidth;
const minWidthInstagramPost = instagramPostValidation.imageMinWidth;
const minWidthInstagramCarousel = instagramCarouselValidation.imageMinWidth;
const minWidthLinkedinPost = linkedinPostValidation.imageMinWidth;

interface State {
  image: null,
  activeDimension: CropperOption | null,
  isFromTemplates: boolean,
  readonly cropperOptions: Record<AdTypeWithCropper, CropperOption[]>,
}

const cropperOptions = {
  FacebookPostAd: [
    { height: minWidthFacebookPost, width: minWidthFacebookPost, name: 'square' },
    { height: minWidthFacebookPost, width: 700, name: 'wide' },
    { height: 700, width: minWidthFacebookPost, name: 'tall' },
  ],
  FacebookCarouselAd: [{ height: minWidthFacebookCarousel, width: minWidthFacebookCarousel, name: 'square' }],
  InstagramPostAd: [
    { height: minWidthInstagramPost, width: minWidthInstagramPost, name: 'square' },
    { height: minWidthInstagramPost, width: 700, name: 'wide' },
    { height: 700, width: minWidthInstagramPost, name: 'tall' },
  ],
  InstagramCarouselAd: [{ height: minWidthInstagramCarousel, width: minWidthInstagramCarousel, name: 'square' }],
  LinkedinPostAd: [
    { height: minWidthLinkedinPost, width: minWidthLinkedinPost, name: 'square' },
    { height: minWidthLinkedinPost, width: 700, name: 'wide' },
    { height: 700, width: minWidthLinkedinPost, name: 'tall' },
  ],
};

export const useCroppedImagesStore = defineStore('croppedImagesStore', {
  state: (): State => {
    return { 
      image: null,
      activeDimension: null,
      isFromTemplates: false,
      cropperOptions: cropperOptions as Record<AdTypeWithCropper, CropperOption[]>
  }
},
  getters: { 
    currentAdTypeWithCropper(): AdTypeWithCropper | null {
      const typeOfTheAd = templateAdsEditorStore.currentAdType && this.isFromTemplates ? templateAdsEditorStore.currentAdType : adsEditorStore.currentAdType;
      switch (typeOfTheAd) {
        case 'FacebookStoryAd': case 'InstagramStoryAd': case 'GoogleDisplayGeneratedAd': case 'GoogleDisplayCustomAd' : case 'GoogleSearchAd': return null;
        default: return typeOfTheAd;
      }
    }
  },
  actions: {
    setActiveDimension(cropperOption: CropperOption) {
      this.activeDimension = cropperOption;
    },
    setFromTemplates(status: boolean) {
      this.isFromTemplates = status;
    },
    updateFromTemplates(status: boolean) {
      return status;
    },
    async createCroppedImageFromMasterImage(image: MasterImage): Promise<CroppedImage> {
      return await croppedImageApi.prototype.create(image.id);
    },
    replaceImage(data: { id: string, base64Output: string, copy?: any}) {
      croppedImageApi.prototype.replaceImage(data.id, data.base64Output, data.copy).then(croppedImage => {
        adsEditorStore.setToCroppedUrl(croppedImage);
      });
    },
    createActiveDimension(cropperOption: any) {
      this.activeDimension = cropperOption;
    },
    async cropImagesByFirstCropperOption(adType: AdTypeWithCropper) {
      this.createActiveDimension(this.cropperOptions[adType][0]);

      if (this.isFromTemplates) {
        for (const media of templateAdsEditorStore.media) {
          if (media.type === 'image') {
            const aspectRatio = { x: this.activeDimension!.width, y: this.activeDimension!.height };
            const { id, url } = await cropImageUrlOnAspectRatio(media, aspectRatio);
            templateAdsEditorStore.setToCroppedUrl({ ...media, url, thumbNailUrl: '' });
            this.replaceImage({ id, base64Output: url });
          }
        }
      } else {
        for (const media of adsEditorStore.media) {
          if (media.type === 'image') {
            const aspectRatio = { x: this.activeDimension!.width, y: this.activeDimension!.height };
            const { id, url } = await cropImageUrlOnAspectRatio(media, aspectRatio);
            adsEditorStore.setToCroppedUrl({ ...media, url, thumbNailUrl: '' });
            // TODO the following request has been commented because we might not need to crop image before the Done button is clicked in cropper view
            // this.replaceImage({ id, base64Output: url });
          }
        }
      }
    }
   }
});