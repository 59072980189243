import { render, staticRenderFns } from "./CreateCustomTemplate.vue?vue&type=template&id=097741f6&scoped=true&"
import script from "./CreateCustomTemplate.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CreateCustomTemplate.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CreateCustomTemplate.vue?vue&type=style&index=0&id=097741f6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "097741f6",
  null
  
)

export default component.exports