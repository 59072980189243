import { IDateRange, startOfDay, dateDiff, isSameOrAfterDate } from '@/core/plugins/date';
import { required, minLength, maxLength, minValue, requiredIf, url, between, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

/* Custom Rules */
const locationsOfSameType = (locations: any) => locations.every((l: any) => l.type === locations[0].type);

const singleCountryLocation = (locations: any) => locations.map((l: any) => l.type).filter((t: any) => t === 'country').length < 2;

const dateRangeInFuture = ({ startDate, endDate }: IDateRange) => {
  const today = startOfDay();
  return dateDiff(startDate, today) >= 0 && dateDiff(endDate, today) >= 0;
};

const endDateIsGreaterThenStartDate = ({ startDate, endDate }: IDateRange) => {
  return isSameOrAfterDate(endDate, startDate);
};

const dateRangeMinLimit = (days: number) => ({ startDate, endDate }: IDateRange) => {
  return dateDiff(endDate, startDate) >= days;
};

const dateRangeMaxLimit = (days: number) => ({ startDate, endDate }: IDateRange) => {
  return dateDiff(endDate, startDate) < days;
};

const cannotBeEmptyList = (requiredAmount: number, end?: number, start: number = 0) => helpers.withParams({
  type: 'requiredAmount',
  value: requiredAmount,
}, (items: never[]): boolean => {
  const endLength = end || items.length;
  const filledInputs = items.slice(start, endLength).filter((item) => item);
  return filledInputs.length >= requiredAmount;
});

const currency = (value: number) => {
  const isValidNumber = !Number.isNaN(Number(value));
  const amountOfDigitsAfterDecimalPoint = value.toString().split('.')[1]?.length || 0;
  return isValidNumber && amountOfDigitsAfterDecimalPoint < 3;
};

const defaultOptions = {
  dirty: true,
  first: 1,
};

/* Rules Error Messages */
const formMessages = {
  required: () => 'This field can not be empty',
  minLength: ({ minLength }: { minLength: any }) => `This field is at least ${minLength.$params.min} characters `,
  maxLength: ({ maxLength }: { maxLength: any }) => `This field is max ${maxLength.$params.max} characters `,
  minValue: ({ minValue }: { minValue: any }) => `Value can't be lower then ${minValue.$params.min}`,
  url: () => `Provided url is invalid`,
  locationsOfSameType: () =>  'You can add only 1 type of administrative unit at the same time',
  singleCountryLocation: () => 'You can only select multiple locations in the same country',
  endDateIsGreaterThenStartDate: () => 'End date should be greater than start date',
  dateRangeInFuture: () => 'Only future dates can be selected',
  dateRangeMinLimit: () => 'Date range period is too short',
  dateRangeMaxLimit: () => 'Date range period is too big',
  between: ({ between }: { between: any }) => `Must be between ${between.$params.min} and ${between.$params.max}`,
  cannotBeEmptyList: ({ cannotBeEmptyList }: { cannotBeEmptyList: any }) => `At least ${cannotBeEmptyList.$params.value} inputs should be filled in this group`,
  currency: () => 'Provided amount has invalid format',
};
type VuelidateField = any;
type Vue = any;

type ValidationMessageGenerator = (field: VuelidateField, ...rest: any[]) => string;

interface ValidationMessages {
  [propName: string]: ValidationMessageGenerator;
}

interface VuelidateMessageOptions {
  dirty?: boolean;
  first?: number;
}

function validationMessages(messages?: ValidationMessages, opts?: VuelidateMessageOptions): 
  (field: VuelidateField, ...args: any[]) => string[] {
  return function(this: Vue, field, ...args) {
    opts = { ...defaultOptions, ...opts };
    if (field.$pending || (opts.dirty && !field.$dirty)) return [];
    const isInvalid = (x: string) => x.charAt(0) !== '$' && field[x].$invalid === true;
    const keys = Object.keys(field).filter(isInvalid).slice(0, opts.first);
    const messageList: Record<string, any> = { ...formMessages, ...messages };
    return keys.map(x => {
      if (!messageList[x]) return 'Invalid';
      return messageList[x].call(this, field, ...args);
    });
  };
}

function validationMessage(
  messages?: ValidationMessages,
  opts?: VuelidateMessageOptions,
): (field: VuelidateField, ...args: any[]) => string {
  const mkMsgs = validationMessages(messages, { ...opts, first: 1 });
  return function(this: Vue, ...args) {
    return mkMsgs.apply(this, args)[0] || '';
  };
}

export {
  required,
  maxLength,
  minLength,
  url,
  between,
  validationMessage,
  validationMessages,
  requiredIf,
  locationsOfSameType,
  singleCountryLocation,
  dateRangeInFuture,
  endDateIsGreaterThenStartDate,
  dateRangeMinLimit,
  dateRangeMaxLimit,
  cannotBeEmptyList,
  minValue,
  currency,
  useVuelidate,
};
