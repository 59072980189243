export interface Account {
    id?: string;
    name: string;
}
export interface IAccount {
    name: string;
    street: string;
    number: number | null;
    zipcode: string;
    city: string;
    country: string;
    agreement?: string;
    currency: string;
    fee: string;
    image: string;
  }
  export interface IMasterAccount extends Omit<IAccount, 'currency'> {
    firstName: string;
    lastName: string;
    email: string;
  }


export enum ECurrency  {
    EURO = 'EUR (€)',
    DOLLAR = 'USD ($)',
    POUND = 'GBP (£)',
}
