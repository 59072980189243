import { render, staticRenderFns } from "./CombinedPage.vue?vue&type=template&id=6fdbbe88&scoped=true&"
import script from "./CombinedPage.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CombinedPage.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CombinedPage.vue?vue&type=style&index=0&id=6fdbbe88&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fdbbe88",
  null
  
)

export default component.exports