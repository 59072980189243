import { defineStore } from 'pinia'
import {InvitationRequest} from '@/modules/accounts/models/invitationRequest.model';
import {Invitation} from '@/modules/accounts/models/invitation';
import InvitationResource from '@/modules/accounts/api/invitation.api';
interface State {
  invitations: Invitation[];
  loading: boolean;
}

export const useInvitationStore = defineStore('invitations', {
  state: (): State => {
    return { 
      invitations: [],
      loading: false
    }
  },
  actions: {
    async inviteUser(invitationRequest: InvitationRequest) {
      await InvitationResource.prototype.inviteUser(invitationRequest);
      this.fetchInvitations()
    },
    async fetchInvitations(): Promise<void> {
      const invitations = await InvitationResource.prototype.findAll();
      this.loading = false;
      this.invitations = invitations;
    }
  }
});