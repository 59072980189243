import {Module, getModule, Action, Mutation, config} from 'vuex-module-decorators';
import store from '@/core/store/index.store';
import {AbstractAds} from '../abstractTemplateCustom.store';
import adsEditorStore from '../templateAdsEditor.store';
import {AbstractResource} from '@/core/api/abstract.api';
import InstagramPostAdResource, {InstagramPostAdResponseJson, InstagramPostAdRequestJson} from '../../api/instagramPostCustom.api';
import {AdMedia} from '@/modules/medialibrary/models/video.model';
import {InstagramPostAd} from '../../models/instagramAds.model';
import { SocialPage } from '@/modules/accounts/models/socialPage.model';
import { useSocialPagesStore } from '@/modules/accounts/store/socialPages.store';

config.rawError = true;

interface InstragramAdCopy {
  message: string;
  callToAction: string;
}

@Module({
  name: 'instagramPostCustomTemplates',
  namespaced: true,
  dynamic: true,
  store,
})
class InstagramPostAds extends AbstractAds<InstagramPostAd, InstagramPostAdResponseJson, InstagramPostAdRequestJson> {
  protected get resource(): AbstractResource<InstagramPostAd, InstagramPostAdResponseJson, InstagramPostAdRequestJson> {
    return InstagramPostAdResource.prototype;
  }

  public instagramPage: SocialPage | undefined | object = {};

  get media(): AdMedia[] {
    return adsEditorStore.media.length > 0 ?
      adsEditorStore.media :
      [{ id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'}];
  }

  @Mutation
  public async setInstagramPage(page: SocialPage | {} | undefined) { 
    this.instagramPage = page;
  }

  @Action({commit: 'setInstagramPage'})
  public updateInstagramPage(id: string | null | undefined) {
    const socialPagesStore = useSocialPagesStore();
    return socialPagesStore.returnSocialPage({id, channel: 'instagram'});
  }

  @Action
  public async createAds(): Promise<void> {
    for (const media of this.media) {
      const instagramPostAd: InstagramPostAd = {
        media,
        ...adsEditorStore.copy,
      };
      await this.createAd(instagramPostAd);
    }
  }
}

export default getModule(InstagramPostAds, store);
