import { render, staticRenderFns } from "./ChangeCampaignStatusExtend.vue?vue&type=template&id=58d85752&scoped=true&"
import script from "./ChangeCampaignStatusExtend.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ChangeCampaignStatusExtend.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ChangeCampaignStatusExtend.vue?vue&type=style&index=0&id=58d85752&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58d85752",
  null
  
)

export default component.exports