import toastNotificationStore from '../store/toastNotification.store';
import _ from 'lodash';
import { CustomTemplate } from '@/modules/templates/custom/models/customTemplate.model';
import { DefaultTemplate } from '@/modules/templates/default/models/defaultTemplate.model';
import { Channel } from '@/modules/ads/models/ads.model';
import { Campaign } from '@/modules/campaigns/models/campaign.model';
import { EMPLOYER_BRAND_AWARENESS, FAST_APPLY_FORM } from '@/core/store/productConfig';

export const handleUnknownError = (data: any) => {
  toastNotificationStore.showToastNotification({
    message: _.get(data, 'message', 'Unknown error'),
    isError: true,
  });
};

export const handleResponseError = (error: any, path: string = 'response.data.message') => {
  const responseError = _.get(error, path);
  const tryCatchError = _.get(error, 'message', error);
  const unknownError = 'Unknown error';
  return toastNotificationStore.showToastNotification({
    isError: true,
    message: responseError || tryCatchError || unknownError,
  });
};

export function showNotification(params: {message: string, isError?: boolean, isSuccess?: boolean, isWarning?: boolean}): void {
    toastNotificationStore.showToastNotification({
      message: params.message,
      isError: params.isError ? params.isError : false,
      isSuccess: params.isSuccess ? params.isSuccess : false,
      isWarning: params.isWarning ? params.isWarning : false,
    });
}

export function showChannel(data: { channel: Channel, template?: DefaultTemplate | CustomTemplate, campaign?: Campaign}) {
  const { channel, template, campaign } = data;

  const entityType = template ? (template as CustomTemplate).type : campaign?.type;

  if (entityType === EMPLOYER_BRAND_AWARENESS && channel === 'google-search') {
    return false;
  }

  if (entityType === FAST_APPLY_FORM && !(channel === 'facebook' || channel === 'instagram')) {
    return false;
  }

  return true;
}

export function groupArrayStrings(data: { arr: string[], groupCount: number, replacingStr: string, joinSymbol: string }) {
  const { arr, groupCount, replacingStr, joinSymbol } = data;
  const result = [];
  const mappedWithReplacement = arr.map((string) => {
    return string ? string : replacingStr;
  });

  for (let i = 0; i < mappedWithReplacement.length; i += groupCount) {
    const subset = mappedWithReplacement.slice(i, i + groupCount);

    result.push(extendSubsetArrayIfNotEnoughItems({
      subsetArray: subset,
      groupCount,
      array: mappedWithReplacement,
    }).join(joinSymbol));
  }

  return result;
}

export function shuffle(arr: string[]) {
  const arrayCopy = [...arr];

  return arrayCopy.sort(() => Math.random() - 0.5);
}

function extendSubsetArrayIfNotEnoughItems(data: { subsetArray: string[], groupCount: number, array: string[] }) {
  const { subsetArray, groupCount, array } = data;
    if (subsetArray.length < groupCount) {
      const count = groupCount - subsetArray.length;

      for (let i = 0; i < count; i += 1) {
        subsetArray.push(array[i]);
      }

    }

  return subsetArray;
}
interface IReadFile {
  file: File;
  image: HTMLImageElement;
}
export function readFileAsync(file: Event) {
  const fileInput = file.target as HTMLInputElement;
  const fileItem = fileInput?.files![0];
  
  return new Promise<IReadFile>((resolve, reject) => {
    const reader = new FileReader();
    const image = new Image();
    reader.onload = e => {
      image.src =  e.target?.result as string;
      image.onload =  () => {
        resolve({
          file: fileItem,
          image,
        });
      };
    };

    reader.readAsDataURL(fileItem);
    reader.onerror = reject;
  });
}


export function initScript(scriptURL: string, id: string) {
  const hasLoaded = document.getElementById(id);
  if (hasLoaded) return Promise.resolve();

  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = scriptURL;
  script.id = id;
  script.async = true;
  head.appendChild(script);
}

export function uid(): string {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

export function formatToHundreds(value: number, precision = 1): string | number {
  return value < 1000 ? value : parseFloat((value / 1000).toFixed(precision)) + 'k';
}

export function capitalize(value: string): string {
  if (!value) { return ''; }

  return value.charAt(0).toUpperCase() + value.slice(1);
}
