import httpService from '@/core/plugins/httpService';
import defaultTemplateStore from '../../templates/default/store/defaultTemplates.store';
import { GoogleSearchAd } from '../models/googleSearchAds.model';
import { GoogleSearchCopy } from '../store/templateAdsEditor.store';
import { handleResponseError } from '@/core/assets/global-utils';

export default class GoogleSearchAdResource {
  protected get resourceName() {
    if (!defaultTemplateStore.selectedDefaultTemplate) {
      throw new Error('Trying to perform ad related actions without a selected template.');
    }
    return `templates/${defaultTemplateStore.selectedDefaultTemplate.id}/google-search-templates`;
  }

  protected get endpoint(): string {
    return '/' + this.resourceName;
  }

  public async findAll(): Promise<GoogleSearchAd[]> {
    return await httpService.get(this.endpoint)
      .then((response) => this.hydrateCollection(response.data as GoogleSearchAdJson[]));
  }

  public create({ headlines, descriptions}: GoogleSearchCopy) {
    const requestCreateData = {
      headlines,
      descriptions,
    };

    return httpService.post( this.endpoint, requestCreateData)
    .then((response) => this.hydrate(response.data as GoogleSearchAdJson))
    .catch((error) => {
      handleResponseError(error);
    });
  }

  public update({id, headlines, descriptions}: GoogleSearchAd) {
    const requestUpdateData = {
      id, 
      headlines,
      descriptions,
    };
    return httpService.put(`/google-search-templates/${id}`, requestUpdateData)
      .then((response) => this.hydrate(response.data = {} as GoogleSearchAdJson));
  }

  public delete(ad: GoogleSearchAd): Promise<void> {
    return httpService.delete('/google-search-templates/' + ad.id);
  }

  protected hydrate(json: GoogleSearchAdJson): GoogleSearchAd {
    return {
      id: `${json.id}`,
      headlines: json.headlines,
      descriptions: json.descriptions,
    };
  }

  protected hydrateCollection(responseCollection: GoogleSearchAdJson[]): GoogleSearchAd[] {
    return responseCollection.map((response) => this.hydrate(response));
  }
}

interface GoogleSearchAdJson {
  id: string;
  campaign_id: string;
  descriptions: string[];
  headlines: string[];
}
