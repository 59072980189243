import { defineStore } from 'pinia'

import {Workspace} from '@/modules/accounts/models/workspace.model';
import { WorkspaceMember } from '@/modules/accounts/models/workspaceMember.model';
import WorkspaceResource from '@/modules/accounts/api/workspace.api';
import WorkspaceMemberResource from '@/modules/accounts/api/workspaceMember.api';
import campaignStore from '@/modules/campaigns/store/campaigns.store';
import userModule from '@/modules/auth/store/localUser.store';
import { Campaign } from '@/modules/campaigns/models/campaign.model';

const WORKSPACE_SLUG = 'WORKSPACE_SLUG';
interface State {
  workspaces: Workspace[];
  selectedWorkspace: Workspace | null;
  loading: boolean;
  searchString: string
  members: WorkspaceMember[];
}
export const useWorkspaceStore = defineStore('workspace', {
  state: (): State => {
    return {
      workspaces: [],
      selectedWorkspace: null,
      loading: true,
      searchString: '',
      members: [],
    }
  },
  getters: {
    filteredWorkspaces(state: State) {
      return state.workspaces.filter((workspace) => {
        return workspace.name.toLowerCase().includes(state.searchString.toLowerCase());
      }).sort();
    },
    individualWorkspace(state: State) {
      return state.selectedWorkspace;
    },
    workspaceMembers(state: State) {
      return state.members;
    },
    hasWorkspaces(state: State): boolean {
      return state.workspaces.length > 0;
    },
    hasSelectedWorkspace(state: State): boolean {
      return state.selectedWorkspace !== null;
    },
    selectedWorkspaceHasCampaigns(state: State): boolean {
      if (state.selectedWorkspace) {
        return campaignStore.campaigns.some((campaign: Campaign) => {
          return state.selectedWorkspace!.campaignIds.includes(campaign.id);
        });
      }
      return false;
    },
    workspaceSlug(state: State) {
      return state.selectedWorkspace?.slug;
    },
  },
  actions: {
    search(searchString: string): void {
      this.searchString = searchString;
    },
    async removeWorkspace(workspace: Workspace): Promise<Workspace> {
      await WorkspaceResource.prototype.delete(workspace);
      this.selectWorkspace(this.workspaces[0]);
      this.workspaces = this.workspaces.filter((currentWorkspace) => {
        return currentWorkspace.id !== workspace.id;
      });
      return workspace;
    },
    async updateLoadingState(newLoadingState: boolean): Promise<boolean> {
      this.loading = newLoadingState;
      return newLoadingState;
    },
    async fetchWorkspaces(): Promise<Workspace[] | void> {
      const workspaces = await WorkspaceResource.prototype.findUserWorkspaces(userModule.user!.id!);
      await this.updateLoadingState(false);
      this.workspaces = workspaces as Workspace[];
      return workspaces;
    },
    async fetchWorkspaceMembers(): Promise<void> {
      const members = await WorkspaceMemberResource.prototype.findAll();
      this.members = members;
    },
    async createWorkspace(workspace: Workspace): Promise<void> {
      workspace = await WorkspaceResource.prototype.create(workspace);
      this.selectWorkspace(workspace);
      this.workspaces.push(workspace);
    },
    async addUser(userId: string): Promise<void> {
      await WorkspaceMemberResource.prototype.addUser(userId);
      this.fetchWorkspaceMembers();
    },
    async renameWorkspace(workspace: Workspace): Promise<Workspace> {
      const updatedWorkspace = await WorkspaceResource.prototype.rename(workspace);
      const renamedWorkspace = {
        ...updatedWorkspace,
        name: workspace.name,
      };
      this.workspaces.map((item) => {
        if (item.id === renamedWorkspace.id) {
          item.name = renamedWorkspace.name;
          item.slug = renamedWorkspace.slug;
        }
        return item;
      });
      return renamedWorkspace;
    },
    selectWorkspace(workspace: Workspace): void {
      window.sessionStorage.setItem('WORKSPACE_SLUG', workspace.slug);
      this.setSelectedWorkspace(workspace);
    },
    async determineSelectedWorkspace(slug?: string): Promise<void> {
      const workspaceSlug: string | null = slug
        ? slug
        : window.sessionStorage.getItem(WORKSPACE_SLUG);

      await this.fetchWorkspaces();
      if (workspaceSlug === 'All') {
        this.setSelectedWorkspace({ id: '', name: 'All Workspaces', slug: 'All', campaignIds: [] });
      } else {
        const currentWorkspace = this.workspaces?.find((workspace) => workspace.slug === workspaceSlug);
        const workspaceToSet = workspaceSlug && currentWorkspace ? currentWorkspace : this.workspaces[0];
        this.setSelectedWorkspace(workspaceToSet);
      }
    },
    setSelectedWorkspace(workspace: Workspace) {
      window.sessionStorage.setItem('WORKSPACE_SLUG', workspace.slug);
      this.selectedWorkspace = workspace;
    },
    setWorkspaces(workspaces: Workspace[]) {
      this.workspaces = workspaces;
    },
  }
})
