import {Location, LocationType} from '@/core/models/location.model';
import httpService from '@/core/plugins/httpService';
import {last} from 'lodash';

export default class LocationsApi {
  public async locationsByString(searchInput: string): Promise<Location[]> {
    return searchInput?.length > 0
      ? httpService.get(`${this.locationService}${searchInput}`, { withCredentials: false })
            .then((response) => response.data.map((location: LocationJson) => this.hydrate(location)))
            .catch(() => [])
        : [];
  }

  protected extract(model: Location): LocationJson {
    const { id: place_id, name, type } = model;
    const terms: LocationTerms[] = [];
    return { place_id, name, type, terms };
  }

  protected hydrate(json: LocationJson): Location {
    const { place_id: id, name, type, terms } = json;
    const country = last(terms) ? last(terms)!.value : '';
    return { id, name, type, country };
  }

  protected get locationService(): string | undefined {
      return process.env.VUE_APP_LOCATION_SERVICE;
  }
}

interface LocationJson {
  place_id: string;
  name: string;
  terms: LocationTerms[];
  type: LocationType;
}

interface LocationTerms {
  offset: number;
  value: string;
}
