import { defineStore } from 'pinia';

import VideoApi from '@/modules/medialibrary/api/video.api';

import adsEditorStore from '@/modules/ads/store/adsEditor.store';
import { useMediaLibraryStore } from '@/modules/medialibrary/store/mediaLibrary.store';
import toastNotificationStore from '@/core/store/toastNotification.store';

import { AdMedia, InvalidVideo, Video } from '@/modules/medialibrary/models/video.model';
import { AdValidation } from '@/modules/ads/models/ads.model';
import { isValidVideoRatio, isValidVideoMaxDuration, isValidVideoMinWidth } from '@/modules/ads/helpers';

interface State {
  videos: Video[];
}

export const useVideosStore = defineStore('videosStore', {
  state: (): State => {
    return {
      videos: [], 
    }
  },

  getters: {
    currentValidations(): AdValidation | null {
      const mediaLibraryStore = useMediaLibraryStore();
      return mediaLibraryStore.currentValidations;
    },
    validVideos(): Video[] {
      if (!!this.currentValidations) {
        const { videoMaxDuration, videoRatio, videoMinWidth} = this.currentValidations!;
        return this.videos.filter((video) => {
          return isValidVideoRatio(video, videoRatio) &&
            isValidVideoMaxDuration(video, videoMaxDuration) &&
            isValidVideoMinWidth(video, videoMinWidth);
      });
      }
      return this.videos;
    },
    invalidVideos(): InvalidVideo[] {
      const invalidVideos = this.videos.filter((video) => !this.validVideos.includes(video));
      return invalidVideos.map((video) => {
        return {
          ...video,
          invalidDuration: this.currentValidations ? !isValidVideoMaxDuration(video, this.currentValidations.videoMaxDuration) : false,
          invalidMinWidth: this.currentValidations ? !isValidVideoMinWidth(video, this.currentValidations.videoMinWidth) : false,
          invalidRatio: this.currentValidations ? !isValidVideoRatio(video, this.currentValidations.videoRatio) : false,
        };
      });
    },
  },

  actions: {
    async getVideos(): Promise<void> {
      this.videos = await VideoApi.prototype.findAll();
    },
    removeVideoFromState(video: Video) {
      this.videos = this.videos.filter((item) => {
        return video.id !== item.id;
      });
    },
    async deleteVideo(video: Video): Promise<void> {
      const mediaLibraryStore = useMediaLibraryStore();
      
      mediaLibraryStore.removeSelectedMedia(video);
      await VideoApi.prototype.delete(video);
      this.removeVideoFromState(video);
      toastNotificationStore.mediaSelectionLength! > 1 
        ? toastNotificationStore.showToastNotification(toastNotificationStore.deleteMultipleMediaNotification) 
        : toastNotificationStore.showToastNotification({ message: `${video.title} successfully deleted`});
    },
    async createVideo(file: File): Promise<Video> {
      const mediaLibraryStore = useMediaLibraryStore();
    
      mediaLibraryStore.incrementPlaceholderCount();
      const createdVideo = await VideoApi.prototype.create(file);
      mediaLibraryStore.decrementPlaceholderCount();
      this.videos.push(createdVideo);
      return createdVideo;
    },
    async renameVideo(video: Video): Promise<void> {
      await VideoApi.prototype.rename(video);
      toastNotificationStore.showToastNotification({ message: `${video.title} successfully renamed`});
    },
    async fetchVideoById(videoId: string) {
      try {
        const video = await VideoApi.prototype.fetchVideoById(videoId);
        if (video && video.thumbnailUrls) {
          const result = this.videos.map((currentVideo) => {
            return currentVideo.id === video.id ? video : currentVideo;
          });
          this.videos = result;
        }
      } catch (error) {
        console.log(error);
      }
    },
    validateVideo(file: File): boolean {
      const acceptedVideoTypes = ['video/mp4', 'video/quicktime'];
      return file && acceptedVideoTypes.includes(file.type);
    },
    createAdMediaFromVideo(video: Video): AdMedia {
      return {
        ...video,
        previewUrl: video.thumbnailUrl,
        masterUrl: video.url,
      };
    },
    removeSelectedInvalidVideo(invalidVideos: InvalidVideo[]) {
      const validMedia = adsEditorStore.media.filter((media) => {
        return media.type === 'video'
          ? !invalidVideos.find((video) => video.id === media.id)
          : true;
      });
      if (validMedia.length < adsEditorStore.media.length) {
        toastNotificationStore.showToastNotification({ message: 'Some media was invalid for this ad type'});
      }
      adsEditorStore.setMedia(validMedia);
    }
  },
});
