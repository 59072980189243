import store from '@/core/store/index.store';
import adsEditorStore from '../templateAdsEditor.store';
import { getModule, Module, Action, config } from 'vuex-module-decorators';
import { AdMedia } from '@/modules/medialibrary/models/video.model';
import {AbstractAds} from '../abstractTemplateCustom.store';
import { AbstractResource } from '@/core/api/abstract.api';
import {FacebookCarouselAd} from '../../models/facebookAds.model';
import FacebookCarouselAdResource, {FacebookCarouselAdResponseJson, FacebookCarouselAdRequestJson} from '../../api/facebookCarouselCustom.api';

config.rawError = true;

@Module({
  name: 'facebookCarouselCustomTemplates',
  namespaced: true,
  dynamic: true,
  store,
})
class FacebookCarouselAds extends AbstractAds<
  FacebookCarouselAd,
  FacebookCarouselAdResponseJson,
  FacebookCarouselAdRequestJson> {
  
  protected get resource(): AbstractResource<
    FacebookCarouselAd,
    FacebookCarouselAdResponseJson,
    FacebookCarouselAdRequestJson> {
      return FacebookCarouselAdResource.prototype;
    }
    get media(): AdMedia[] {
      return adsEditorStore.media.length > 0 ?
        adsEditorStore.media :
        [
          { id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'},
          { id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'},
        ];
    }

  @Action
  public async createNewAd() {
    const media = this.media;
    const facebookCarouselAd: any = {
      media,
      ...adsEditorStore.copy,
    };
    await this.createAd(facebookCarouselAd);
  }
}


export default getModule(FacebookCarouselAds, store);
