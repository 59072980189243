import { render, staticRenderFns } from "./DefaultDropDown.vue?vue&type=template&id=53faa383&scoped=true&"
import script from "./DefaultDropDown.vue?vue&type=script&setup=true&lang=ts&"
export * from "./DefaultDropDown.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53faa383",
  null
  
)

export default component.exports