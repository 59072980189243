import { render, staticRenderFns } from "./LinkedInAdInputs.vue?vue&type=template&id=5deef056&scoped=true&"
import script from "./LinkedInAdInputs.vue?vue&type=script&setup=true&lang=ts&"
export * from "./LinkedInAdInputs.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./LinkedInAdInputs.vue?vue&type=style&index=0&id=5deef056&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5deef056",
  null
  
)

export default component.exports