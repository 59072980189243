import { getDateWithTimezone } from '@/core/plugins/date';
import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import {MasterImage, MasterLogo} from '@/modules/medialibrary/models/masterImage.model';
import httpService from '@/core/plugins/httpService';
import toastNotificationStore from '@/core/store/toastNotification.store';
import { useMediaLibraryStore } from '@/modules/medialibrary/store/mediaLibrary.store';

export default class MasterImageApi {
    protected get resourceName() {
        const accountsStore = useAccountsStore();
        if (!accountsStore.selectedAccount) {
            throw new Error('Trying to perform master image related actions without a selected account');
        }
        return `accounts/${accountsStore.selectedAccount.id}/master-images`;
    }

    protected get endpoint(): string {
        return '/' + this.resourceName;
    }

    public async create(image: File): Promise<MasterImage | null> {
        const formData = new FormData();
        formData.append('image', image);
        let imageToReturn: MasterImage | null = null;
        try {
          imageToReturn = await httpService.post( this.endpoint, formData)
          .then((response) => this.hydrate(response.data as MasterImageJson));
        } catch (err) {
            const mediaLibraryStore = useMediaLibraryStore();
            mediaLibraryStore.decrementPlaceholderCount();
            toastNotificationStore.showToastNotification({
                message: 'One or more images have invalid format',
                isError: true,
            });
        }
        return imageToReturn;
    }

    public createLogo(image: File) {
        const formData = new FormData();
        formData.append('image', image);
        formData.append('isLogo', '1');
        const imageToReturn = httpService.post( this.endpoint, formData)
          .then((response) => this.hydrateLogo(response.data as MasterLogoJson));
        return imageToReturn;
    }

    public rename(image: MasterImage): Promise<void> {
        return httpService.put('/master-images/' + image.id, { title: image.title });
    }

    public delete(image: MasterImage): Promise<void> {
        return httpService.delete('/master-images/' + image.id);
    }

    public async fetchAllImages(): Promise<MasterImage[]> {
        const response = await httpService.get(this.endpoint);
        return this.hydrateCollection(response.data as MasterImageJson[]);
    }

    public async fetchAllLogos(): Promise<MasterLogo[]> {
        const response = await httpService.get(this.endpoint, { params: { isLogo: 1 } });
        return this.hydrateLogoCollection(response.data as MasterLogoJson[]);
    }

    protected hydrate(json: MasterImageJson): MasterImage {
        return {
            id: json.id,
            title: json.title,
            url: json.url,
            thumbnailUrl: json.thumb_url,
            fileName: json.file_name,
            user: json.user,
            type: 'image',
            createdAt: getDateWithTimezone(json.created_at, 'YYYY-MM-DD'),
            height: json.height,
            width: json.width,
        };
    }

    protected hydrateLogo(json: MasterLogoJson): MasterLogo {
        return {
            id: json.id,
            title: json.title,
            url: json.url,
            thumbnailUrl: json.thumb_url,
            fileName: json.file_name,
            user: json.user,
            type: 'logo',
            createdAt: getDateWithTimezone(json.created_at, 'YYYY-MM-DD'),
            height: json.height,
            width: json.width,  
        };
    }

    protected hydrateCollection(responseCollection: MasterImageJson[]): MasterImage[] {
        return responseCollection.map((response) => this.hydrate(response));
    }

    protected hydrateLogoCollection(responseCollection: MasterLogoJson[]): MasterLogo[] {
        return responseCollection.map((response) => this.hydrateLogo(response));
    }
}

interface MasterImageJson {
    id: string;
    title: string;
    url: string;
    thumb_url: string;
    created_at: string;
    file_name: string;
    width: number;
    height: number;
    user: string;
}

interface MasterLogoJson {
    id: string;
    title: string;
    url: string;
    thumb_url: string;
    created_at: string;
    file_name: string;
    width: number;
    height: number;
    user: string;
}
