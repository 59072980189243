import customTemplateStore from '../../templates/custom/store/customTemplates.store';
import {AbstractResource} from '@/core/api/abstract.api';
import {InstagramPostAd} from '../models/instagramAds.model';
import {MediaType} from '@/modules/medialibrary/models/masterImage.model';
import { hydrateAdMedia, AdMediaJson } from '@/modules/ads/api/apiHelpers';

export default class InstagramPostAdResource extends AbstractResource<
  InstagramPostAd,
  InstagramPostAdResponseJson,
  InstagramPostAdRequestJson
  > {

    protected get resourceName() {
      return 'instagram-post-templates';
    }

    protected get resourceOwner(): string {
      if (!customTemplateStore.selectedCustomTemplate) {
        throw new Error('Trying to perform ad related actions without a selected default template');
      }
      return `customTemplates/${customTemplateStore.selectedCustomTemplate.id}`;
    }

    protected extract(model: InstagramPostAd): InstagramPostAdRequestJson {
      return {
        media: {
          id: model.media.id,
          type: model.media.type,
        },
        message: model.message,
        callToAction: model.callToAction,
      };
    }

    protected hydrate(json: InstagramPostAdResponseJson): InstagramPostAd {
      return {
        id: String(json.id),
        media: hydrateAdMedia(json.media),
        message: json.message,
        callToAction: json.callToAction,
      };
    }

  }

export interface InstagramPostAdResponseJson {
  id: string;
    media: AdMediaJson;
    callToAction: string;
    message: string;
}

export interface InstagramPostAdRequestJson {
  media: {
    id: string;
    type: MediaType;
  };
  callToAction: string;
  message: string;
}
