import store from '@/core/store/index.store';
import adsEditorStore from '../templateAdsEditor.store';
import { Action, getModule, Module, config } from 'vuex-module-decorators';
import { AbstractResource } from '@/core/api/abstract.api';
import { AdMedia } from '@/modules/medialibrary/models/video.model';
import FacebookStoryAdResource, {FacebookStoryAdRequestJson, FacebookStoryAdResponseJson} from '../../api/facebookStoryAd.api';
import {AbstractAds} from '../abstractTemplateAds.store';
import {FacebookStoryAd} from '../../models/facebookAds.model';

config.rawError = true;

@Module({
  name: 'facebookStoryTemplates',
  namespaced: true,
  dynamic: true,
  store,
})
class FacebookStoryAds extends AbstractAds<FacebookStoryAd, FacebookStoryAdResponseJson, FacebookStoryAdRequestJson> {
  protected get resource(): AbstractResource<FacebookStoryAd, FacebookStoryAdResponseJson, FacebookStoryAdRequestJson> {
    return FacebookStoryAdResource.prototype;
  }

  get media(): AdMedia[] {
    return adsEditorStore.media.length > 0 ?
      adsEditorStore.media :
      [{ id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'}];
  }

  @Action
  public async createAds(): Promise<void> {
    for (const media of this.media) {
      const facebookStoryAd: FacebookStoryAd = {
        media,
        ...adsEditorStore.copy,
      };
      await this.createAd(facebookStoryAd);
    }
  }
}

export default getModule(FacebookStoryAds, store);
