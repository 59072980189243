import _ from 'lodash';
import store from '@/core/store/index.store';
import { Action, getModule, Module, Mutation, VuexModule, config } from 'vuex-module-decorators';

import CustomTemplateResource from '@/modules/templates/custom/api/customTemplates.api';

import ChangeTemplateStatusDelete from '@/modules/templates/default/modals/ChangeTemplateStatusDelete.vue';

import { Channel } from '@/modules/ads/models/ads.model';
import { CustomTemplate } from '@/modules/templates/custom/models/customTemplate.model';
import { FunctionGroup } from '@/modules/campaigns/models/function.group.model';
import { JobProfile } from '@/core/models/jobProfile.model';
import { Leadform } from '@/modules/campaigns/models/campaign.model';
import { Location } from '@/core/models/location.model';
import { ProductType } from '@/core/models/product.model';
import { SocialPage } from '@/modules/accounts/models/socialPage.model';

import { CUSTOM, EMPLOYER_BRAND_AWARENESS, JOB_APPLICATIONS, VACANCY_VISITS, FAST_APPLY_FORM } from '@/core/store/productConfig';
import { handleUnknownError } from '@/core/assets/global-utils';

config.rawError = true;

type leadformTagInputType = 'introHeadline' | 'introDescription' | 'completionHeadline' | 'completionDescription' | 'completionButtonUrl';

export interface ModalParams {
  isActive: boolean;
  currentComponent: object | null;
  template: CustomTemplate | null;
  socialChannel: Channel | null;
}

@Module({
  name: 'customTemplates',
  namespaced: true,
  dynamic: true,
  store,
})
class CustomTemplates extends VuexModule {
  public loading: boolean = false;
  public editingTemplate: boolean = false;
  public isEditingLeadform: boolean = false;

  public activeCustomModal: ModalParams = {
    isActive: false,
    currentComponent: null,
    template: null,
    socialChannel: null,
  };

  public selectedCustomTemplate: CustomTemplate | null = null;
  public selectedLeadform: Leadform | null = null;
  public customTemplates: CustomTemplate[] = [];

  get templateId() {
    return _.get(this.selectedCustomTemplate, 'id', '');
  }

  get customTemplateType(): ProductType | undefined {
    return _.get(this.selectedCustomTemplate, 'type');
  }

  get goalTypes() {
    return [
      {
        type: EMPLOYER_BRAND_AWARENESS, 
        tag: 'awareness',
        header: 'Employer brand awareness',
        target: 'potential candidates in a job function',
        goal: 'impressions',
      },
      {
        type: VACANCY_VISITS, 
        tag: 'consideration',
        header: 'Vacancy visits',
        target: 'potential candidates that fit a specific profile',
        goal: 'clicks',
      },
      {
        type: JOB_APPLICATIONS, 
        tag: 'conversions',
        header: 'Job applications',
        target: 'potential candidates that fit a specific profile',
        goal: 'conversions',
      },
      {
        type: FAST_APPLY_FORM,
        tag: 'conversions',
        header: 'Fast apply form',
        target: 'potential candidates that fit a specific profile',
        goal: 'conversions',
      },
    ];
  }

  get isEditing(): boolean {
    return this.editingTemplate;
  }

  get hasCustomTemplates(): CustomTemplate[] {
    return this.customTemplates;
  }

  get isCustomType(): boolean {
    return this.customTemplateType === CUSTOM;
  }

  get isAwarenessType(): boolean {
    return this.customTemplateType === EMPLOYER_BRAND_AWARENESS;
  }

  get isJobApplicationsType(): boolean {
    return this.customTemplateType === JOB_APPLICATIONS; 
  }

  get isVacancyVisitsType(): boolean {
    return this.customTemplateType === VACANCY_VISITS;
  }

  get completedCustomTemplates() {
    return this.customTemplates.filter((template) => {
      if (
          checkIfCustomTemplateIsComplete(template)
        ) {
        return template;
      }
      return;
    });
  }

  @Mutation
  public setModal(newModalParams: ModalParams) {
    this.activeCustomModal = newModalParams;
  }

  @Mutation
  public setEditTrue() {
    this.editingTemplate = true;
  }

  @Mutation
  public setEditFalse() {
    this.editingTemplate = false;
  }

  @Mutation
  public setEditingLeadform(status: boolean): void {
    this.isEditingLeadform = status;
  }

  @Mutation
  public setSelectedCustomTemplate(template: CustomTemplate) {
    this.selectedCustomTemplate = template;
  }

  @Mutation
  public setCustomTemplates(data: CustomTemplate[]) {
    this.customTemplates = data;
  }

  @Mutation
  public appendTemplate(template: CustomTemplate) {
    this.customTemplates.push(template);
    this.selectedCustomTemplate = template;
  }

  @Mutation
  public removeCustomTemplate(template: CustomTemplate) {
    const filtered = this.customTemplates.filter((item) => {
      return item.id !== template.id;
    });
    this.customTemplates = filtered;
  }

  @Mutation
  public updateTemplate({template, type}: {template: CustomTemplate, type: ProductType}) {
    this.customTemplates.map((item) => {
      if (item.id === template.id) {
        item.type = type;
      }
    });
  }

  @Mutation
  public setSocialPage(socialPage: SocialPage) {
    if (socialPage.channel === 'facebook') {
      this.selectedCustomTemplate!.facebookPageId = socialPage.id!;
    }
    if (socialPage.channel === 'instagram') {
      this.selectedCustomTemplate!.instagramPageId = socialPage.id!;
    }
    if (socialPage.channel === 'linkedin') {
      this.selectedCustomTemplate!.linkedinPageId = socialPage.id!;
    }
  }

  @Mutation
  public appendJobProfile(profile: JobProfile) {
    this.selectedCustomTemplate!.profiles.push(profile);
  }

  @Mutation
  public removeJobProfile(profile: JobProfile) {
    this.selectedCustomTemplate!.profiles = this.selectedCustomTemplate!.profiles.filter((item) => {
      return item.id !== profile.id;
    });
  }

  @Mutation
  public setFunctionGroup(functionGroup: FunctionGroup) {
    this.selectedCustomTemplate!.functionGroup = functionGroup;
  }

  @Mutation
  public removeFunctionGroup() {
    this.selectedCustomTemplate!.functionGroup = '';
  }

  @Mutation
  public appendLocation(location: Location) {
    this.selectedCustomTemplate!.locations.push(location);
  }

  @Mutation
  public removeLocation(location: Location) {
    this.selectedCustomTemplate!.locations = this.selectedCustomTemplate!.locations.filter(
      (selected) => selected !== location);
  }

  @Mutation
  public setTargetLanguage(targetLanguage: string) {
    this.selectedCustomTemplate!.language = targetLanguage;
  }

  @Mutation
  public setRuntime(runtime: string) {
    this.selectedCustomTemplate!.runtime = runtime;
  }

  @Mutation
  public setBudget(budget: number) {
    this.selectedCustomTemplate!.customTotalGrossBudget = budget;
  }

  @Mutation
  public setSelectedLeadform(leadform: Leadform) {
    this.selectedLeadform = leadform;
  }

  @Action({commit: 'setModal'})
  public closeActiveModal(): ModalParams {
    return {
      isActive: false,
      currentComponent: null,
      template: null,
      socialChannel: null,
    };
  }

  @Action({commit: 'setModal'})
  public openChangeStatusConfirmModal(data: CustomTemplate | null): ModalParams {
    return {
      isActive: true,
      currentComponent: ChangeTemplateStatusDelete,
      template: data,
      socialChannel: null,
    };
  }

  @Action({commit: 'setSelectedCustomTemplate'})
  public selectCustomTemplate(data: CustomTemplate | null) {
    return data;
  }

  @Action({commit: 'setSelectedCustomTemplate'})
  public async fetchSingleCustomTemplate(id: string): Promise<CustomTemplate> {
    return await CustomTemplateResource.prototype.findById(id);
  }

  @Action({commit: 'setCustomTemplates'})
  public async fetchCustomTemplates(): Promise<CustomTemplate[]> {
    return await CustomTemplateResource.prototype.findAll();
  }

  @Action({commit: 'appendTemplate'})
  public async createCustomTemplate(templateType: ProductType): Promise<CustomTemplate> {
    return await CustomTemplateResource.prototype.createCustomTemplate(templateType);
  }

  @Action({commit: 'removeCustomTemplate'})
  public async deleteCustomTemplate(data: CustomTemplate) {
    await CustomTemplateResource.prototype.deleteCustomTemplate(data);
    return data;
  }

  @Action({commit: 'updateTemplate'})
  public changeCustomTemplateType({template, type}: {template: CustomTemplate, type: ProductType}) {
    return {template, type};
  }

  @Action({commit: 'setSocialPage'})
  public async selectSocialPage(params: {id: string, socialPage: SocialPage}) {
    await CustomTemplateResource.prototype.setSocialPage(params.socialPage, params.id);
    return params.socialPage;
  }

  @Action({commit: 'appendLocation'})
  public addLocation({data, location}: {data: CustomTemplate, location: Location}) {
    CustomTemplateResource.prototype.addLocation(data, location);
    return location;
  }

  @Action({commit: 'removeLocation'})
  public async deleteLocation({data, location}: {data: CustomTemplate, location: Location}) {
    await CustomTemplateResource.prototype.deleteLocation(data, location);
    return location;
  }

  @Action({commit: 'appendJobProfile'})
  public async addProfile({data, profile}: {data: CustomTemplate, profile: JobProfile}) {
    await CustomTemplateResource.prototype.addProfile(data, profile);
    return profile;
  }

  @Action({commit: 'removeJobProfile'})
  public async deleteProfile({data, profile}: {data: CustomTemplate, profile: JobProfile}) {
    await CustomTemplateResource.prototype.deleteProfile(data, profile);
    return profile;
  }

  @Action({commit: 'setSelectedCustomTemplate'})
  public updateCustomTemplateFields(data: any) {
    CustomTemplateResource.prototype.updateCustomTemplateFields(data);
    return data;
  }

  @Action({commit: 'setFunctionGroup'})
  public addFunctionGroup({templateId, functionGroup}: {templateId: string, functionGroup: FunctionGroup}) {
    CustomTemplateResource.prototype.setFunctionGroup(templateId, functionGroup);
    return functionGroup;
  }

  @Action
  public deleteFunctionGroup(templateId: string) {
    CustomTemplateResource.prototype.deleteFunctionGroup(templateId);
    this.context.commit('removeFunctionGroup');
  }

  @Action({commit: 'setBudget'})
  public async updateBudget(budget: number) {
    await CustomTemplateResource.prototype.setBudget(this.templateId, budget)
    .catch(err => handleUnknownError(err.response.data));
    return budget;
  }

  @Action({commit: 'setRuntime'})
  public async updateRuntime(runtimeInput: string | number) {
    await CustomTemplateResource.prototype.setRuntime(this.templateId, runtimeInput);
    return runtimeInput;
  }

  @Action({commit: 'setTargetLanguage'})
  public async updateTargetLanguage(targetLanguage: string) {
    await CustomTemplateResource.prototype.setTargetLanguage(this.templateId, targetLanguage);
    return targetLanguage;
  }

  @Action({commit: 'setSelectedLeadform'})
  public fetchLeadform(): Promise<Leadform> {
    return CustomTemplateResource.prototype.fetchLeadform(_.get(this.selectedCustomTemplate!, 'id'));
  }

  @Action({commit: 'setSelectedLeadform'})
  public async updateLeadform(data: any): Promise<Leadform> {
    return await CustomTemplateResource.prototype.saveLeadform({
      leadform: {...this.selectedLeadform, ...data},
      templateId: _.get(this.selectedCustomTemplate, 'id', ''),
    });
  }
}

function checkIfCustomTemplateIsComplete(template: CustomTemplate) {
  return template.title &&
    template.purpose &&
    template.customTotalGrossBudget &&
    (template.functionGroup || template.profiles.length !== 0) &&
    templateHasAds(template);
}

function templateHasAds(template: CustomTemplate): boolean {
  return template.enabledChannels.facebook 
    || template.enabledChannels.instagram 
    || template.enabledChannels.linkedIn
    || template.enabledChannels.google_display 
    || template.enabledChannels.google_search;
}

export default getModule(CustomTemplates, store);
