import { render, staticRenderFns } from "./MissingChannelModal.vue?vue&type=template&id=80538b76&scoped=true&"
import script from "./MissingChannelModal.vue?vue&type=script&setup=true&lang=ts&"
export * from "./MissingChannelModal.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./MissingChannelModal.vue?vue&type=style&index=0&id=80538b76&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80538b76",
  null
  
)

export default component.exports