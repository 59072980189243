import { defineStore } from 'pinia';

import MasterImageApi from '@/modules/medialibrary/api/masterImage.api';

import adsEditorStore from '@/modules/ads/store/adsEditor.store';
import { useMediaLibraryStore } from '@/modules/medialibrary/store/mediaLibrary.store';
import toastNotificationStore from '@/core/store/toastNotification.store';

import { AdValidation } from '@/modules/ads/models/ads.model';
import {InvalidImage, MasterImage, MasterLogo } from '@/modules/medialibrary/models/masterImage.model';
import { Media, Video } from '@/modules/medialibrary/models/video.model';

async function waitForImageLoad(url: string): Promise<void> {
  return new Promise((resolve, reject) => {
    let tries = 0;
    const MAX_TRIES = 5;
    function tryLoadImage() {
      if (tries >= MAX_TRIES) {
        resolve();
        return;
      }
      const img = new Image();
      img.onload = () => {
        resolve();
      };
      img.onerror = () => {
        tries += 1;
        setTimeout(tryLoadImage, 500);
      };
      img.src = url;
    }
    tryLoadImage();
  });
}

interface State {
  images: MasterImage[];
  logos: MasterLogo[];
}

export const useMasterImageStore = defineStore('masterImageStore', {
  state: (): State => {
    return {
      images: [],
      logos: [],
    }
  },

  getters: {
    currentValidations(): AdValidation | null {
      const mediaLibraryStore = useMediaLibraryStore();
      return mediaLibraryStore.currentValidations;
    },
    validImages(): Media[] {
      if (this.currentValidations?.imageMinWidth) {
        const { imageMinWidth } = this.currentValidations;
        return this.images.filter((image: MasterImage) => imageMinWidth && image.width >= imageMinWidth);
      }
      return this.images;
    },
    invalidImages(): InvalidImage[] {
      const invalidImages = this.images.filter((image) => !this.validImages.includes(image));
      return invalidImages.map((image) => {
        return {
          ...image,
          invalidWidth: !!this.currentValidations!.imageMinWidth && this.currentValidations!.imageMinWidth > image.width,
        };
      });
    },
  },

  actions: {
    async getImages(): Promise<void> {
      this.images = await MasterImageApi.prototype.fetchAllImages();
    },
    async getLogos(): Promise<void> {
      this.logos = await MasterImageApi.prototype.fetchAllLogos();
    },
    async createImage(file: File): Promise<MasterImage | null> {
      const mediaLibraryStore = useMediaLibraryStore();
      mediaLibraryStore.incrementPlaceholderCount();
      const createdImage = await MasterImageApi.prototype.create(file);
      if (!createdImage) {
        return null;
      }
      await waitForImageLoad(createdImage.thumbnailUrl);
      mediaLibraryStore.decrementPlaceholderCount();
      this.images.push(createdImage);
      mediaLibraryStore.validateMedia(createdImage);
      return createdImage;
    },
    async createLogo(image: File): Promise<MasterLogo> {
      const mediaLibraryStore = useMediaLibraryStore();
      mediaLibraryStore.incrementPlaceholderCount();
      const createdLogo = await MasterImageApi.prototype.createLogo(image);
      adsEditorStore.setGoogleCopyLogo(createdLogo);
      mediaLibraryStore.decrementPlaceholderCount();
      this.logos.push(createdLogo);
      return createdLogo; 
    },
    async renameImage(image: MasterImage): Promise<void> {
      try {
        await MasterImageApi.prototype.rename(image);
        toastNotificationStore.showToastNotification({ message: `${image.title} successfully renamed`, isSuccess: true });
      } catch(err) {
        console.log(err);
      }
    },
    validateImage(file: File): boolean {
      const acceptedImageTypes = ['image/jpeg', 'image/png'];
      return file && acceptedImageTypes.includes(file.type);
    },
    validateLogo(file: File): boolean {
      const acceptedLogoTypes = ['image/jpeg', 'image/png']; // 'image/svg+xml' <- svg files to be added
      return file && acceptedLogoTypes.includes(file.type);
    },
    removeImage(imageToRemove: MasterImage): void {
      this.images = this.images.filter((image) => image.id !== imageToRemove.id);
    },
    removeLogo(logoToRemove: MasterLogo): void {
      this.logos = this.logos.filter((logo) => logo.id !== logoToRemove.id);
    },
    async delete(image: MasterImage): Promise<any> {
      const mediaLibraryStore = useMediaLibraryStore();
      return MasterImageApi.prototype.delete(image).then(() => {
        mediaLibraryStore.removeSelectedMedia(image);
        toastNotificationStore.mediaSelectionLength! > 1
          ? toastNotificationStore.showToastNotification(toastNotificationStore.deleteMultipleMediaNotification)
          : toastNotificationStore.showToastNotification({message: `${image.title} successfully deleted`, isSuccess: true });
        if (image.type === 'logo') {
          this.removeLogo(image);
        } else {
          this.removeImage(image);
        }
      }).catch(error => {
        toastNotificationStore.showToastNotification({ message: error.response.data.message, isError: true });
      });
    },
  },
});
