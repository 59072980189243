import { defineStore } from 'pinia'
import socialPagesApi from '@/modules/accounts/api/socialPages.api';
import adsStore from '@/modules/ads/store/ads.store';
import { SocialPage } from '@/modules/accounts/models/socialPage.model';
import { Channel, ChannelWithSocialPage, ChannelId } from '@/modules/ads/models/ads.model';
import { Campaign } from '@/modules/campaigns/models/campaign.model';
import { CustomTemplate } from '@/modules/templates/custom/models/customTemplate.model';
import { DefaultTemplate } from '@/modules/templates/default/models/defaultTemplate.model';

interface State {
  socialPages: SocialPage[];
  searchString: string;
}

export const useSocialPagesStore = defineStore('socialPagesStore', {
  state: (): State => {
    return { 
      socialPages: [],
      searchString: '',
    }
  },
  getters: { 
    channelsWithSocialPage(): Channel[] {
      return this.socialPages.map((page) => page.channel);
    },
    missingSocialPages(): ChannelWithSocialPage[] {
      return adsStore.channelsWithAds
        .filter((channel) => !this.channelsWithSocialPage.includes(channel) && channel !== 'google' && channel !== 'google-search') as ChannelWithSocialPage[];
    },
    hasMissingSocialPages(): boolean {
      return !!this.missingSocialPages.length;
    },
    channelsWithInvalidSocialPage() {
      return (campaign: Campaign): ChannelWithSocialPage[] => {
        const channelsWithSocialPage: ChannelWithSocialPage[] = ['linkedin', 'instagram', 'facebook'];
        return channelsWithSocialPage.filter(channel => !this.isChannelSocialPageValid(channel, campaign));
      };
    },
    isChannelSocialPageValid() {
      return (channel: ChannelWithSocialPage, selectedProduct: Campaign | CustomTemplate | DefaultTemplate): boolean => {
        const hasSocialPageSelected = !!selectedProduct[channelIdByChannel[channel]];
        const isSocialPageValid = this.socialPages.some(page => {
          return selectedProduct
            && page.id === selectedProduct[channelIdByChannel[channel]]
            && page.channel === channel;
        });
        return !hasSocialPageSelected || isSocialPageValid;
      };
    },
    selectedSocialPage() {
      return (channel: ChannelWithSocialPage, selectedProduct: Campaign | CustomTemplate | DefaultTemplate): SocialPage | undefined => {
        return this.socialPages.find((page) => {
          return selectedProduct
            && page.id === selectedProduct[channelIdByChannel[channel]]
            && page.channel === channel;
        });
      };
    },
    filteredSocialPages(): SocialPage[] {
      return this.socialPages.filter((page) => {
        return page.name.toLowerCase().includes(this.searchString.toLowerCase());
      }).sort();
    }
  },
  actions: {
    setSearch(searchString: string) {
      this.searchString = searchString;
    },
    async fetchSocialPages() {
      this.socialPages = await socialPagesApi.prototype.findAll(); 
    },
    clearSearch() {
      this.searchString = '';
    },
    returnSocialPage({id, channel}: {id: string | null | undefined, channel: string}) {
      return this.socialPages.filter((page) => page.channel === channel).find((page) => page.id === id);
    }
  }
});

const channelIdByChannel: Record<ChannelWithSocialPage, ChannelId> = {
  facebook: 'facebookPageId',
  instagram: 'instagramPageId',
  linkedin: 'linkedinPageId',
};

