import { render, staticRenderFns } from "./ErroredCampaigns.vue?vue&type=template&id=c8ffd478&scoped=true&"
import script from "./ErroredCampaigns.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ErroredCampaigns.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ErroredCampaigns.vue?vue&type=style&index=0&id=c8ffd478&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8ffd478",
  null
  
)

export default component.exports