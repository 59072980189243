import { render, staticRenderFns } from "./Campaigns.vue?vue&type=template&id=6e0c7756&scoped=true&"
import script from "./Campaigns.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Campaigns.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Campaigns.vue?vue&type=style&index=0&id=6e0c7756&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e0c7756",
  null
  
)

export default component.exports