import { render, staticRenderFns } from "./FacebookAdInputs.vue?vue&type=template&id=1c8f3ea3&scoped=true&"
import script from "./FacebookAdInputs.vue?vue&type=script&setup=true&lang=ts&"
export * from "./FacebookAdInputs.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c8f3ea3",
  null
  
)

export default component.exports