import { Module, getModule, Action, config, Mutation } from 'vuex-module-decorators';
import { AbstractAds } from '@/modules/template-ads/store/abstractTemplateAds.store';
import store from '@/core/store/index.store';
import { useSocialPagesStore } from '@/modules/accounts/store/socialPages.store';
import templateAdsEditorStore from '@/modules/template-ads/store/templateAdsEditor.store';

import { AbstractResource } from '@/core/api/abstract.api';
import LinkedinPostAdResource, { LinkedinPostAdResponseJson, LinkedinPostAdRequestJson } from '@/modules/template-ads/api/linkedinPostCustom.api';

import { AdMedia } from '@/modules/medialibrary/models/video.model';
import { LinkedinPostAd } from '@/modules/template-ads/models/linkedinAds.model';
import { SocialPage } from '@/modules/accounts/models/socialPage.model';

config.rawError = true;

@Module({
  name: 'linkedinPostCustomTemplates',
  namespaced: true,
  dynamic: true,
  store,
})
class LinkedinPostAds extends AbstractAds<LinkedinPostAd, LinkedinPostAdResponseJson, LinkedinPostAdRequestJson> {
  protected get resource(): AbstractResource<LinkedinPostAd, LinkedinPostAdResponseJson, LinkedinPostAdRequestJson> {
    return LinkedinPostAdResource.prototype;
  }

  public linkedInPage: SocialPage | undefined | object = {};

  get media(): AdMedia[] {
    return templateAdsEditorStore.media.length > 0 ?
      templateAdsEditorStore.media :
      [{ id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'}];
  }
  
  @Mutation
  public setLinkedInPage(page: SocialPage | {} | undefined) {
    this.linkedInPage = page;
  }

  @Action({commit: 'setLinkedInPage'})
  public updateLinkedInPage(id: string | null | undefined) {
    const socialPagesStore = useSocialPagesStore();
    return socialPagesStore.returnSocialPage({id, channel: 'linkedin'});
  }

  @Action
  public async createAds(): Promise<void> {
    for (const media of this.media) {
      const linkedinPostAd: LinkedinPostAd = {
        media,
        ...templateAdsEditorStore.copy,
        status: 'active',
      };
      await this.createAd(linkedinPostAd);
    }
  }
}

export default getModule(LinkedinPostAds, store);
