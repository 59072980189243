import {AbstractResource} from '@/core/api/abstract.api';
import customTemplateStore from '../../templates/custom/store/customTemplates.store';
import {FacebookPostAd} from '../models/facebookAds.model';
import {MediaType} from '@/modules/medialibrary/models/masterImage.model';
import { hydrateAdMedia, AdMediaJson } from '@/modules/ads/api/apiHelpers';

export default class FacebookPostAdResource extends AbstractResource<
  FacebookPostAd,
  FacebookPostAdResponseJson,
  FacebookPostAdRequestJson
  > {

    protected get resourceName(): string {
      return 'facebook-post-templates';
    }

    protected get resourceOwner(): string {
      if (!customTemplateStore.selectedCustomTemplate) {
        throw new Error('Trying to perform ad related actions without a selected template');
      }
      return `customTemplates/${customTemplateStore.selectedCustomTemplate.id}`;
    }

    protected extract(model: FacebookPostAd): FacebookPostAdRequestJson {
      return {
        media: {
          id: model.media.id,
          type: model.media.type,
        },
        message: model.message,
        headline: model.headline,
        linkDescription: model.linkDescription,
        callToAction: model.callToAction,
      };
    }

    protected hydrate(json: FacebookPostAdResponseJson): FacebookPostAd {
      return {
        id: String(json.id),
        media: hydrateAdMedia(json.media),
        message: json.message,
        headline: json.headline,
        linkDescription: json.linkDescription,
        callToAction: json.callToAction,
      };
    }
  }

export interface FacebookPostAdResponseJson {
  id: string;
  media: AdMediaJson;
  message: string;
  headline: string;
  linkDescription: string;
  callToAction: string;
}

export interface FacebookPostAdRequestJson {
  media: {
    id: string;
    type: MediaType;
  };
  message: string;
  headline: string;
  linkDescription: string;
  callToAction: string;
}
