import { render, staticRenderFns } from "./MasterAccount.vue?vue&type=template&id=a8b7a296&scoped=true&"
import script from "./MasterAccount.vue?vue&type=script&setup=true&lang=ts&"
export * from "./MasterAccount.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./MasterAccount.vue?vue&type=style&index=0&id=a8b7a296&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8b7a296",
  null
  
)

export default component.exports