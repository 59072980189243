import { defineStore } from 'pinia'
import FinanceApi from '@/modules/settings/api/finance.api';
import {
  Balance,
  BalanceOverviewRecipient,
  CampaignSpend,
  Correction,
  Deposit,
  FinanceSettings,
} from '@/modules/settings/models/finance.model';

interface State {
  financeSettings: FinanceSettings | null;
  corrections: Correction[];
  deposits: Deposit[];
  balanceOverviewRecipients: BalanceOverviewRecipient[];
  campaignSpends: CampaignSpend[];
  balance: Balance | null;
}

export const useFinanceStore = defineStore('finance', {
  state: (): State => {
    return { 
      financeSettings: null,
      corrections: [],
      deposits: [],
      balanceOverviewRecipients: [],
      campaignSpends: [],
      balance: null,
    }
  },
  actions: {
    async addBalanceOverviewRecipient(recipient: BalanceOverviewRecipient) {
      await FinanceApi.prototype.addBalanceOverviewRecipient(recipient)
      this.balanceOverviewRecipients.push(recipient);
    },
    async fetchBalance() {
      const balance = await FinanceApi.prototype.getBalance();
      this.balance = balance;
      return balance;
    },
    async refresh() {
      const promises = [
        FinanceApi.prototype.getFinanceSettings(),
        FinanceApi.prototype.getCorrections(),
        FinanceApi.prototype.getDeposits(),
        FinanceApi.prototype.getCampaignSpends(),
        FinanceApi.prototype.getBalance(),
        FinanceApi.prototype.getBalanceOverviewRecipients()
      ]
      const [ settings, corrections, deposits, campaignSpends, balance, recipients ] = await Promise.all(promises);
      this.financeSettings = settings as FinanceSettings;
      this.corrections = corrections as Correction[];
      this.deposits = deposits as Deposit[];
      this.campaignSpends = campaignSpends as CampaignSpend[];
      this.balance = balance as Balance;
      this.balanceOverviewRecipients = recipients as BalanceOverviewRecipient[];
    }
  },
});