var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('main',{staticClass:"wrapper",class:{ loading: _vm.loading },attrs:{"mode":"in-out"}},[(_vm.label && _setup.ads.length)?_c('div',{staticClass:"ad-type-label"},[_vm._v(" "+_vm._s(_vm.label)+" "),_c('span',[_vm._v(" ["+_vm._s(_setup.adsCount)+" ads] "),(_vm.adType.includes('GoogleDisplay') && _setup.adsCount > 9)?_c('span',[_c('span',{staticClass:"minimise",on:{"click":function($event){return _setup.changeGoogleDisplayLength()}}},[_c('img',{attrs:{"src":require(`@/core/assets/icons/${
                _setup.minimiseGoogleAds ? 'maximise' : 'minimise'
              }.png`),"height":"14px","width":"14px"}})])]):_vm._e()])]):_vm._e(),_c('transition-group',{staticClass:"ads-row",class:{
      'google-display': _vm.adType.includes('GoogleDisplay'),
      'generated-banners': _vm.adType.includes('GoogleDisplayGeneratedAd'),
    },attrs:{"name":"ads-in-row"}},[_vm._l((_setup.ads),function(ad){return _c('div',{key:ad.id,staticClass:"ad",class:{ 'new-ad': _setup.isNewAd(ad) },style:({ gridColumn: _setup.googleAdStyling(ad) })},[_c(_setup.AdActionBar,{attrs:{"ad-type":_vm.adType,"ad":ad}}),_vm._t("adPreview",null,{"ad":{ ...ad }}),(!_vm.$scopedSlots.adPreview)?_c(_setup.adConfig.view,{tag:"component",attrs:{"ad":ad,"cropping-disabled":true}}):_vm._e()],2)}),(_setup.showPlaceholders)?_c('div',{key:"placeholder-1",staticClass:"ad placeholder"}):_vm._e(),(_setup.showPlaceholders)?_c('div',{key:"placeholder-2",staticClass:"ad placeholder"}):_vm._e(),(_setup.showPlaceholders)?_c('div',{key:"placeholder-3",staticClass:"ad placeholder"}):_vm._e(),(_setup.showGooglePlaceholder)?_c('div',{key:"googleplaceholder-1",staticClass:"google-placeholder-container"},_vm._l((_setup.googlePlaceholders),function(placeholder){return _c('div',{key:placeholder.id,staticClass:"google-placeholder",style:({
          width: `${placeholder.width}px`,
          height: `${placeholder.height}px`,
          gridColumn: _setup.googleAdStyling(placeholder),
        })})}),0):_vm._e(),(_setup.showGoogleSearchPlaceholder)?_c('div',{key:"googlesearch-placeholder-1",staticClass:"google-search-placeholder-container"},_vm._l((_setup.googleSearchPlaceholders),function(placeholder,index){return _c('div',{key:index,staticClass:"google-placeholder",style:({ width: `${placeholder.width}px`, height: `${placeholder.height}px` })})}),0):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }