import { render, staticRenderFns } from "./EmojiSelect.vue?vue&type=template&id=1098a19c&scoped=true&"
import script from "./EmojiSelect.vue?vue&type=script&setup=true&lang=ts&"
export * from "./EmojiSelect.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./EmojiSelect.vue?vue&type=style&index=0&id=1098a19c&prod&scoped=true&lang=css&"
import style1 from "./EmojiSelect.vue?vue&type=style&index=1&id=1098a19c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1098a19c",
  null
  
)

export default component.exports