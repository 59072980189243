export class User {

    public static fromAuth0User(auth0User: { [key: string]: string }): User {
        return new User(
            null,
            auth0User.name,
            auth0User.given_name,
            auth0User.family_name,
            auth0User.email,
            auth0User.updated_at,
        );
    }

    public static fromLocalUser(localUser: { [key: string]: string }): User {
        return new User(
            localUser.id,
            localUser.name,
            localUser.first_name,
            localUser.last_name,
            localUser.email,
            localUser.updated_at,
        );
    }

    constructor(
        readonly id: string | null,
        readonly name: string,
        readonly firstName: string,
        readonly lastName: string,
        readonly email: string,
        readonly updatedAt: string,
    ) {
    }
}
