import { defineStore } from 'pinia';

import { GoogleSearchAd } from '../../models/googleSearchAds.model';
import { GoogleSearchCopy } from '../templateAdsEditor.store';
import GoogleSearchApi from '../../api/googleSearchCustom.api';

interface State {
  ads: GoogleSearchAd[];
  newCreatedAds: GoogleSearchAd[];
}

export const useGoogleSearchCustomStore = defineStore('GoogleSearchTemplatesCustom', {
  state: (): State => {
    return {
      ads: [],
      newCreatedAds: [],
    }
  },
  actions: {
    async create(googleSearchAd: GoogleSearchCopy) {
      const createdAd = await GoogleSearchApi.prototype.create(googleSearchAd);
      this.ads = [...this.ads, createdAd as GoogleSearchAd]; 
      return createdAd;
    },
    async fetchAds(): Promise<GoogleSearchAd[]> {
      const ads = await GoogleSearchApi.prototype.findAll();
      this.ads = ads;
      return ads;
    },
    async deleteAd(removingAd: GoogleSearchAd) {
      await GoogleSearchApi.prototype.delete(removingAd);
      this.ads = this.ads.filter((ad) => removingAd.id !== ad.id);
      return removingAd;
    },
    async updateAd(ad: GoogleSearchAd) {
      const editedAd = await GoogleSearchApi.prototype.update(ad);

      this.ads = this.ads.map((ad) => {
        if (ad.id === editedAd.id) {
          return editedAd;
        }
        return ad;
      });

      return editedAd;
    },
    emptyNewCreatedAds() { this.newCreatedAds = []; }
  }
})
