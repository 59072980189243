import httpService from '@/core/plugins/httpService';
import campaignStore from '../../campaigns/store/campaigns.store';
import toastNotificationStore from '@/core/store/toastNotification.store';
import {GoogleDisplayGeneratedAd, GoogleDisplayGeneratedAdParams} from '../models/googleAds.model';
import { handleResponseError} from '@/core/assets/global-utils';

export default class GoogleDisplayGeneratedAdsResource {
  protected get resourceName() {
    if (!campaignStore.selectedCampaign) {
      throw new Error('Trying to actions without a selected campaign.');
    }
    return `campaigns/${campaignStore.selectedCampaign.id}/google-banner-ads`;
  }

  protected get endpoint(): string {
    return '/' + this.resourceName;
  }

  public findAll(): Promise<GoogleDisplayGeneratedAd[] | void> {
    return httpService.get(this.endpoint)
      .then((response) => this.hydrateCollection(response.data as GoogleBannerGeneratedAdResponseJson[]))
      .catch((error) => {
        handleResponseError(error);
      });
  }

  public create(adParams: GoogleDisplayGeneratedAdParams): Promise<GoogleDisplayGeneratedAd | void> {
    return httpService.post( this.endpoint, this.extract(adParams) )
      .then((response) => this.hydrate(response.data as GoogleBannerGeneratedAdResponseJson))
      .catch((error) => {
        toastNotificationStore.showToastNotification({
          message: `Failed to generate ads. (${error.response.status}: ${error.response.statusText})`,
          isError: true,
        });
      });
  }

  public update(ad: GoogleDisplayGeneratedAd): Promise<GoogleDisplayGeneratedAd | void> {
    return httpService.put('/google-banner-ads/' + ad.id, this.extract(ad))
      .then((response) => this.hydrate(response.data as GoogleBannerGeneratedAdResponseJson))
      .catch((error) => {
        handleResponseError(error);
      });
  }

  public delete(ad: GoogleDisplayGeneratedAd) {
    return httpService.delete('/google-banner-ads/' + ad.id);
  }

  protected hydrate(json: GoogleBannerGeneratedAdResponseJson): GoogleDisplayGeneratedAd {
    return {
      id: json.id,
      type: json.type,
      subtitle: json.intro,
      title: json.title,
      callToAction: json.call_to_action,
      tags: json.tags,
      primaryColor: json.background_color,
      logoUrl: json.logo_url,
    };
  }

  protected hydrateCollection(responseCollection: GoogleBannerGeneratedAdResponseJson[]): GoogleDisplayGeneratedAd[] {
    return responseCollection.map((response) => {
      return {
        ...this.hydrate(response),
        type: response.ad_type,
      };
    });
  }

  protected extract(model: GoogleDisplayGeneratedAdParams): GoogleBannerGeneratedAdRequestJson {
    const { type, subtitle, title, callToAction, tags, primaryColor, logoUrl } = model;
    return {
      type,
      intro: subtitle,
      title,
      call_to_action: callToAction,
      tags,
      background_color: primaryColor,
      logo_url: logoUrl,
    };
  }
}

interface GoogleBannerGeneratedAdResponseJson {
  background_color: string;
  call_to_action: string;
  id: number;
  intro: string;
  logo_url: string;
  tags: string[];
  title: string;
  ad_type: string;
  type: string;
}

interface GoogleBannerGeneratedAdRequestJson {
  background_color: string;
  call_to_action: string;
  intro: string;
  logo_url: string;
  tags: string[];
  title: string;
  type: string;
}
