import { getAdConfig } from '../index';
import { googleSearchValidation } from '@/core/validations/ads/channels';
import { GoogleSearchAd } from '../models/googleSearch.model';
import {
  facebookCarouselValidation, facebookPostValidation,
  instagramCarouselValidation, instagramPostValidation,
  linkedinPostValidation,
} from '@/core/validations/ads/channels';
import {
  FacebookCarouselAd,
  FacebookPostAd,
} from '../models/facebookAds.model';
import {
  InstagramCarouselAd,
  InstagramPostAd,
} from '../models/instagramAds.model';
import { LinkedinPostAd } from '../models/linkedinAds.model';
import { GoogleDisplayGeneratedAd } from '../models/googleAds.model';

const adsValidation = [
  {
    validator: hasIncorrectFacebookPostAd,
    message:
      'A Facebook Post ad has a character length exceeding the limit. Please correct to continue.',
  },
  {
    validator: hasIncorrectFacebookCarouselAd,
    message:
      'A Facebook Carousel ad has a character length exceeding the limit. Please correct to continue.',
  },
  {
    validator: hasIncorrectInstagramPostAd,
    message:
      'An Instagram Post ad has a character length exceeding the limit. Please correct to continue.',
  },
  {
    validator: hasIncorrectInstagramCarouselAd,
    message:
      'An Instagram Carousel ad has a character length exceeding the limit. Please correct to continue.',
  },
  {
    validator: hasIncorrectLinkedinPostAd,
    message:
      'A LinkedIn Post ad has a character length exceeding the limit. Please correct to continue.',
  },
  {
    validator: hasIncorrectGoogleSearchAd,
    message:
      'A Google Search ad has a character length exceeding the limit. Please correct to continue.',
  },
  {
    validator: hasIncorrectGoogleDisplayGeneratedAd,
    message:
      'A Google Display ad is missing an image. Please correct to continue.',
  },
];

export function hasIncorrectAd(): boolean {
  return adsValidation.some(({ validator }) => {
    return validator();
  });
}

export function getIncorrectAdMessage(): string {
  const ad = adsValidation.find(({ validator, message }) => validator());
  return ad ? ad.message : '';
}

function hasIncorrectGoogleSearchAd() {
  const { headlineCharacterLimit, descriptionCharacterLimit, linkPathCharacterLimit } = googleSearchValidation;
  const ads = getAdConfig('GoogleSearchAd').store.ads;
  return ads.some((ad: GoogleSearchAd) => {
    return ad.headlines.some((headline) => headline.length > headlineCharacterLimit)
      || ad.descriptions.some((description) => description.length > descriptionCharacterLimit);
  });
}

function hasIncorrectGoogleDisplayGeneratedAd() {
  const ads = getAdConfig('GoogleDisplayGeneratedAd').store.ads;
  return ads.some((ad: GoogleDisplayGeneratedAd) => {
    return !ad.logoUrl;
  });
}

export function checkIfAdsHaveSameCTA() {
  const facebokPostAds = getAdConfig('FacebookPostAd').store.ads;
  const facebookCarouselAd = getAdConfig('FacebookCarouselAd').store.ads;
  const instagramPostAd = getAdConfig('InstagramPostAd').store.ads;
  const instagramCarouselAd = getAdConfig('InstagramCarouselAd').store.ads;
  const ads = [...facebokPostAds, ...facebookCarouselAd, ...instagramPostAd, ...instagramCarouselAd];
  const result = ads.every((ad) => {
    return ad.callToAction === ads[0].callToAction;
  });

    return result;
}

function hasIncorrectFacebookPostAd() {
  const { message, headline, linkDescription } = facebookPostValidation;
  const ads = getAdConfig('FacebookPostAd').store.ads;
  return ads.some((ad: FacebookPostAd) => {
    return (
      ad.message.length > message.error ||
      ad.headline.length > headline.error ||
      ad.linkDescription.length > linkDescription.error
    );
  });
}

function hasIncorrectFacebookCarouselAd() {
  const { message, headline, linkDescription } = facebookCarouselValidation;
  const ads = getAdConfig('FacebookCarouselAd').store.ads;
  return ads.some((ad: FacebookCarouselAd) => {
    return (
      ad.message.length > message.error ||
      ad.headline.length > headline.error ||
      ad.linkDescription.length > linkDescription.error
    );
  });
}

function hasIncorrectInstagramPostAd() {
  const { message } = instagramPostValidation;
  const ads = getAdConfig('InstagramPostAd').store.ads;
  return ads.some((ad: InstagramPostAd) => {
    return ad.message.length > message.error;
  });
}

function hasIncorrectInstagramCarouselAd() {
  const { message } = instagramCarouselValidation;
  const ads = getAdConfig('InstagramCarouselAd').store.ads;
  return ads.some((ad: InstagramCarouselAd) => {
    return ad.message.length > message.error;
  });
}

function hasIncorrectLinkedinPostAd() {
  const { message, headline, linkDescription } = linkedinPostValidation;
  const ads = getAdConfig('LinkedinPostAd').store.ads;
  return ads.some((ad: LinkedinPostAd) => {
    return (
      ad.message.length > message.error ||
      ad.headline.length > headline.error ||
      ad.linkDescription.length > linkDescription.error
    );
  });
}
