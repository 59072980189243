<template>
  <div class="ui-page">
    <h2>UI components guideline</h2>
    <div class="row">
      <div class="col-2 left-col">
        <div class="sticky-col">
          States:
          <checkbox v-model="disabled">Disabled</checkbox>
          <checkbox v-model="hasError">Error</checkbox>
          <checkbox v-model="hasWarning">Warning</checkbox>
          <br>References:<br>
          <a href="https://getbootstrap.com/docs/5.1/layout/grid/">bootstrap grid</a><br>
          <a href="https://getbootstrap.com/docs/5.1/utilities/spacing/">bootstrap spacing</a><br>
          <br>Colors<br>
          <span v-for="color in colors" class="circle" :key="color" :style="{ background: color }">{{ color }}</span><br>
          <br>Tags<br>
          <div class="tags">
            <tag type="red">tag</tag>
            <tag type="blue">tag</tag>
            <tag type="orange">tag</tag>
            <tag type="gray">tag</tag>
            <tag type="purple">tag</tag>
            <tag type="green">tag</tag>
            <tag type="success">tag</tag>
            <tag editable>editable</tag>
          </div>
          <br>Typography<br>
          <h1>H1 header</h1>
          <div class="h1-subtitle">H1 subtitle</div>
          <h2>H2 header</h2>
          <div class="h2-subtitle">H2 subtitle</div>
          <h3>H3 header</h3>
          <div class="h3-subtitle">H3 subtitle</div>
          <h4>h4 header</h4>
          <div class="h4-subtitle">H4 subtitle</div>
          <h5>h5 header</h5>
          <div class="h5-subtitle">H5 subtitle</div>
          <p>Paragraph</p>
          <div class="_section-title">Section title</div>
          <div class="caption">Caption</div>
          <div class="caption-sm">Caption sm</div>
        </div>
      </div>

      <div class="col">
        <div class="row m-3">
          <div class="col">
            <h2>Create Workspace Modal</h2>
            <button @click="workspaceModalIsOpen = true">Create New Workspace</button>
            <create-workspace-modal v-if="workspaceModalIsOpen" @close="workspaceModalIsOpen = false"/>
          </div>
          <div class="col">
            <button @click="showWorkspaceCretedModal">workspace created modal</button>
          </div>
          <div class="col">
            <button @click="showAccountCretedModal">account created modal</button>
          </div>          
          <div class="col">
            <button @click="addAccountUser">open user account modal</button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h2>Profile Image Cropper</h2>
            <cropper-profileImage :default-image="croppingDefaultImage" @output="setCroppedFile"/>
          </div>
          <div class="col">
            <h2>Account Managing Modal</h2>
            <button @click="createAccountModalOpen = true">Open Account Managing Modal</button>
            <create-account-modal v-if="createAccountModalOpen" @close="createAccountModalOpen = false" @confirm="consoleLogData"/>
          </div>
          <div class="col">
            <h2>Master Account Modal</h2>
            <button @click="masterAccountModalOpen = true">Open Master Account Modal</button>
            <create-master-account-modal v-if="masterAccountModalOpen" @close="masterAccountModalOpen = false" @confirm="consoleLogData"/>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h2>Master account page</h2>
            <master-account style="height: fit-content"/>
          </div>
        </div>

        <div class="col-6">
          <h2>Profile Image Cropper</h2>
          <cropper-profileImage :default-image="croppingDefaultImage" @output="setCroppedFile"/>
        </div>

        <div class="row my-3">
          <div class="col">
            <h2>Text input</h2>
            <text-input v-model="textInput" placeholder="Enter text" :disabled="disabled" :error="hasError ? 'error msg' : ''" has-emoji-select :max-length="15">
              Text input with emoji and maxlength
            </text-input>
            value {{ textInput }}
          </div>
          <div class="col">
            <h2>Number input</h2>
            <text-input v-model="numberInput" type="number" placeholder="Enter number" :disabled="disabled" :error="hasError ? 'error msg' : ''">
              Number input
            </text-input>
            value {{ numberInput }}
          </div>
        </div>
        <div class="row my-3">
          <div class="col">
            <h2>Tabs</h2>
            <tabs v-model="activeTab" :items="tabItems" class="mb-3"/>
            <tabs-content :value="activeTab" :items="tabItems">
              <template #default="{ item }">
                {{ item.title }}
              </template>
            </tabs-content>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h2>Textarea</h2>
            <textarea-input v-model="textareaInput" placeholder="Enter text" :disabled="disabled" :error="hasError ? 'error msg' : ''" has-emoji-select :max-length="150" :warn-length="10" :warn-linebreaks="2">
              Text area with emoji and maxlength
            </textarea-input>
            value {{ textareaInput }}
          </div>
          <div class="col">
            <h2>Currency input</h2>
            <currency-input is-integer class="d-inline-flex me-4" placeholder="Spent" v-model="currencyInput" :disabled="disabled" :error="hasError ? 'error message' : ''" title="integer input" />
            <currency-input class="d-inline-flex me-4" placeholder="0,00" v-model="currencyInput" :disabled="disabled" :error="hasError ? 'error message' : ''" title="float input" />
            <currency-input is-integer class="d-inline-flex mb-4" size="sm" v-model="currencyInput" :disabled="disabled" :error="hasError ? 'error message' : ''" title="sm input" /><br>
            value {{ currencyInput }}
          </div>
        </div>

        <div class="row my-3">
          <h2>Dropdown with search</h2>

          <div class="col">
            <dropdown title="With default items" v-model="selectedOption1" :options="options" labelKey="channel" :disabled="disabled" placeholder="Select channel" clearable :warning="hasWarning ? 'warning msg' : ''" />
            selectedOption {{ selectedOption1 }}
          </div>

          <div class="col">
            <dropdown title="With custom item template" v-model="selectedOption2" :options="options" labelKey="channel" placeholder="My placeholder" :disabled="disabled" :warning="hasWarning ? 'warning msg' : ''">
              <template v-slot:option="{option}">
                <div class="option">
                  {{ option.channel }}
                  <component :is="logos" v-bind="{ [option.channel]: true }" />
                </div>
              </template>

              <template v-slot:list-header>
                <div>
                  <button>btn1</button>
                  <button>btn2</button>
                </div>
              </template>
            </dropdown>
            selectedOption {{ selectedOption2 }}
          </div>
        </div>

        <div class="row my-3">
          <div class="col">
            <h2>Checkbox</h2>

            <checkbox v-model="cbValue" :disabled="disabled">Default checkbox</checkbox>
            <checkbox v-model="cbValue2" :disabled="disabled" is-heading>Heading checkbox</checkbox>
          </div>

          <div class="col">
            <h2>Radio</h2>

            <radio nanme="radio" v-model="radioValue" :disabled="disabled" option="1">Option 1</radio>
            <radio nanme="radio" v-model="radioValue" :disabled="disabled" option="2">Option 2</radio>
            value {{ radioValue }}
          </div>

          <div class="col">
            <h2>Action sheet</h2>

            <action-sheet :horizontal-offset="-15" :vertical-offset="10">
              <template #clickable>
                <more-button />
              </template>

              <template #actions>
                <div>option #1</div>
                <div>option #2</div>
                <div>option #3</div>
              </template>
            </action-sheet>
          </div>
        </div>

        <div class="row my-3">
          <div class="col">
            <h2>Tooltip</h2>
            top-left
            <form-tool-tip :horizontal-offset="-135" :vertical-offset="28" cornerLocation="top-left" :has-error="hasError">
              <template #hoverable><img src="@/core/assets/icons/info-tooltip.svg" class="tooltip-icon"></template>
              <template #tool-tip>The text of the tooltip</template>
            </form-tool-tip>
            <span class="ms-3" />top-center
            <form-tool-tip :horizontal-offset="-71" :vertical-offset="28" cornerLocation="top-center" :has-error="hasError">
              <template #hoverable><img src="@/core/assets/icons/info-tooltip.svg" class="tooltip-icon"></template>
              <template #tool-tip>The text of the tooltip</template>
            </form-tool-tip>
            <span class="ms-3" />top-right
            <form-tool-tip :horizontal-offset="-12" :vertical-offset="28" cornerLocation="top-right" :has-error="hasError">
              <template #hoverable><img src="@/core/assets/icons/info-tooltip.svg" class="tooltip-icon"></template>
              <template #tool-tip>The text of the tooltip</template>
            </form-tool-tip>
            <span class="ms-3" />bottom-left
            <form-tool-tip :horizontal-offset="-135" :vertical-offset="28" cornerLocation="bottom-left" :has-error="hasError">
              <template #hoverable><img src="@/core/assets/icons/info-tooltip.svg" class="tooltip-icon"></template>
              <template #tool-tip>The text of the tooltip</template>
            </form-tool-tip>
            <span class="ms-3" />bottom-center
            <form-tool-tip :horizontal-offset="-72" :vertical-offset="28" cornerLocation="bottom-center" :has-error="hasError">
              <template #hoverable><img src="@/core/assets/icons/info-tooltip.svg" class="tooltip-icon"></template>
              <template #tool-tip>The text of the tooltip</template>
            </form-tool-tip>
            <span class="ms-3" />bottom-right
            <form-tool-tip :horizontal-offset="-13" :vertical-offset="28" cornerLocation="bottom-right" :hasError="hasError">
              <template #hoverable><img src="@/core/assets/icons/info-tooltip.svg" class="tooltip-icon"></template>
              <template #tool-tip>The text of the tooltip</template>
            </form-tool-tip>
          </div>
        </div>

        <div class="row my-3">
          <div class="col">
            <h2>Type card</h2>

            <goal-card :goalObject="{ header: 'Employer brand awareness', tag: 'awareness', target: 'potential candidates that fit specific profile', goal: 'impressions', type: 'custom'}" :disabled="disabled" />
          </div>
        </div>

        <div class="row my-3">
          <div class="col">
            <h2>Collapsable</h2>
            <collapsable :disabled="disabled">
              <template #header>Header content</template>
              <template #content>Body content</template>
            </collapsable>
          </div>
          <div class="col">
            <h2>Accordion</h2>
            <accordion :items="[{slot: 'intro', header: 'Header 1', content: 'Body 1'}, {slot: 'form', header: 'Header 2', content: 'Body 2'}, {slot: 'settings', header: 'Header 3', content: 'Body 3'}]">
              <template #intro-header>Intro header</template>
              <template #intro-content>Intro body</template>
              <template #form-header>Overwritten form header content</template>
              <template #form-content>Overwritten form body content</template>
              <template #settings-header>Overwritten header content</template>
              <template #settings-content>Overwritten body content</template>
            </accordion>
          </div>
        </div>

        <div class="row my-3">
          <div class="col">
            <h2>Date selector</h2>
            <date-selector :disabled="disabled" title="Im title" />
          </div>

          <div class="col">
            <h2>Date range selector</h2>
            <date-range-selector v-model="dateRange" :disabled="disabled" :error="hasError ? 'error message' : ''" />
          </div>
        </div>

        <div class="row my-3">
          <h2>Alerts</h2>
          <div class="col"><alert type="info">This is info alert.</alert></div>
          <div class="col"><alert type="info">This is info alert with a bit longer content and a <a href="#">link</a>.</alert></div>
        </div>

        <div class="row my-3">
          <h2>Links</h2>
          <a href="/">This is a link</a>
        </div>

        <div class="row my-3">
          <div class="col">
            <h2>Toggle</h2>

            <switcher v-model="isToggled" :disabled="disabled" />
            <p>value {{ isToggled }}</p>
          </div>

          <div class="col-5">
            <h2>Channel budget toggle</h2>

            <channel-budget-toggle :budget="budgetValue" @budgetInput="v => this.budgetValue = v" v-model="isChannelBudgetToggleEnabled" :error="hasError ? 'some error goes here' : ''" :disabled="disabled">
              <span>Something here</span>
            </channel-budget-toggle>
            <p>isEnabled: {{ isChannelBudgetToggleEnabled }}, budget: {{ budgetValue }}</p>
          </div>
        </div>

        <div class="row my-3">
          <div class="col buttons">
            <h2>Buttons</h2>
            <button class="btn-primary btn-sm" :disabled="disabled">primary sm</button>
            <button class="btn-primary btn-md" :disabled="disabled">primary md</button>
            <button class="btn-primary btn-lg" :disabled="disabled">primary lg</button>
            <button class="btn-primary btn-lg" :disabled="disabled">
              <img src="@/core/assets/icons/edit-icon.svg"/> with logo lg
            </button>
            <a href="/ui" class="btn-primary btn-lg">its a link</a><br />
            <button class="btn-secondary btn-sm" :disabled="disabled">secondary sm</button>
            <button class="btn-secondary btn-md" :disabled="disabled">secondary md</button>
            <button class="btn-secondary btn-lg" :disabled="disabled">secondary lg</button>
            <button class="btn-secondary btn-lg" :disabled="disabled">
              <channel-logos class="me-2" facebook /> with logo lg
            </button>
            <a href="/ui" class="btn-secondary btn-lg">its a link</a><br />
            <button class="btn-tertiary btn-sm" :disabled="disabled">sm</button>
            <button class="btn-tertiary btn-md" :disabled="disabled">md</button>
            <button class="btn-tertiary btn-lg" :disabled="disabled">lg</button>
            <button class="btn-tertiary btn-lg" :disabled="disabled">
              <img src="@/core/assets/icons/edit-icon.svg"/>
            </button>
            <a href="/ui" class="btn-tertiary btn-lg">link</a><br />
            <button class="btn-quaternary btn-sm" :disabled="disabled">quaternary sm</button>
            <button class="btn-quaternary btn-md" :disabled="disabled">quaternary md</button>
            <button class="btn-quaternary btn-lg" :disabled="disabled">quaternary lg</button>
            <button class="btn-quaternary btn-lg" :disabled="disabled">
              <img src="@/core/assets/icons/edit-icon.svg"/> with logo lg
            </button>
            <a href="/ui" class="btn-quaternary btn-lg">its a link</a><br />
            <validation-button :error="hasError ? 'validation error on hover' : ''" :disabled="disabled">
              validation btn will have tooltip on error
            </validation-button>
          </div>
        </div>

        <div class="row my-3">
          <div class="col">
            <h2>Channel logos</h2>
            <channel-logos facebook googleDisplay /><br />
            <channel-logos facebook instagram linkedIn googleSearch googleDisplay /><br />
          </div>

          <div class="col">
            <h2>Loading indicator</h2>
            light: <loading-icon class="mx-3" />
            dark: <loading-icon dark class="mx-3" />
          </div>

          <div class="col">
            <h2>Toasts</h2>
            <button class="btn-primary btn-sm" @click="showNotification({isError: true, message: 'Error msg'})">Error</button>
            <button class="btn-primary btn-sm" @click="showNotification({message: 'Long info message text that is placed inside info toast to test how it looks like'})">Long info</button>
            <button class="btn-primary btn-sm" @click="showNotification({isSuccess: true, message: 'Success msg'})">Success</button>
            <button class="btn-primary btn-sm" @click="showNotification({isWarning: true, message: 'Long_warning_message_without_spaces_that_is_placed_inside_warning_toast_to_test_how_it_looks_like_and_how_word_braking_is_working'})">Long warning</button>
          </div>
        </div>

        <div class="row my-3">
          <div class="col modal-wrapper">
            <h2>Modal</h2>
            <base-modal :size="isMdSize ? 'md' : 'sm'" :layout="isHorizontalModal ? 'horizontal' : 'vertical'">
              <template #image>
                <div :style="{background: 'lightgray'}">Image goes here</div>
              </template>
              <template #content>
                <div :style="{background: 'lightgray', padding: '10px'}">
                  Content goes here
                  <checkbox v-model="isMdSize">md size</checkbox>
                  <checkbox v-model="isHorizontalModal">horizontal layout</checkbox>
                </div>
              </template>
            </base-modal>
          </div>
        </div>
      </div>
    </div>
    <add-account-user-modal v-if="addAccountUserModalIsOpen" @close="closeAccountUserModal"/>
    <account-created-modal v-if="accountCreatedModalIsOpen" :data="modalData"  @close="closeAccountCreatedModal"/>
    <workspace-created-modal v-if="workspaceCreatedModalIsOpen"  @close="closeWorkspaceCreatedModal"/>
  </div>
</template>

<script setup>
import { withDefaults, defineProps, ref, computed, onMounted } from 'vue';

import { getDateWithTimezone } from '@/core/plugins/date';
import { showNotification } from '@/core/assets/global-utils';
import Alert from '@/core/components/Alert.vue';
import AddAccountUserModal from '@/modules/accounts/components/modals/AddAccountUserModal.vue';
import AccountCreatedModal from '@/modules/accounts/components/modals/AccountCreatedModal.vue';
import WorkspaceCreatedModal from '@/modules/accounts/components/modals/WorkspaceCreatedModal.vue';
import Dropdown from '@/core/components/forms/Dropdown.vue';
import Checkbox from '@/core/components/forms/Checkbox.vue';
import Radio from '@/core/components/forms/Radio.vue';
import ValidationButton from '@/core/components/forms/ValidationButton.vue';
import ChannelLogos from '@/core/components/ChannelLogos.vue';
import GoalCard from '@/modules/campaigns/components/GoalCard.vue';
import Collapsable from '@/core/components/Collapsable.vue';
import ChannelBudgetToggle from '@/core/components/ChannelBudgetToggle.vue';
import Switcher from '@/core/components/Switcher.vue';
import FormToolTip from '@/core/components/FormToolTip.vue';
import Accordion from '@/core/components/Accordion.vue';
import ActionSheet from '@/core/components/ActionSheet.vue';
import MoreButton from '@/core/components/MoreButton.vue';
import TextInput from '@/core/components/forms/TextInput.vue';
import TextareaInput from '@/core/components/forms/TextareaInput.vue';
import DateRangeSelector from '@/core/components/DateRangeSelector.vue';
import DateSelector from '@/core/components/DateSelector.vue';
import Tag from '@/core/components/Tag.vue';
import CurrencyInput from '@/core/components/forms/CurrencyInput.vue';
import LoadingIcon from '@/core/components/LoadingIcon.vue';
import BaseModal from '@/core/components/BaseModal.vue';
import CreateWorkspaceModal from '@/modules/accounts/components/modals/CreateWorkspaceModal.vue';
import CreateMasterAccountModal from '@/modules/accounts/components/modals/CreateMasterAccountModal.vue';
import CreateAccountModal from '@/modules/accounts/components/modals/CreateAccountModal.vue';
import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import CropperProfileImage from '@/core/components/CropperProfileImage.vue';
import MasterAccount from '@/modules/accounts/pages/MasterAccount.vue';
import Tabs from '@/core/components/Tabs.vue';
import TabsContent from '@/core/components/TabsContent.vue';
import { formatDate } from '@/core/plugins/date';

const accountStore = useAccountsStore();

const selectedOption1 = ref(undefined);
const selectedOption2 = ref({
        channel: 'facebook',
      });
const disabled = ref(false);
const cbValue = ref(false);
const cbValue2 = ref(false);
const budgetValue = ref(5);
const isToggled = ref(false);
const toggledValue = ref('bleh');
const isChannelBudgetToggleEnabled = ref(false);
const hasError = ref(false);
const hasWarning = ref(false);
const colors = ['#2E395D', '#FF7165', '#7C8399', '#B7B9C6', '#F6F7FA', '#DCE0EA', '#FFFFFF'];
const dateRange = ref({
        startDate: getDateWithTimezone('02-15-2022', 'MM-DD-YYYY'),
        endDate: getDateWithTimezone(),
      });
const currencyInput = ref(undefined);
const textInput = ref(undefined);
const numberInput = ref(undefined);
const textareaInput = ref(undefined);
const isMdSize = ref(false);
const isHorizontalModal = ref(true);
const workspaceModalIsOpen = ref(false);
const masterAccountModalOpen = ref(false);
const createAccountModalOpen = ref(false);
const croppingDefaultImage = ref('');
const radioValue = ref('');
const tabItems = [{ id: 'tab-1', title: 'Tab 1' }, { id: 'tab-2', title: 'Tab 2' }]
const activeTab = ref('tab-1');
const logos = computed(() => ChannelLogos);
const options = [{
      channel: 'facebook',
    },
    {
      channel: 'linkedIn',
    },
    {
      channel: 'instagram',
    },
    {
      channel: 'googleDisplay',
    },
    {
      channel: 'googleSearch',
    }];

function consoleLogData(data) {
  console.log(data);
}
function addAccountUser() {
  accountStore.openModal({action: 'add account user'});
}
function showWorkspaceCretedModal() {
  accountStore.openModal({action: 'workspace created'});
}
function showAccountCretedModal() {
  accountStore.openModal({
    action: 'account created',
    modalData: 'kanyewest51@raven51.com',
  });
}
function setCroppedFile(data) {
croppingDefaultImage.value = data.croppedFile;
consoleLogData(data);
}   

onMounted(() => {
  // the way modal example is used on this page
  // prevents the page from scrolling
  document.body.style.removeProperty('overflow-y');
})
</script>

<style scoped>
.modal-wrapper :deep(.modal-backdrop) {
  position: relative;
}

.modal-wrapper :deep(.modal-wrapper) {
  margin: 10px auto;
}

.tags {
  display: flex;
  width: 150px;
  flex-wrap: wrap;
  gap: 4px;
}

.ui-page {
  padding: 0 20px;
}

h2 {
  margin: 10px 0;
}

.left-col {
  min-width: 190px;
}

.sticky-col {
  position: sticky;
  top: 20px;
}

.sticky-col .checkbox {
  margin: 0;
}

.circle {
  display: inline-block;
  width: 72px;
  height: 33px;
  border: solid 1px black;
  margin: 5px 10px 0 0;
  text-align: center;
  padding-top: 7px;
  font-size: 14px;
  text-shadow: 0px 0px 10px white;
}

.buttons button,
.buttons a {
  margin-bottom: 5px;
  margin-right: 15px;
}

.tool-tip-container {
  display: inline-block;
}

.tooltip-icon {
  width: 10px;
  height: 10px;
}
</style>
