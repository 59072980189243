import _ from 'lodash';
import { defineStore } from 'pinia'
import { handleResponseError } from '@/core/assets/global-utils';
import PackageResource from '@/modules/packages/api/packages.api';
import { ChannelBudget, ChannelsActivation, Package, PackageStatus } from '@/modules/packages/models/package.model';
import {handleBudgetError} from '@/modules/campaigns/helpers/errorHandlers';
import { handleUnknownError } from '@/core/assets/global-utils';
interface State {
  editPackageOpen: boolean;
  proceedWithoutSaving: boolean;
  hasDirtyState: boolean;
  hasActivity: boolean;
  packages: Package[];
  selectedPackage: Package | null;
}

export const usePackageStore = defineStore('package', {
  state: (): State => {
    return {
      editPackageOpen: false,
      proceedWithoutSaving: true,
      hasDirtyState: false,
      hasActivity: false,
      packages: [],
      selectedPackage: null,
    }
  },
  getters: {
    activePackagesDropdownOptions(state) {
      return state.packages.filter(p => p.status === 'active')
    }
  },
  actions: {
    setActivity(status: boolean) {
      this.hasActivity = status;
    },
    async getSinglePackage(packageId: string): Promise<Package | null> {
      try {
        const response = await PackageResource.prototype.getSinglePackage(packageId);
        this.selectedPackage = response;
        return response;
      } catch (error) {
        await handleResponseError(error);
        return null;
      }
    },
    async getAllPackages(): Promise<Package[]> {
      const packages: Package[] = await PackageResource.prototype.getAllPackages()
        .catch(err => {
          handleUnknownError(err);
          return [];
        });
      
      this.packages = packages;
      return packages;
    },
    async updatePackage(): Promise<void> {
      const channelsBudgetInCents = _.mapValues(this.selectedPackage?.channelsBudget, i => `${Number(i) * 100}`);
      const customTotalBudgetInCents: number = Number(this.selectedPackage?.customTotalGrossBudget) * 100;
      const modifiedPackage: any = { ...this.selectedPackage, channelsBudget: channelsBudgetInCents, customTotalGrossBudget: customTotalBudgetInCents };

      this.setActivity(true);
      return PackageResource.prototype.updatePackage(modifiedPackage!)
        .then(() => {
          const updatedList = this.packages.map(currentPackage => {
            return currentPackage.id === this.selectedPackage!.id ? this.selectedPackage : currentPackage;
          });
          this.packages = updatedList as Package[];
          this.removeDirtyState();
          this.setEditPackageOpen(false);
          // Remove the activity indicator from packageStore.
          this.setActivity(false);
        })
        .catch((error) => {
          this.setActivity(false);
          handleBudgetError(error);
        });
    },
    async createPackage(): Promise<void> {
      try {
        const packageItem = await PackageResource.prototype.createPackage();
        this.packages.unshift(packageItem);
      } catch (error) {
        await handleResponseError(error);
      }
    },
    async resetUnsavedChangesInPackage(): Promise<void> {
      await this.getSinglePackage(this.selectedPackage!.id);

      const updatedList = this.packages.map((currentPackage) => {
        return currentPackage.id === this.selectedPackage!.id ? this.selectedPackage! : currentPackage;
      });
      this.packages = updatedList as Package[];
    },
    async deletePackage(packageId: string): Promise<void> {
      try {
        await PackageResource.prototype.deletePackage(packageId);
      } catch (error) {
        await handleResponseError(error);
      }

      const updatedList = this.packages.filter((currentPackage) => currentPackage.id !== packageId);
      this.packages = updatedList as Package[];
      this.setProceedWithoutSaving(true);
    },
    async archivePackage(packageId: string): Promise<void> {
      try {
        await PackageResource.prototype.archivePackage(packageId);
      } catch (error) {
        await handleResponseError(error);
      }
      const updatedList = this.packages.filter((currentPackage) => currentPackage.id !== packageId);
      this.packages = updatedList as Package[];
      this.setProceedWithoutSaving(true);
    },
    async duplicatePackage(packageId: string): Promise<void>  {
      try {
        const duplicatedPackage = await PackageResource.prototype.duplicatePackage(packageId);
        this.packages.unshift(duplicatedPackage);
      } catch (error) {
        await handleResponseError(error);
      }
    },
    setProceedWithoutSaving(proceedWithoutSaving: boolean) {
      this.proceedWithoutSaving = proceedWithoutSaving;
    },
    setDirtyState() {
      this.hasDirtyState = true;
    },
    removeDirtyState() {
      this.hasDirtyState = false;
    },
    setNewPackageStatus(newStatus: PackageStatus) {
      this.selectedPackage!.status = newStatus;
    },
    setNewPackageName(newPackageName: string) {
      this.selectedPackage!.name = newPackageName;
    },
    setChannelsBudgetInEditMode(channelsBudgetInEditMode: ChannelBudget) {
      this.selectedPackage!.channelsBudget = channelsBudgetInEditMode;
    },
    setChannelsActivationInEditMode(channelsActivationInEditMode: ChannelsActivation) {
      this.selectedPackage!.channelsActivation = channelsActivationInEditMode;
    },
    setCustomTotalGrossBudget(customTotalGrossBudget: number) {
      this.selectedPackage!.customTotalGrossBudget = customTotalGrossBudget;
    },
    setSelectedPackage(selectedPackage: Package | null) {
      this.selectedPackage = selectedPackage;
    },
    setEditPackageOpen(editPackageOpen: boolean) {
      this.editPackageOpen = editPackageOpen;
    },
    setNewPackageRuntime(newPackageRuntime: string) {
      this.selectedPackage!.runtime = parseInt(newPackageRuntime, 10);
    },
  }
});