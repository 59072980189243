import {Module, VuexModule, Mutation, Action, getModule, config} from 'vuex-module-decorators';
import store from '../../../core/store/index.store';
import templateStore from '../../templates/default/store/defaultTemplates.store';
import facebookPostAdStore from './facebook/facebookPostAd.store';
import facebookCarouselAdStore from './facebook/facebookCarouselAd.store';
import facebookStoryAdStore from './facebook/facebookStoryAd.store';
import instagramPostAdStore from '@/modules/template-ads/store/instagram/instagramPostAd.store';
import instagramCarouselAdStore from '@/modules/template-ads/store/instagram/instagramCarouselAd.store';
import instagramStoryAdStore from '@/modules/template-ads/store/instagram/instagramStoryAd.store';
import facebookPostCustomStore from './facebook/facebookPostCustom.store';
import facebookCarouselCustomStore from './facebook/facebookCarouselCustom.store';
import facebookStoryCustomStore from './facebook/facebookStoryCustom.store';
import instagramPostCustomStore from './instagram/instagramPostCustom.store';
import instagramCarouselCustomStore from './instagram/instagramCarouselCustom.store';
import instagramStoryCustomStore from './instagram/instagramStoryCustom.store';
import BudgetModal from '../../templates/default/modals/BudgetModal.vue';
import ChangeAdStatusDelete from '../components/modals/ChangeAdStatusDelete.vue';
import {Ad, AdType, AdStatusAction} from '../models/ads.model';
import {CustomTemplate} from '@/modules/templates/custom/models/customTemplate.model';
import {DefaultTemplate} from '../../templates/default/models/defaultTemplate.model';
import {TemplateModel} from '../../templates/models/templates.model';

config.rawError = true;

export interface AdModalParams {
  isAdActive: boolean;
  currentAdComponent: object | null;
  ad: Ad | null;
  adType: AdType | null;
}

@Module({
  name: 'templateAds',
  namespaced: true,
  dynamic: true,
  store,
})
class TemplateAds extends VuexModule {
  public activeAdModal: AdModalParams = {
    isAdActive: false,
    currentAdComponent: null,
    ad: null,
    adType: null,
  };

  @Mutation
  public setAdModal(adAction: AdModalParams) {
    this.activeAdModal = adAction;
  }

  @Action
  public openBudgetModal({template, type}: {template: DefaultTemplate | CustomTemplate, type: TemplateModel}) {
  templateStore.setModal({
    isActive: true,
    currentComponent: BudgetModal,
    template,
    templateType: type,
  });
  }

  @Action
  public emptyTemplateAds() {
    facebookPostAdStore.setAds([]);
    facebookStoryAdStore.setAds([]);
    facebookCarouselAdStore.setAds([]);
    instagramPostAdStore.setAds([]);
    instagramStoryAdStore.setAds([]);
    instagramCarouselAdStore.setAds([]);
    facebookPostCustomStore.setAds([]);
    facebookCarouselCustomStore.setAds([]);
    facebookStoryCustomStore.setAds([]);
    instagramPostCustomStore.setAds([]);
    instagramCarouselCustomStore.setAds([]);
    instagramStoryCustomStore.setAds([]);
  }

  @Action({commit: 'setAdModal'})
  public async openAdConfirmModal({adAction, ad, adType}: {adAction: AdStatusAction, ad: Ad, adType: AdType}) {

    function selectAdModalByAction(action: AdStatusAction) {
      switch (action) {
        case 'delete': return ChangeAdStatusDelete;
        // Pause and Resmues to be added in later product releases
        // case 'pause': ,
        // case 'resume':
      }
    }

    return {
      isAdActive: true,
      currentAdComponent: selectAdModalByAction(adAction),
      ad,
      adType,
    };
  }

  @Action({commit: 'setAdModal'})
  public closeAdConfirmationModal(): AdModalParams {
    return {
      isAdActive: false, 
      currentAdComponent: null, 
      ad: null, 
      adType: null,
    };
  }
}

export default getModule(TemplateAds, store);
