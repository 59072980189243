import axios from 'axios';
import _ from 'lodash';
import { signOut } from '@/modules/auth/auth';
import toastNotificationStore from '@/core/store/toastNotification.store';

const httpService = axios.create({
  baseURL: process.env.VUE_APP_WONDERKIND_API_BASE_URL,
});

httpService.interceptors.response.use(
  (config) => config,
  (error) => {
    let message;
    const status = _.get(error, 'response.status');

    if (status && (status === 403 || status === 401)) {
      signOut();
    } else if (status === 500) {
      toastNotificationStore.showToastNotification({ message: error.message, isError: true, timeout: 100000 });
      return;
    } else {
      const backendErrorMessage = _.get(error, 'response.data.message');
      const messageWithStatus = `${status}: ${_.get(error, 'response.statusText') || error.message}`;
      const messageWithoutStatus = error.request ? `Server returned no response for ${error.request}` : error.message;
      message = backendErrorMessage || `Something went wrong (${status ? messageWithStatus : messageWithoutStatus})`;
    }

    if (!_.get(error, 'config.suppressErrorToast')) {
      toastNotificationStore.showToastNotification({ message, isError: true, timeout: 100000 });
    }

    return Promise.reject(error);
  },
);

export default httpService;
