import CreateCustomTemplate from '@/modules/templates/custom/pages/CreateCustomTemplate.vue';
import CustomTemplatesPresets from '@/modules/templates/custom/pages/CustomTemplatesPresets.vue';
import CustomTemplates from '@/modules/templates/custom/pages/CustomTemplates.vue';
import DefaultTemplates from '@/modules/templates/default/pages/DefaultTemplates.vue';
import CustomCombinedPage from '@/modules/templates/custom/pages/CustomCombinedPage.vue';
import DefaultCombinedPage from '@/modules/templates/default/pages/DefaultCombinedPage.vue';

export default [
    {
        path: '/presets/templates/custom-templates',
        name: 'custom-templates',
        component: CustomTemplates,
        redirect: { name: 'custom-templates-overview' },
        children: [
            {
                path: '',
                name: 'custom-templates-overview',
                component: CustomTemplatesPresets,
            },
            {
                path: 'create',
                name: 'create-custom-template',
                component: CreateCustomTemplate,
            },
            {
                path: 'edit/:customTemplateId',
                name: 'edit-custom-template-type',
                component: CreateCustomTemplate,
            },
        ],
    },
    {
        path: '/presets/templates/default-templates',
        name: 'default-templates',
        component: DefaultTemplates,
    },
    {
        path: '/presets/templates/custom-templates/:templateId/:page',
        name: 'edit-custom',
        props: true,
        component: CustomCombinedPage,
    },
    {
        path: '/presets/templates/default-templates/:templateId/:page',
        name: 'edit-default',
        props: true,
        component: DefaultCombinedPage,
    },
];
