import httpService from '@/core/plugins/httpService';
import {AbstractResource} from '@/core/api/abstract.api';
import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import {Campaign} from '../../campaigns/models/campaign.model';
import CampaignResource, { CampaignResponseJson } from '@/modules/campaigns/api/campaign.api';
import {
  AllAdSetResponse,
  JobList,
  JobListStatus,
  FeedSourceData, 
  NewCampaignRequest, 
  NewJobList, 
  UpdateJobListParams, 
  ValidateXmlParams,
  XmlResponseValidationParams,
} from '../models/jobLibrary.model';
import { handleResponseError } from '@/core/assets/global-utils';

export default class JobLibraryResource extends AbstractResource<JobList, JobListResponseJson, JobListRequestJson> {
  public async validateXmlUrl(feedSource: ValidateXmlParams): Promise<XmlResponseValidationParams | void> {
    const payload = feedSource.login && feedSource.password ? {
      functionGroup: feedSource.functionGroup,
      feedUrl: feedSource.feedUrl,
      login: feedSource.login,
      password: feedSource.password,
    } : {
        functionGroup: feedSource.functionGroup,
        feedUrl: feedSource.feedUrl,
      };
      const validationResponse = await httpService.post(this.collectionEndpoint + '/validate-feed', payload);
      return validationResponse.data;
  }

  public async createJobList(newJobList: NewJobList): Promise<{status: number, data: JobList} | null> {
      const response = await httpService.post(this.collectionEndpoint + '/catalogs', newJobList);
      const data = this.hydrate(response.data as JobListResponseJson);
      return {status: response.status, data};
  }

  public async fetchJobLibrary(pageCount: number): Promise<JobList[] | void> {
      const allJobLists = await httpService.get(this.collectionEndpoint + `/catalogs?perpage=10&page=${pageCount}`, 
      {
        data: {
          firstPageUrl: `/?page=${pageCount}`,
          nextPageUrl: `/?page=${pageCount + 1}`,
          prevPageUrl: `/?page=${pageCount - 1}`,
        },
      },
      );
      return this.hydrateCollection(allJobLists.data.data as JobListResponseJson[]);
  }
  
  public async updateJobList(params: UpdateJobListParams): Promise<{status: number, data: JobList} | null> {
    const response = await httpService.put(this.collectionEndpoint + `/catalogs/${params.id}`, params);
    const data = this.hydrate(response.data as JobListResponseJson);
    
    return {status: response.status, data};
  }

  public async deleteJobList(jobListId: string): Promise<number | void> {
    try {
      const response = await httpService.delete(this.collectionEndpoint + `/catalogs/${jobListId}`);
      return response.status;
    } catch (error) {
      console.log(error);
    }
  }

  public async refreshJobList(jobListId: string): Promise<unknown | void> {
    try {
      const response = await httpService.get(this.collectionEndpoint + `/catalogs/${jobListId}/refresh`);
      return response.status;
    } catch (error) {
      console.log(error);
    }
  }

  public async fetchAllJobListItems(jobListId: string): Promise<AllAdSetResponse | void> {
    try {
      const response = await httpService.get(this.collectionEndpoint + `/catalogs/${jobListId}/items`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
  
  public async createCampaignFromJobList(request: NewCampaignRequest): Promise<Campaign | void> {
    try {
      const response = await httpService.post(this.collectionEndpoint + '/campaigns', request);
      return CampaignResource.prototype.hydrate(response.data);
    } catch (error) {
      await handleResponseError(error);
    }
  }

  public async updateSelectedCampaign(request: Campaign): Promise<Campaign | null> {
    const response = await httpService.put(`${this.entityEndpoint}/${this.resourceOwner}/campaigns/${request.id}`, {...request, facebook_catalog_id: request.facebookJobListId});
      return CampaignResource.prototype.hydrate(response.data);
  }

  public async fetchSingleDynamicCampaign(campaignId: string): Promise<Campaign | null> {
    const response = await httpService.get(`${this.collectionEndpoint}/campaigns/${campaignId}`);
    return CampaignResource.prototype.hydrate(response.data);
  }

  public async fetchAllJobListCampaigns(pageCount: number, activeFilters?: string[]): Promise<Campaign[] | void> {
    try {
      const response = await httpService.get(this.collectionEndpoint + `/campaigns?perpage=10&page=${pageCount}`,
        {
          params: {
            status: activeFilters?.join(','),
          },
          data: {
            firstPageUrl: `/?page=${pageCount}`,
            nextPageUrl: `/?page=${pageCount + 1}`,
            prevPageUrl: `/?page=${pageCount - 1}`,
          },
        },
    );
      const hydratedData = response.data.data.map((campaign: CampaignResponseJson) => {
        return CampaignResource.prototype.hydrate(campaign);
      });
      return hydratedData;
    } catch (error) {
      console.log(error);
    }
  }

  public async deployCampaignAndAds(campaignId: string) {
      const response = await httpService.put(this.collectionEndpoint + `/campaigns/${campaignId}/deploy`);
      return response.status;
  }

  protected get resourceName() {
    return 'dynamic-ads';
  }

  protected get resourceOwner() {
    const accountsStore = useAccountsStore();
    const accountId = accountsStore.selectedAccount!.id;
    return `${accountId}`;
  }

  protected get collectionEndpoint() {
    const accountsStore = useAccountsStore();
    const accountId = accountsStore.selectedAccount!.id;
    return this.entityEndpoint + `/${accountId}`;
  }

  protected extract(model: JobList) {
    return {
      id: model.id,
      name: model.name,
      feedSources: model.feedSources,
    };
  }

  protected hydrate(json: JobListResponseJson) {
    const jobList: JobList = {
      id: json.id,
      accountId: json.accountId,
      name: json.name,
      feedSources: json.feedSources as FeedSourceData[],
      feedsCount: json.feedsCount,
      referenceId: json.externalId,
      vertical: json.vertical,
      status: json.status as JobListStatus,
      createdAt: json.createdAt,
      updatedAt: json.updatedAt,
      jobsCount: json.jobsCount,
      externalEventSources: json.externalEvenSources,
      templateId: json.templateId,
    };
    return jobList;
  }
}

export interface JobListResponseJson {
  id: string;
  accountId: string;
  name: string;
  feedSources: FeedSourceData[];
  feedsCount: number;
  vertical: string | null;
  status: JobListStatus;
  createdAt: string;
  updatedAt: string;
  jobsCount: number;
  externalEvenSources: string[];
  externalId: string;
  campaigns: Campaign[];
  templateId: string;
}

export interface JobListRequestJson {
  id: string;
  name: string | null;
  feedSources: FeedSourceData[];
}
