import defaultTemplateStore from '../../templates/default/store/defaultTemplates.store';
import {AbstractResource} from '@/core/api/abstract.api';
import {FacebookStoryAd} from '../models/facebookAds.model';
import {MediaType} from '@/modules/medialibrary/models/masterImage.model';
import { hydrateAdMedia, AdMediaJson } from '@/modules/ads/api/apiHelpers';

export default class FacebookStoryAdResource extends AbstractResource<
  FacebookStoryAd,
  FacebookStoryAdResponseJson,
  FacebookStoryAdRequestJson
  > {
    protected get resourceName() {
      return 'facebook-story-templates';
    }

    protected get resourceOwner() {
      if (!defaultTemplateStore.selectedDefaultTemplate) {
        throw new Error('Trying to perform ad related actions without a selected template');
      }
      return `templates/${defaultTemplateStore.selectedDefaultTemplate.id}`;
    }

    protected extract(model: FacebookStoryAd): FacebookStoryAdRequestJson {
      return {
        media: {
          id: model.media.id,
          type: model.media.type,
        },
        callToAction: model.callToAction,
      };
    }

    protected hydrate(json: FacebookStoryAdResponseJson): FacebookStoryAd {
      return {
        id: String(json.id),
        media: hydrateAdMedia(json.media),
        callToAction: json.callToAction,
      };
    }

  }

  export interface FacebookStoryAdResponseJson {
    id: string;
    media: AdMediaJson;
    callToAction: string;
  }

  export interface FacebookStoryAdRequestJson {
    media: {
      id: string;
      type: MediaType;
    };
    callToAction: string;
  }
