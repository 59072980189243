import { defineStore } from 'pinia';
import ReportsResource from '@/modules/accounts/api/reports.api';


export const useReportStore = defineStore('reports', {
  actions: {
    async prepareCampaignsReport(accountId: string): Promise<string> {
       return await ReportsResource.prototype.fetch(accountId);
    },
    async prepareReportPerCampaign(accountId: string): Promise<string> {
      return await ReportsResource.prototype.fetchReportPerCampaign(accountId);
    }
  },
});
