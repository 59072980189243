import { AdMedia } from '@/modules/medialibrary/models/video.model';
import { MediaType } from '@/modules/medialibrary/models/masterImage.model';

interface AdMediaJson {
  id: string;
  masterUrl: string;
  master_url?: string;
  url: string;
  previewUrl: string;
  preview_url?: string;
  type: MediaType;
}

function hydrateAdMedia(json: AdMediaJson): AdMedia {
  return {
      id: String(json.id),
      masterUrl: json.master_url || json.masterUrl,
      url: json.url,
      previewUrl: json.preview_url || json.previewUrl,
      type: json.type,
  };
}

export {
  AdMediaJson,
  hydrateAdMedia,
};
