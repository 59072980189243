import Settings from './pages/Settings.vue';

export default [
  {
    path: '/settings',
    redirect: '/settings/company',
  },
  {
    path: '/settings/:settingsRoute',
    name: 'settings',
    component: Settings,
    props: true,
    meta: { pageTransition: { name: 'fade', mode: 'out-in' }},
  },
];
