import httpService from '@/core/plugins/httpService';

export default class LocalAuthResource {
    public static get baseUrl(): string {
        return process.env.VUE_APP_WONDERKIND_API_BASE_URL as string;
    }
    public static login(email: string): Promise<void> {
        LocalAuthResource.developmentOnlyGuard();
        return httpService.get(this.baseUrl.slice(0, -4) + '/sanctum/csrf-cookie').then((response) => {
            return httpService.post('/dev/login', {email});
        });
    }

    public static async isAuthenticated(): Promise<boolean> {
        LocalAuthResource.developmentOnlyGuard();

        const response = await httpService.get('/dev/is-authenticated');
        if (response.status !== 200) {
            return false;
        }
        return response.data.is_authenticated;
    }

    public static logout(): Promise<void> {
        LocalAuthResource.developmentOnlyGuard();
        return httpService.get('/dev/logout');
    }

    protected static developmentOnlyGuard(): void {
        if (process.env.NODE_ENV !== 'development') {
            throw new Error('Only available in development mode');
        }
    }
}
