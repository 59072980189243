import { getDateWithTimezone } from '@/core/plugins/date';
import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import httpService from '@/core/plugins/httpService';
import {Video} from '@/modules/medialibrary/models/video.model';


export default class VideoApi {
    protected get resourceName() {
        const accountsStore = useAccountsStore();
        if (!accountsStore.selectedAccount) {
            throw new Error('Trying to perform master image related actions without a selected account');
        }
        return `accounts/${accountsStore.selectedAccount.id}/videos`;
    }

    protected get endpoint(): string {
        return '/' + this.resourceName;
    }

    public create(video: File): Promise<Video> {
        const formData = new FormData();
        formData.append('video', video);
        return httpService.post( this.endpoint, formData)
          .then((response) =>   this.hydrate(response.data as VideoJson));
    }

    public async fetchVideoById(videoId: string): Promise<Video> {
      const response = await httpService.get(`/videos/${videoId}`);
      return this.hydrate(response.data as VideoJson);
    }

    public rename(image: Video): Promise<void> {
        return httpService.put('/videos/' + image.id, { title: image.title });
    }

    public delete(image: Video): Promise<void> {
        return httpService.delete('/videos/' + image.id);
    }

    public findAll(): Promise<Video[]> {
        return httpService.get(this.endpoint)
          .then((response) =>   this.hydrateCollection(response.data as VideoJson[]));
    }

    protected hydrate(json: VideoJson): Video {
        return {
            id: json.id,
            title: json.title,
            url: json.url,
            duration: json.duration,
            height: json.height,
            width: json.width,
            thumbnailUrl: json.thumbnail_urls[0],
            thumbnailUrls: json.thumbnail_urls,
            fileName: json.file_name,
            user: 'Jelmer Zuidema',
            type: 'video',
            createdAt: getDateWithTimezone(json.created_at, 'YYYY-MM-DD'),
        };
    }

    protected hydrateCollection(responseCollection: VideoJson[]): Video[] {
        return responseCollection.map((response) => this.hydrate(response));
    }
}

interface VideoJson {
    id: string;
    title: string;
    url: string;
    duration: number;
    width: number;
    height: number;
    thumbnail_urls: string[];
    created_at: string;
    file_name: string;
}
