import { defineStore } from 'pinia';

import GoogleDisplayAdsApi from '@/modules/ads/api/googleDisplayAds.api';
import {GoogleDisplayCustomAd} from '@/modules/ads/models/googleAds.model';

type UploadState = 'idle' | 'loading' | 'succeeded' | 'failed';
interface State {
  uploadState: UploadState;
  minimiseGoogleAds: boolean;
  unsortedAds: GoogleDisplayCustomAd[];
  newCreatedAds: GoogleDisplayCustomAd[];
}

export const useGoogleDisplayStore = defineStore('GoogleDisplay', {
  state: (): State => {
    return {
      uploadState: 'idle',
      minimiseGoogleAds: false,
      unsortedAds: [],
      newCreatedAds: [],
    }
  },
  getters: {
    minimiseImageString(state) {
      return state.minimiseGoogleAds ? '@/core/assets/icons/maximise.png' : '@/core/assets/icons/minimise.png';
    },
    ads(state): GoogleDisplayCustomAd[] {
      return state.unsortedAds.sort((a: GoogleDisplayCustomAd, b: GoogleDisplayCustomAd) => {
        const diff = a.height - b.height;
        return diff !== 0 ? diff : a.width - b.width;
      });
    },
    createdAds(state): GoogleDisplayCustomAd[] {
      return state.newCreatedAds.sort((a: GoogleDisplayCustomAd, b: GoogleDisplayCustomAd) => {
        const diff = a.height - b.height;
        return diff !== 0 ? diff : a.width - b.width;
      });
    }
  },
  actions: {
    setAds(ads: GoogleDisplayCustomAd[]) {
      this.unsortedAds = ads;
    },
    setUploadState(state: UploadState) {
      this.uploadState = state;
    },
    appendAds(ads: GoogleDisplayCustomAd[]) {
      this.unsortedAds.concat(ads);
    },
    setCreatedAds(ads: GoogleDisplayCustomAd[]) {
      this.newCreatedAds = ads;
    },
    setMinimiseGoogleAds(value: boolean) {
      this.minimiseGoogleAds = value;
    },
    resetNewAds() {
      this.newCreatedAds = [];
    },
    async deleteAd(removingAd: GoogleDisplayCustomAd): Promise<GoogleDisplayCustomAd[]> {
      await GoogleDisplayAdsApi.prototype.delete(removingAd);
      const filteredAds = this.ads.filter((ad) => {
        return removingAd.id !== ad.id;
      });
      this.setAds(filteredAds);
      return filteredAds;
    },
    async fetchAds(): Promise<GoogleDisplayCustomAd[]> {
      const ads = await GoogleDisplayAdsApi.prototype.findAll();
      this.setAds(ads);
      return ads;
    },
    async create(zipArchive: File): Promise<GoogleDisplayCustomAd[] | void> {
      this.setUploadState('loading')
      const ads = await GoogleDisplayAdsApi.prototype.create(zipArchive);

      if (!ads) { return this.setUploadState('failed'); }

      this.setUploadState('succeeded');
      this.setCreatedAds(ads);
      this.appendAds(ads);
      return ads;
    },
    updateUploadState(state: UploadState): UploadState {
      this.setUploadState(state);
      return state;
    }
  },
})
