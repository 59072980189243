import { defineStore } from 'pinia';

import GoogleDisplayGeneratedAdsApi from '../../api/googleDisplayGeneratedAds.api';
import {GoogleDisplayGeneratedAd, GoogleDisplayGeneratedAdParams} from '../../models/googleAds.model';

interface State {
  ads: GoogleDisplayGeneratedAd[];
  newCreatedAds: GoogleDisplayGeneratedAd;
  minimiseGoogleAds: boolean;
  loading: boolean;
  placeholderAds: PlaceholderAds[];
}
interface PlaceholderAds {
  bannerName: BannerName;
  width: number;
  height: number;
}
type BannerName = 'half page' | 'wide skyscraper' | 'skyscraper' | 'medium rectangle' | 'large rectangle' |
  'large leaderboard' | 'leaderboard' | 'banner' | 'small banner';

export const useGoogleDisplayGeneratedStore = defineStore('GoogleDisplayGenerated', {
  state: (): State => {
    return {
      ads: [],
      newCreatedAds: {
        type: '',
        subtitle: '',
        title: '',
        callToAction: '',
        tags: [],
        primaryColor: '',
        logoUrl: '',
      },
      minimiseGoogleAds: false,
      loading: false,
      placeholderAds: [
        { bannerName: 'half page', width: 300, height: 600 },
        { bannerName: 'wide skyscraper', width: 160, height: 600 },
        { bannerName: 'skyscraper', width: 120, height: 600 },
        { bannerName: 'medium rectangle', width: 300, height: 250 },
        { bannerName: 'large rectangle', width: 336, height: 280 },
        { bannerName: 'large leaderboard', width: 970, height: 90 },
        { bannerName: 'leaderboard', width: 728, height: 90 },
        { bannerName: 'banner', width: 468, height: 60 },
        { bannerName: 'small banner', width: 320, height: 50 },
      ],
    }
  },
  getters: {
    sortedPlaceholders(state) {
      return state.placeholderAds.sort((a, b) => {
        const diff = a.height - b.height;
        return diff !== 0 ? diff : a.width - b.width;
      });
    }
  },
  actions: {
    setMinimiseGoogleAds(value: boolean) {
      this.minimiseGoogleAds = value;
    },
    setAds(ads: GoogleDisplayGeneratedAd[]) {
      this.ads = ads;
    },
    appendAds(ad: GoogleDisplayGeneratedAd) {
      this.ads = [...this.ads, ad];
    },
    resetNewAds() {
      this.newCreatedAds = {
        type: '',
        subtitle: '',
        title: '',
        callToAction: '',
        tags: [],
        primaryColor: '',
        logoUrl: '',
      };
    },
    setLoading(state: boolean) {
      this.loading = state;
    },
    removeAd(removingAd: GoogleDisplayGeneratedAd) {
      this.ads = this.ads.filter((ad) => {
        return removingAd.id !== ad.id;
      });
    },
    editAd(editedAd: GoogleDisplayGeneratedAd) {
      this.ads = this.ads.map((ad) => {
        if (ad.id === editedAd.id) {
          return editedAd;
        }
        return ad;
      });
    },
    async create(adParams: GoogleDisplayGeneratedAdParams) {
      this.setLoading(true);
      const ad = await GoogleDisplayGeneratedAdsApi.prototype.create(adParams);

      if (!ad) {
        return this.setLoading(false);
      } else {
        this.appendAds(ad);
        this.setLoading(false);
      }
    },
    async updateAd(ad: GoogleDisplayGeneratedAd) {
      const editedAd = await GoogleDisplayGeneratedAdsApi.prototype.update(ad);
      this.editAd(editedAd as GoogleDisplayGeneratedAd);
      return editedAd;
    },
    async fetchAds(): Promise<GoogleDisplayGeneratedAd[] | void> {
      const ads = await GoogleDisplayGeneratedAdsApi.prototype.findAll();
      this.setAds(ads as GoogleDisplayGeneratedAd[]);
      return ads;
    },
    async deleteAd(removingAd: GoogleDisplayGeneratedAd) {
      await GoogleDisplayGeneratedAdsApi.prototype.delete(removingAd);
      this.removeAd(removingAd);
      return removingAd;
    },
  }
})
