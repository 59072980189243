import { render, staticRenderFns } from "./CropperView.vue?vue&type=template&id=2922dd61&scoped=true&"
import script from "./CropperView.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CropperView.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CropperView.vue?vue&type=style&index=0&id=2922dd61&prod&scoped=true&lang=css&"
import style1 from "./CropperView.vue?vue&type=style&index=1&id=2922dd61&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2922dd61",
  null
  
)

export default component.exports