import { render, staticRenderFns } from "./LinkedInAdsEditorForm.vue?vue&type=template&id=954fb1dc&scoped=true&"
import script from "./LinkedInAdsEditorForm.vue?vue&type=script&setup=true&lang=ts&"
export * from "./LinkedInAdsEditorForm.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./LinkedInAdsEditorForm.vue?vue&type=style&index=0&id=954fb1dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "954fb1dc",
  null
  
)

export default component.exports