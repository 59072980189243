import { defineStore, Store, StoreDefinition } from 'pinia';
import { PiniaStore } from '@/core/store/piniaTypes';
import { state, actions, getters, BaseState, BaseGetters, BaseActions } from '@/core/store/common/ads.store';

import LinkedinPostAdResource, { LinkedinPostAdResponseJson, LinkedinPostAdRequestJson } from '@/modules/ads/api/linkedinPostAd.api';

import adsEditorStore from '@/modules/ads/store/adsEditor.store';
import campaignStore from '@/modules/campaigns/store/campaigns.store';
import { useSocialPagesStore } from '@/modules/accounts/store/socialPages.store';

import { AbstractResource } from '@/core/api/abstract.api';
import { Ad as AbstractAd } from '@/modules/ads/models/ads.model';
import { AdMedia } from '@/modules/medialibrary/models/video.model';
import { LinkedinPostAd } from '@/modules/ads/models/linkedinAds.model';
import { SocialPage } from '@/modules/accounts/models/socialPage.model';
import { stat } from 'fs';

interface LinkedInPostState extends BaseState<AbstractAd> {
  resource: AbstractResource<LinkedinPostAd, LinkedinPostAdRequestJson, LinkedinPostAdResponseJson>;
}

interface LinkedInPostActions extends BaseActions<LinkedinPostAd> {
  createAds(ad: LinkedinPostAd): Promise<void>;
  updateLinkedInPage(id: string | null | undefined): SocialPage | undefined;
}

type LinkedInPostStore = Store<'linkedInPostAdStore', LinkedInPostState, BaseGetters<AdMedia>, LinkedInPostActions>;

type LinkedInPostStoreDefinition = StoreDefinition<'linkedInPostAdStore', LinkedInPostState, BaseGetters<AdMedia>, LinkedInPostActions>;

const extendedPostStore: PiniaStore<LinkedInPostStore> = {
  state: () => {
    return {
      ...state(),
      linkedInPage: {},
      resource: LinkedinPostAdResource.prototype,
    }
  },
  getters: {
    ...getters,
    media(): AdMedia[] {
      return adsEditorStore.media.length > 0 ?
        adsEditorStore.media :
        [{ id: '', url: '', masterUrl: '', previewUrl: '', type: 'image'}];
    },
  },
  actions: {
    ...actions,
    updateLinkedInPage(id: string | null | undefined) {
      const socialPagesStore = useSocialPagesStore();
      const socialPage = socialPagesStore.returnSocialPage({id, channel: 'linkedin'});
      this.linkedInPage = socialPage;
      return socialPage;
    },
    async createAds(): Promise<void> {
      for (const media of this.media) {
        const linkedinPostAd: LinkedinPostAd = {
          media,
          ...adsEditorStore.copy,
          status: 'active',
          linkUrl: campaignStore.selectedCampaign!.targetUrl!,
        };
        await this.createAd(linkedinPostAd);
      }
    },
  },
}

export const useLinkedInPostStore: LinkedInPostStoreDefinition = defineStore('linkedInPostAdStore', extendedPostStore);