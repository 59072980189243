import httpService from '@/core/plugins/httpService';
import campaignStore from '../../campaigns/store/campaigns.store';
import {GoogleDisplayCustomAd} from '@/modules/ads/models/googleAds.model';
import {AdMedia} from '@/modules/medialibrary/models/video.model';
import { handleResponseError } from '@/core/assets/global-utils';

export default class GoogleDisplayAdsResource {
    protected get resourceName() {
        if (!campaignStore.selectedCampaign) {
            throw new Error('Trying to perform actions without a selected campaign.');
        }
        return `campaigns/${campaignStore.selectedCampaign.id}/google-display-ads`;
    }

    protected get endpoint(): string {
        return '/' + this.resourceName;
    }

    public create(zipArchive: File): Promise<GoogleDisplayCustomAd[] | any> {
        const formData = new FormData();
        formData.append('zip', zipArchive);
        return httpService.post( this.endpoint, formData)
          .then((response) => {
            console.log('RESPONSE', response.data);
            const cleanArray = response.data.filter((ad: GoogleDisplayAdJson) => ad !== null);
            return this.hydrateCollection(cleanArray as GoogleDisplayAdJson[]);
          })
          .catch((error) => {
              handleResponseError(error);
          });
    }

    public delete(ad: GoogleDisplayCustomAd): Promise<void> {
        return httpService.delete('/google-display-ads/' + ad.id);
    }

    public findAll(): Promise<GoogleDisplayCustomAd[]> {
        return httpService.get(this.endpoint)
          .then((response) => this.hydrateCollection(response.data as GoogleDisplayAdJson[]));
    }

    protected hydrate(json: GoogleDisplayAdJson): GoogleDisplayCustomAd {
        return {
            id: json.id,
            status: 'active',
            htmlUrl: json.html_url,
            width: json.width,
            height: json.height,
            isPlaceholder: false,
            bannerName: json.name,
            media: [],
        };
    }

    protected hydrateCollection(responseCollection: GoogleDisplayAdJson[]): GoogleDisplayCustomAd[] {
        return responseCollection.map((response) => this.hydrate(response));
    }
}

interface GoogleDisplayAdJson {
    id: string;
    html_url: string;
    width: number;
    height: number;
    media: AdMedia[];
    name: string;
}
