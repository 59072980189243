import {AbstractResource} from '@/core/api/abstract.api';
import {SocialPage} from '@/modules/accounts/models/socialPage.model';
import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import { Channel } from '@/modules/ads/models/ads.model';
import { RequestStatus } from '@/modules/settings/models/accountSettings.model';

export default class SocialPagesResource extends AbstractResource<SocialPage, SocialPageJson, SocialPageJson> {
    protected get resourceName() {
        return 'social-pages';
    }

    protected get resourceOwner() {
        const accountsStore = useAccountsStore();
        return 'accounts/' + accountsStore.selectedAccount!.id;
    }

    protected extract(model: SocialPage): SocialPageJson {
        return {
            id: model.id,
            name: model.name,
            channel: model.channel,
            icon: model.icon,
            leadform_tos: model.leadformStatus,
        };
    }

    protected hydrate(json: SocialPageJson): SocialPage {
        const model: SocialPage = {
            id: json.id,
            name: json.name,
            channel: json.channel,
            icon: json.icon,
            leadformStatus: json.leadform_tos,
        };

        if (json.id) {
            model.id = json.id;
        }

        return model;
    }
}

interface SocialPageJson {
    id: string | null;
    name: string;
    channel: Channel;
    icon: string;
    leadform_tos: RequestStatus;
}
