import httpService from '@/core/plugins/httpService';

export abstract class AbstractResource<Model extends AbstractModel, ResponseJsonModel, RequestJsonModel> {
    public findById(id: string): Promise<Model | null> {
      if (!id) {
          console.warn(`\"findById\" method is missing \"id\" argument. ${this.entityEndpoint}/{id} request was cancelled.`);
          return Promise.resolve(null);
      }

      return httpService.get(this.entityEndpoint + '/' + id)
        .then((response) => this.hydrate(response.data as ResponseJsonModel));
    }

    public async findAll(): Promise<Model[]> {
        return await httpService.get(this.collectionEndpoint)
          .then((response) => this.hydrateCollection(response.data as ResponseJsonModel[]))
          .catch(err => {
            this.handleError(err);
            return [];
          });
    }

    public create(model: Model): Promise<Model> {
        return httpService.post(this.collectionEndpoint, this.extract(model))
          .then((response) => this.hydrate(response.data as ResponseJsonModel));
    }

    public update(model: Model): Promise<Model> {
        return httpService.put(this.entityEndpoint + '/' + this.identifier(model), this.extract(model))
          .then((response) => this.hydrate(response.data as ResponseJsonModel));
    }

    public async delete(model: Model): Promise<void> {
        return await httpService.delete(this.entityEndpoint + '/' + this.identifier(model))
          .then((response) => Promise.resolve())
          .catch(this.handleError);
    }

  protected identifier(model: Model): string | undefined {
        return model?.id || '';
    }

    protected get collectionEndpoint(): string {
        if (this.resourceOwner) {
            return '/' + this.resourceOwner + '/' + this.resourceName;
        } else {
            return '/' + this.resourceName;
        }
    }

    protected get entityEndpoint(): string {
        return '/' + this.resourceName;
    }

    protected abstract get resourceName(): string;
    protected abstract get resourceOwner(): string;

    protected abstract hydrate(json: ResponseJsonModel): Model;
    protected abstract extract(model: Model): RequestJsonModel;

    protected hydrateCollection(responseCollection: ResponseJsonModel[]): Model[] {
        return responseCollection.map((response) => this.hydrate(response));
    }

    private handleError(err: Error) {
        console.log('Error:', err);
    }
}

export interface AbstractModel {
    id?: string | null;
}
