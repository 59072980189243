import {AbstractResource} from '@/core/api/abstract.api';
import { useAccountsStore } from '../store/accounts.store';
import {InvitationRequest} from '@/modules/accounts/models/invitationRequest.model';
import {Invitation} from '@/modules/accounts/models/invitation';
import httpService from '@/core/plugins/httpService';


export default class InvitationResource extends AbstractResource<Invitation, InvitationJson, InvitationJson> {
    public inviteUser(invitationRequest: InvitationRequest): Promise<void> {
        return httpService.post(this.collectionEndpoint, invitationRequest);
    }

    protected identifier(invitation: Invitation): string | undefined {
        return invitation.id;
    }

    protected get resourceName() {
        return 'invitations';
    }

    protected get resourceOwner(): string {
        const accountsStore = useAccountsStore();
        return `accounts/${accountsStore.selectedAccount!.id}/members`;
    }

    protected extract(model: Invitation): InvitationJson {
        return {
            id: model.id!,
            first_name: model.first_name,
            last_name: model.last_name,
            name: model.name,
            email: model.email,
            role: model.role,
            status: model.status,
            workspaces: model.workspaces ? model.workspaces : [],
        };
    }

    protected hydrate(json: InvitationJson): Invitation {
        return {
            id: json.id,
            first_name: json.first_name,
            last_name: json.last_name,
            name: json.name,
            email: json.email,
            role: json.role,
            status: json.status,
            workspaces: json.workspaces ? json.workspaces : [],
        };
    }
}

interface InvitationJson {
    id: string;
    first_name: string;
    last_name: string;
    name: string;
    email: string;
    role: string;
    status: string;
    workspaces: Array<{ name: string, slug: string }>;
}
