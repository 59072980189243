export interface Auth0Config {
    domain: string;
    clientId: string;
    audience: string;
    scope: string;
    responseType: string;
    redirectUri: string;
}

export const authConfig = {
    domain : String(process.env.VUE_APP_AUTH0_DOMAIN),
    clientId: String(process.env.VUE_APP_AUTH0_CLIENT_ID),
    audience: String(process.env.VUE_APP_AUTH0_AUDIENCE),
    scope: 'openid profile email',
    responseType: 'token id_token',
    redirectUri: window.location.origin, // Redirect back to main page by default
};
