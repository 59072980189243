import Vue from 'vue';
import Vuex from 'vuex';
import { createPinia, PiniaVuePlugin, setActivePinia } from 'pinia'

Vue.use(Vuex);

Vue.use(PiniaVuePlugin)
export const pinia = setActivePinia(createPinia());

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
});
