import ErroredCampaignResource from '@/modules/campaigns/api/erroredCampaign.api';
import { Campaign } from '@/modules/campaigns/models/campaign.model';
import { handleResponseError } from '@/core/assets/global-utils';
import campaignsStore from '@/modules/campaigns/store/campaigns.store';
import { defineStore } from 'pinia';

interface State {
  searchString: string;
  loading: boolean;
  erroredCampaigns: Campaign[];
  pageCount: number;
}

export const useErroredCampaignStore = defineStore('erroredCampaignStore', {
state: (): State => {
  return {
    searchString: '',
    loading: true,
    erroredCampaigns: [],
    pageCount: 1,
  };
},
getters: {
  isErroredCampaigns(state) {
    return !!state.erroredCampaigns.length;
  },
  filteredErroredCampaigns(state) {
    return state.erroredCampaigns.filter((campaign) => {
      return campaign.title!.toLowerCase().includes(state.searchString.toLowerCase());
    }).sort()
  },
  selectedCampaign() {
    return campaignsStore.selectedCampaign;
  }
},
actions: {
  resetErroredCampaigns() {
    this.erroredCampaigns = [];
  },
  updatePageCount(pageCount: number) {
    this.pageCount = pageCount;
  },
  search(searchString: string) {
    this.searchString = searchString;
  },
  clearSearch() {
    this.searchString = '';
  },
  updateLoadingState(newLoadingState: boolean) {
    this.loading = newLoadingState;
  },
  async fetchErroredCampaigns() {
    try {
      const erroredCampaigns = await ErroredCampaignResource.prototype.findAllErroredCampaigns(this.pageCount);
      await this.updateLoadingState(false);

      const campaignsToStore = erroredCampaigns.filter((campaign) => {
        return !this.erroredCampaigns.find((knownCampaign) => knownCampaign.id === campaign.id);
      });

      this.erroredCampaigns = [...this.erroredCampaigns, ...campaignsToStore];
      return erroredCampaigns;
    } catch (error: any) {
      await handleResponseError(error);
      return [];
    }
  },
  async resolveOneAd(data: {
    campaignId: string,
    requestId: string,
    resolution: string,
    note: string,
  }) {
    try {
      await ErroredCampaignResource.prototype.resolveOneAd(data.campaignId, data.requestId, data.resolution, data.note);
      return true;
    } catch (error: any) {
      await handleResponseError(error);
      return false;
    }
  },
  removeErroredCampaignWithAllResolvedAds(campaignId: string) {
    const updatedList = this.erroredCampaigns.filter((campaign) => {
      return campaign.id !== campaignId;
    });
    this.erroredCampaigns = updatedList;
  },
  updateErroredCampaignWithOneResolvedAd(campaign: Campaign) {
    const updatedList = this.erroredCampaigns.map((currentCampaign) => {
      return currentCampaign.id === campaign.id ? campaign : currentCampaign;
    });
    this.erroredCampaigns = updatedList;
  },
  addErroredCampaignIfNotInTheErroredList(campaign: Campaign) {
    if (campaign && campaign?.errors?.length) {
      const campaignIsNotInTheList = this.erroredCampaigns.find((existingCamaign) => {
        return existingCamaign.id === campaign.id;
      });

      if (!campaignIsNotInTheList) {
        this.erroredCampaigns = [...this.erroredCampaigns, campaign];
      }
    }
  },
  async retryDeployOneAd(data: { campaignId: string, requestId: string }): Promise<boolean> {
    try {
      await ErroredCampaignResource.prototype.retryDeployOneAd(data.campaignId, data.requestId);
      return true;
    } catch (error: any) {
      await handleResponseError(error);
      return false;
    }
  },
  async redeployErroredCampaign(campaignId: string): Promise<boolean> {
    try {
      await ErroredCampaignResource.prototype.redeploy(campaignId);
      return true;
    } catch (error: any) {
      await handleResponseError(error);
      return false;
    }
  },
  async updateErroredCampaignsList(): Promise<void> {
    if (this.selectedCampaign?.errors?.length) {
      const campaign = await campaignsStore.getSingleCampaign(this.selectedCampaign.id);
      if (campaign) {
        if (campaign!.errors!.length) {
          this.updateErroredCampaignWithOneResolvedAd(campaign);
        } else {
          this.removeErroredCampaignWithAllResolvedAds(campaign.id);
          campaignsStore.updateCampaignOnSuccessfulUpdate(campaign);
        }
      }
    }
  },
}
});
