import httpService from '@/core/plugins/httpService';
import { AdMedia } from '../../medialibrary/models/video.model';
import { GoogleDisplayCustomAd } from '../models/googleAds.model';
import defaultTemplateStore from '../../templates/default/store/defaultTemplates.store';
export default class GoogleDisplayAdResource {
  protected get resourceName() {
    if (!defaultTemplateStore.selectedDefaultTemplate) {
      throw new Error('Trying to perform actions without a selected template.');
    }
    return `templates/${defaultTemplateStore.selectedDefaultTemplate.id}/google-display-templates`;
  }

  protected get endpoint(): string {
    return '/' + this.resourceName;
  }

  public create(zipArchive: File) {
    const formData = new FormData();
    formData.append('zip', zipArchive);
    return httpService.post( this.endpoint, formData)
      .then((response) => {
        return this.hydrateCollection(response.data as GoogleDisplayAdJson[]);
      });
  }

  public findAll(): Promise<GoogleDisplayCustomAd[]> {
    return httpService.get(this.endpoint)
      .then((response) => this.hydrateCollection(response.data as GoogleDisplayAdJson[]));
  }

  public delete(ad: GoogleDisplayCustomAd): Promise<void> {
    return httpService.delete('/google-display-templates/' + ad.id);
}

  protected hydrate(json: GoogleDisplayAdJson): GoogleDisplayCustomAd {
    return {
      id: json.id,
      status: 'active',
      htmlUrl: json.html_url,
      width: json.width,
      height: json.height,
      isPlaceholder: false,
      bannerName: json.name,
      media: [],
    };
  }

  protected hydrateCollection(responseCollection: GoogleDisplayAdJson[]): GoogleDisplayCustomAd[] {
    return responseCollection.map((response) => this.hydrate(response));
  }  
}

interface GoogleDisplayAdJson {
  id: string;
  html_url: string;
  width: number;
  height: number;
  media: AdMedia[];
  name: string;
}
