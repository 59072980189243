import MediaLibrary from '@/modules/medialibrary/pages/MediaLibrary.vue';

export default [
  {
    path: '*/media-library',
    name: 'media-library',
    component: MediaLibrary,
    props: true,
    meta: { pageTransition: { name: 'overlay', mode: 'in-out' }},
  },
];
