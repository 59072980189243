import {VuexModule, Mutation, Action, config} from 'vuex-module-decorators';
import {AbstractResource} from '@/core/api/abstract.api';
import {Ad as AbstractAd} from '../models/ads.model';
import customTemplateStore from '../../templates/custom/store/customTemplates.store';

config.rawError = true;

export abstract class AbstractAds<Ad extends AbstractAd, AdResponseJson, AdRequestJson> extends VuexModule {
  public loading: boolean = true;
  public ads: Ad[] = [];
  public newCreatedAds: Ad[] = [];

  protected abstract get resource(): AbstractResource<Ad, AdResponseJson, AdRequestJson>

  @Mutation
  public setLoading(state: boolean) {
    this.loading = state;
  }

  @Mutation
  public setAds(ads: Ad[]) {
    this.ads = ads;
  }

  @Mutation
  public appendAd(ad: Ad) { 
    this.ads.push(ad);
    this.newCreatedAds.push(ad);
  }

  @Mutation
  public emptyNewCreatedAds() {
    this.newCreatedAds = [];
  }

  @Action({commit: 'setLoading'})
  public async updateLoadingState(newLoadingState: boolean): Promise<boolean> {
    return newLoadingState;
  }

  @Action({commit: 'setAds'})
  public async fetchAds() {
    if (customTemplateStore.selectedCustomTemplate!.id === '') {
      return;
    } else {
    const ads = await this.resource.findAll();
    return ads;
    }
  }

  @Action({commit: 'appendAd'})
  public async createAd(ad: Ad): Promise<Ad | null> {
    try {
      return await this.resource.create(ad);
    } catch (err) {
      return null;
    }
  } 

  @Action({commit: 'setAds'})
  public async deleteAd(ad: Ad) {
    await this.resource.delete(ad);
    return this.ads.filter((item) => {
      return ad.id !== item.id;
    });
  }

  @Action({commit: 'setAds'})
  public async updateAd(ad: Ad) {
    await this.resource.update(ad);
    const all = await this.resource.findAll();
    return all;
  }
}
