export const tooltip = {
  bind: (el: any, binding: any) => {
    el.addEventListener('mouseover', (evt: any) => {
      showTooltip(evt, binding);
    }); 
  },
  unbind: (el: any) => {
    el.removeEventListener('mouseover', showTooltip);
    el.removeEventListener('mouseleave', hideTooltip);
    el.removeEventListener('click', hideTooltip);
  },
};

const showTooltip = (evt: any, binding: any) => {
  const targetEl = evt.target as HTMLElement;
  const tooltip = document.createElement('div');

  if (targetEl.offsetWidth <= targetEl.scrollWidth || binding.value) {
    const box = targetEl.getBoundingClientRect();
    tooltip.innerText = binding?.value ?? targetEl.innerText;
    tooltip.setAttribute('style', `${tooltipStyles}; top: ${box.y + box.height}px; left: ${box.x + (box.width / 2)}px`);
    document.body.appendChild(tooltip);
    targetEl.addEventListener('mouseleave', () => { hideTooltip(tooltip); });
    targetEl.addEventListener('click', () => { hideTooltip(tooltip); });
  }
};

const hideTooltip = (tooltip: any) => {
  if (document.body.contains(tooltip)) {
    document.body.removeChild(tooltip);
  }
};

const tooltipStyles = `
  position: fixed; 
  max-width: 50%;
  z-index: 11; 
  color: var(--color-secondary); 
  padding: 8px 12px; 
  border-radius: var(--border-radius); 
  background: white;
  overflow-wrap: break-word;
  box-shadow: var(--box-shadow);
`;
