import defaultTemplateStore from '../../templates/default/store/defaultTemplates.store';
import {AbstractResource} from '@/core/api/abstract.api';
import {InstagramStoryAd} from '../models/instagramAds.model';
import {MediaType} from '@/modules/medialibrary/models/masterImage.model';
import { hydrateAdMedia, AdMediaJson } from '@/modules/ads/api/apiHelpers';

export default class InstagramStoryAdResource extends AbstractResource<
  InstagramStoryAd,
  InstagramStoryAdResponseJson,
  InstagramStoryAdRequestJson
  > {

    protected get resourceName() {
      return 'instagram-story-templates';
    }

    protected get resourceOwner() {
      if (!defaultTemplateStore.selectedDefaultTemplate) {
        throw new Error('Trying to perform ad related actions without a selected default template');
      }
      return `templates/${defaultTemplateStore.selectedDefaultTemplate?.id}`;
    }

    protected extract(model: InstagramStoryAd): InstagramStoryAdRequestJson {
      return {
        media: {
          id: model.media.id,
          type: model.media.type,
        },
        callToAction: model.callToAction,
      };
    }

    protected hydrate(json: InstagramStoryAdResponseJson): InstagramStoryAd {
      return {
        id: String(json.id),
        media: hydrateAdMedia(json.media),
        callToAction: json.callToAction,
      };
    }
  }

export interface InstagramStoryAdResponseJson {
  id: string;
  media: AdMediaJson;
  callToAction: string;
}

export interface InstagramStoryAdRequestJson {
  media: {
    id: string,
    type: MediaType;
  };
  callToAction: string;
}
