import {VuexModule, Mutation, Action, Module, getModule} from 'vuex-module-decorators';
import store from '@/core/store/index.store';

@Module({
    name: 'app',
    namespaced: true,
    dynamic: true,
    store,
})
class AppStore extends VuexModule {
  public isLoading: boolean = true;

  @Mutation
  public setLoadingState(newLoadingState: boolean): void {
    this.isLoading = newLoadingState;
  }

  @Action({ commit: 'setLoadingState' })
  public updateLoadingState(newLoadingState: boolean): boolean {
    return newLoadingState;
  }
}

export default getModule(AppStore, store);
