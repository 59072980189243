import { render, staticRenderFns } from "./MediaLibraryDetailSideBar.vue?vue&type=template&id=65733668&scoped=true&"
import script from "./MediaLibraryDetailSideBar.vue?vue&type=script&setup=true&lang=ts&"
export * from "./MediaLibraryDetailSideBar.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./MediaLibraryDetailSideBar.vue?vue&type=style&index=0&id=65733668&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65733668",
  null
  
)

export default component.exports