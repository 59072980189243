import {Action, getModule, Module, Mutation, VuexModule, config} from 'vuex-module-decorators';
import store from '@/core/store/index.store';
import userApi from '../api/user.api';
import {User} from '@/modules/auth/models/user.model';
import {Membership} from '@/modules/auth/models/membership.model';
import { useAccountsStore } from '@/modules/accounts/store/accounts.store';
import {handleResponseError, showNotification} from '@/core/assets/global-utils';

config.rawError = true;

export interface IntercomTypes {
    created_at: number | null;
    hash: string;
}
@Module({
    name: 'user',
    namespaced: true,
    dynamic: true,
    store,
})
class LocalUserStore extends VuexModule {
    public user: User | null = null;
    public memberships: Membership[] = [];
    public intercomKey: IntercomTypes = {
        created_at: null,
        hash: '',
    };

    get isAccountAdmin(): boolean {
        const accountsStore = useAccountsStore();
        if (accountsStore.selectedAccount) {
            const accountId = accountsStore.selectedAccount!.id!;
            const membership = this.memberships.find((m: Membership) => m.accountId === accountId);
            if (!membership) {
                throw new Error('User is not a member of selected account');
            }
            return membership.role === 'admin';
        } else {
            return false;
        }
    }

    @Mutation
    public setProfile(user: User | null) {
        this.user = user;
    }

    @Mutation
    public clearUser() {
        this.user = null;
    }

    @Mutation
    public clearMemberships() {
        this.memberships = [];
    }

    @Mutation
    public setMemberships(memberships: Membership[]) {
        this.memberships = memberships;
    }

    @Mutation 
    public setIntercomHashKey(intercomValues: IntercomTypes) {
        this.intercomKey = intercomValues;
    }

    @Action({commit: 'setProfile'})
    public async profile(): Promise<User | null> {
      try {
        return await userApi.profile();
      } catch (error) {
          await handleResponseError(error);
          return null;
      }
    }

    @Action({commit: 'setMemberships'})
    public async userMemberships(): Promise<Membership[]> {
        return await userApi.memberships();
    }

    @Action({ commit: 'setIntercomHashKey'}) 
    public async intercomHashKey() {
        return await userApi.intercomHashKey();
    }

    @Action
    public empty() {
        this.context.commit('clearUser');
        this.context.commit('clearMemberships');
    }
}

export default getModule(LocalUserStore, store);
