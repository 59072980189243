import { Campaign, CampaignStatus, SortOptions } from '../models/campaign.model';

function filterStatus(campaign: Campaign, activeFilters: CampaignStatus[]): boolean {
  return !!activeFilters.length
    ? activeFilters.includes(campaign.status)
    : true;
}

function sortDates(campaignA: Campaign, campaignB: Campaign, activeSorting: SortOptions): number {
  if (activeSorting.sortDirection !== 'undefined') {
    const { sortDirection, sortOption }: SortOptions = activeSorting;
    const campaignValueA = campaignA[sortOption]
      ? new Date(campaignA[sortOption]).getTime()
      : Number.MAX_SAFE_INTEGER;
    const campaignValueB = campaignB[sortOption]
      ? new Date(campaignB[sortOption]).getTime()
      : Number.MAX_SAFE_INTEGER;
    return sortDirection === 'ascending'
      ? campaignValueA - campaignValueB
      : campaignValueB - campaignValueA;
  }
  return 1;
}

function sortStatus(campaignA: Campaign, campaignB: Campaign, activeSorting: SortOptions): number {
  const priorityStatus = ['active', 'draft', 'paused'];
  const campaignAIsPriority = priorityStatus.includes(campaignA.status);
  const campaignBIsPriority = priorityStatus.includes(campaignB.status);
  if (campaignAIsPriority && !campaignBIsPriority) {
    return -1;
  }
  if (campaignBIsPriority && !campaignAIsPriority) {
    return 1;
  }
  return sortDates(campaignA, campaignB, {
    sortDirection: 'descending',
    sortOption: 'startDate',
  });
}

function filterSearch(campaign: Campaign, campaigns: Campaign[], searchInput: string): boolean {
  if (!searchInput) {
    return true;
  }
  return !!campaigns.length
    ? titleMatchesSearch(campaign, searchInput) 
    || locationsMatchesSearch(campaign, searchInput) 
    || hashIdMatchesSearch(campaign, searchInput)
    || poNumberMatchesSearch(campaign, searchInput)
    : true;
}

function titleMatchesSearch(campaign: Campaign, searchInput: string): boolean {
  if (!campaign.title) {
    return false;
  }
  return formatString(campaign.title).includes(formatString(searchInput));
}

function hashIdMatchesSearch(campain: Campaign, searchInput: string): boolean {
  if (!campain.hash) {
    return false;
  }
  return formatString(campain.hash).includes(formatString(searchInput));
}

function poNumberMatchesSearch(campaign: Campaign, searchInput: string): boolean {
  if (!campaign.poNumber) {
    return false;
  }
  return formatString(campaign.poNumber).includes(formatString(searchInput));
}

function locationsMatchesSearch(campaign: Campaign, searchInput: string): boolean {
  return !!campaign.locations.filter((location) => {
    return formatString(location.name).includes(formatString(searchInput));
  }).length;
}

function formatString(str: string): string {
  return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export {
  filterStatus,
  filterSearch,
  sortDates,
  sortStatus,
};
