var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ads-stack-container"},[(_setup.cards.length > 1)?_c('div',{staticClass:"ads-stack-selector"},[_c('span',{on:{"click":_setup.popBackwards}},[_vm._v("< "+_vm._s(_setup.index + 1))]),_vm._v(" / "),_c('span',{on:{"click":_setup.pop}},[_vm._v(_vm._s(_setup.cards.length)+" >")])]):_vm._e(),_c('div',{staticClass:"ads-stack"},_vm._l((_setup.cards),function(card,index){return _c('div',{key:index,staticClass:"ad-card",class:{
      'primary': card.index === _setup.cards.length - 1,
      'secondary': card.index === _setup.cards.length - 2,
      'tertiary': card.index === _setup.cards.length - 3,
      'animate': card.animate,
      'animate-backwards': card.animateBackwards
      },style:({'z-index': card.zIndex })},[_vm._t("default",null,{"ad":{ ...card.ad }})],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }