import { render, staticRenderFns } from "./ChangeAdStatusDelete.vue?vue&type=template&id=5761a110&"
import script from "./ChangeAdStatusDelete.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ChangeAdStatusDelete.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports